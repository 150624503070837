import { API_URL_MATOMO } from "../config/config";

interface ICustomWindow extends Window {
    _paq?: Array<any>;
}

const MatomoTracker = {
    loadTrackingCode: function (mandantTrakingId: string) {
        const wPAQ = window as ICustomWindow;
        wPAQ._paq = [];
        wPAQ._paq.push(["trackPageView"]);
        wPAQ._paq.push(["enableLinkTracking"]);
        (function () {
            const u = API_URL_MATOMO;
            wPAQ._paq.push(["setTrackerUrl", u + "matomo.php"]);
            wPAQ._paq.push(["setSiteId", mandantTrakingId]); // Using the function to get the site ID
            const d = document,
                g = d.createElement("script"),
                s = d.getElementsByTagName("script")[0];
            g.async = true;
            g.src = u + "matomo.js";
            s.parentNode!.insertBefore(g, s);
        })();
    },
};

export default MatomoTracker;
