import IPartnerLogo from "../IPartnerLogo";
import InitialDocumentData from "./InitialDocumentData";

const InitialPartnerLogoData: IPartnerLogo = {
    id: null,
    priority: 0,
    position: "header",
    url: "",
    document: { ...InitialDocumentData },
    isChanged: false,
};

export default InitialPartnerLogoData;
