import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import InitialMandantData from "../../types/initialData/InitialMandantData";
import { loadMandantSuccess } from "./MandantAction";

const ResetMandant = (): ThunkAction<void, {}, {}, Action> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>) => {
        dispatch(
            loadMandantSuccess({
                ...InitialMandantData,
                primaryColor: "#6bd5fc",
                theme: "sporty",
                key: "",
                partnerLogos: [],
            })
        );
    };
};

export default ResetMandant;
