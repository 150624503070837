import "./AdminRoutesContainer.css";
import "../../../assets/AdminTheme.css";

import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import { Helmet } from "react-helmet";
import { MapStateToPropsParam, connect } from "react-redux";
import * as router from "react-router-dom";
import { Redirect } from "react-router-dom";

import favIcon from "../../../assets/favicons/favicon.gif";
import InDevelopmentBanner from "../../../components/inDevelopmentBanner/InDevelopmentBanner";
import { IS_TESTSYSTEM } from "../../../config/config";
import AdminHeader from "../../../layout/adminHeader/AdminHeader";
import AdminSideMenu from "../../../layout/adminSideMenu/AdminSideMenu";
import PrivateRoute from "../../../routes/privateRoute/PrivateRoute";
import PrivateRouteWithProps from "../../../routes/privateRouteWithProps/PrivateRouteWithProps";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IUser from "../../../store/userStore/interfaces/IUser";
import IMandant from "../../../types/IMandant";
import InitialLoginUserData from "../../../types/initialData/InitialLoginUserData";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import AdminProjectListing from "../adminProjectListing/AdminProjectListing";
import CampaignForm from "../campaignForm/CampaignForm";
import CampaignListing from "../campaignListing/CampaignListing";
import ChangePassword from "../changePassword/ChangePassword";
import CmsPageForm from "../cmsPageForm/CmsPageForm";
import CmsPageListing from "../cmsPageListing/CmsPageListing";
import CommentListing from "../commentListing/CommentListing";
import EventForm from "../eventForm/EventForm";
import FeedbackListing from "../feedbackListing/FeedbackListing";
import MandantForm from "../mandantForm/MandantForm";
import MandantListing from "../mandantManager/MandantListing";
import MapProposalViewer from "../mapProposalViewer/MapProposalViewer";
import PollCampaignForm from "../pollCampaignForm/PollCampaignForm";
import PollCampaignListing from "../pollCampaignListing/PollCampaignListing";
import ProjectForm from "../projectForm/ProjectForm";
import ProjectListing from "../projectListing/ProjectListing";
import UserForm from "../userForm/UserForm";
import UserListing from "../userListing/UserListing";
import DevLogger from "../../../utils/logger/DevLogger";

interface IStateProps {
    activeUser: IUser;
}

interface IOwnProps {
    mandant: IMandant;
}

type IAdminDrawerContainerProps = IOwnProps & IStateProps;

const AdminRoutesContainer = (props: IAdminDrawerContainerProps) => {
    const { mandant, activeUser } = props;
    const { url } = router.useRouteMatch();
    const history = router.useHistory();
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleSelectedHomeTab = (index: number) => {
        setSelectedIndex(index);
    };

    const hasPortal = activeUser.roles.find((role: string) => {
        return role === "ROLE_PORTAL";
    });

    if (hasPortal) {
        return <Redirect to={toPortalUrl(mandant, "/projekt/")} />;
    }

    const pageTitle =
        mandant.key === "stegadmin"
            ? "STEG Kundenverwaltung"
            : "STEG Bürgerbeteiligungsportal";

    const renderInDevelopmentBanner = () => {
        if (IS_TESTSYSTEM) {
            return <InDevelopmentBanner />;
        }
        return <></>;
    };

    return (
        <div className="root admin-container">
            <Helmet>
                <title>{pageTitle}</title>
                <link
                    href={favIcon}
                    rel="icon"
                    sizes="16x16"
                    type="image/png"
                />
            </Helmet>

            <router.BrowserRouter>
                {renderInDevelopmentBanner()}
                <AdminHeader />
                <AdminSideMenu
                    mandant={mandant}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    url={url}
                />

                <main className="content">
                    <Toolbar />

                    {!mandant.flagHaveDomain && (
                        <router.Switch>
                            <PrivateRouteWithProps
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_PROJECT_LIST
                                }
                                component={ProjectListing}
                                handleBackhome={handleSelectedHomeTab}
                                exact
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_PROJECT +
                                    ":id"
                                }
                                component={ProjectForm}
                                exact
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" + RoutesEnum.ADMIN_COMMMENTS
                                }
                                component={CommentListing}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_CMS_PAGE_LIST +
                                    ":pageId"
                                }
                                component={CmsPageForm}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_CMS_PAGE_LIST
                                }
                                component={CmsPageListing}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" + RoutesEnum.FEEDBACK_LISTING
                                }
                                component={FeedbackListing}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_PROJECT +
                                    ":projectId" +
                                    RoutesEnum.ADMIN_SUB_EVENT +
                                    ":eventId"
                                }
                                component={EventForm}
                                exact
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" + RoutesEnum.CHANGE_PASSWORD
                                }
                                component={ChangePassword}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_USER +
                                    ":id"
                                }
                                component={UserForm}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" + RoutesEnum.ADMIN_USER_LIST
                                }
                                component={UserListing}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_PROJECT_ORDER_LIST
                                }
                                component={AdminProjectListing}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_POLL_CAMPAIGN +
                                    ":id"
                                }
                                component={PollCampaignForm}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_POLL_CAMPAIGN
                                }
                                component={PollCampaignListing}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_APPOINTMENT +
                                    ":id"
                                }
                                component={CampaignForm}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_APPOINTMENT
                                }
                                component={CampaignListing}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_MAP_PROPOSAL
                                }
                                component={MapProposalViewer}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_PORTAL +
                                    ":id"
                                }
                                component={MandantForm}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={"/:mandantKey" + RoutesEnum.ADMIN_PORTAL}
                                component={MandantForm}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    "/:mandantKey" +
                                    RoutesEnum.ADMIN_PORTAL_LIST
                                }
                                component={MandantListing}
                            />
                            <PrivateRouteWithProps
                                mandant={mandant}
                                path={"/:mandantKey" + RoutesEnum.ADMIN_HOME}
                                component={
                                    mandant.key === "stegadmin"
                                        ? MandantListing
                                        : ProjectListing
                                }
                                handleBackhome={handleSelectedHomeTab}
                            />
                        </router.Switch>
                    )}

                    {/* mandant without key */}
                    {mandant.flagHaveDomain && (
                        <router.Switch>
                            <PrivateRouteWithProps
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_PROJECT_LIST}
                                component={ProjectListing}
                                handleBackhome={handleSelectedHomeTab}
                                exact
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_PROJECT + ":id"}
                                component={ProjectForm}
                                exact
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_COMMMENTS}
                                component={CommentListing}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    RoutesEnum.ADMIN_CMS_PAGE_LIST + ":pageId"
                                }
                                component={CmsPageForm}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_CMS_PAGE_LIST}
                                component={CmsPageListing}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.FEEDBACK_LISTING}
                                component={FeedbackListing}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={
                                    RoutesEnum.ADMIN_PROJECT +
                                    ":projectId" +
                                    RoutesEnum.ADMIN_SUB_EVENT +
                                    ":eventId"
                                }
                                component={EventForm}
                                exact
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.CHANGE_PASSWORD}
                                component={ChangePassword}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_USER + ":id"}
                                component={UserForm}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_USER_LIST}
                                component={UserListing}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_PROJECT_ORDER_LIST}
                                component={AdminProjectListing}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_POLL_CAMPAIGN + ":id"}
                                component={PollCampaignForm}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_POLL_CAMPAIGN}
                                component={PollCampaignListing}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_APPOINTMENT + ":id"}
                                component={CampaignForm}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_APPOINTMENT}
                                component={CampaignListing}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_MAP_PROPOSAL}
                                component={MapProposalViewer}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_PORTAL + ":id"}
                                component={MandantForm}
                            />

                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_PORTAL}
                                component={MandantForm}
                            />
                            <PrivateRoute
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_PORTAL_LIST}
                                component={MandantListing}
                            />
                            <PrivateRouteWithProps
                                mandant={mandant}
                                path={RoutesEnum.ADMIN_HOME}
                                component={
                                    mandant.key === "stegadmin"
                                        ? MandantListing
                                        : ProjectListing
                                }
                                handleBackhome={handleSelectedHomeTab}
                            />
                        </router.Switch>
                    )}
                </main>
            </router.BrowserRouter>
        </div>
    );
};

const mapStateToProps: MapStateToPropsParam<
    IStateProps,
    {},
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        activeUser: state.user.userData
            ? state.user.userData
            : { ...InitialLoginUserData },
    };
};
export default connect(mapStateToProps)(AdminRoutesContainer);
