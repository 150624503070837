import { serverApiProfiles } from "../config/config";

export interface IMandantLoadingParameter {
    mandantKey?: string;
    domain?: string;
}

function getMandantLoadingParameter(): IMandantLoadingParameter {
    const profileKey = window.location.hostname;

    if (serverApiProfiles[profileKey] !== undefined) {
        // extract the mandant from the url
        const urlPath = window.location.pathname.split("/", 2);
        const mandantKey = urlPath[1];
        return { mandantKey };
    } else {
        return { domain: profileKey };
    }
}

export default getMandantLoadingParameter;
