import "./Header.css";

import { Button, Typography } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React from "react";
import {
    MapDispatchToPropsParam,
    MapStateToPropsParam,
    connect,
} from "react-redux";
import { useHistory } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import logo from "../../assets/images/logo.png";
import CmsImage from "../../components/cms/CmsImage";
import InDevelopmentBanner from "../../components/inDevelopmentBanner/InDevelopmentBanner";
import ImageHeader from "../../components/layout/ImageHeader";
import {
    API_URL_STATIC,
    IS_TESTSYSTEM,
    PAGE_BREAK_MOBILE,
    PAGE_BREAK_MOBILE_WITH_BIG_LOGO,
} from "../../config/config";
import useWindowSize from "../../customhooks/useWindowSize";
import { IProjectMenu } from "../../masks/admin/projectForm/tabs/ProjectMenuTab";
import { ProjectTabEnum } from "../../masks/public/projectData/ProjectTabEnum";
import RoutesEnum from "../../routes/RoutesEnum";
import {
    setProjectListToStore,
    setProjectMenuToStore,
} from "../../store/project/ProjectAction";
import IApplicationRootState from "../../store/StoreTypes";
import LogoutAction from "../../store/userStore/asyncActions/LogoutAction";
import IUser from "../../store/userStore/interfaces/IUser";
import ICmsPage, { ICmsPageChild } from "../../types/ICmsPage";
import IDocument from "../../types/IDocument";
import IMandant from "../../types/IMandant";
import InitialCmsPageData from "../../types/initialData/InitialCmsPageData";
import IProjectFull from "../../types/IProjectFull";
import deepCopy from "../../utils/deepCopy";
import DevLogger from "../../utils/logger/DevLogger";
import { toPortalUrl } from "../../utils/toPortalUrl";
import { loadPublicCmsPage } from "../../xhr/CmsPageRequests";
import ISyncProjectList from "../../xhr/interface/ISyncProjectList";
import { loadPublicProjectMenuRequest } from "../../xhr/ProjectRequests";
import { loadPublicProjectListRequest } from "../../xhr/ProjectRequests";
import getProjectHeaderData from "./getProjectHeaderData";
import HeaderProjectMenu from "./HeaderProjectMenu";

export interface IProjectHeaderData {
    title?: string;
    desc?: string;
    header?: IDocument;
}

interface IMapStateProps {
    mandant: IMandant;
    userData: IUser | null;
    activeProject?: IProjectFull | null;
    projectMenu?: IProjectMenu | null;
    projectList?: ISyncProjectList[];
    cmsPageProjectData: ICmsPageChild[];
}

interface IMapDispatchProps {
    handleLogout: (mandantKey: string) => void;
    dispatchSetProjectMenu: (projectMenu: IProjectMenu | null) => void;
    dispatchSetProjectList: (projectList: ISyncProjectList[] | null) => void;
}

interface IOwnProps {
    mandant: IMandant;
    noProject?: boolean;
    activeTab?: string;
    pageKey?: string;
}

interface IPortalHeaderParams
    extends IMapStateProps,
        IMapDispatchProps,
        IOwnProps {}

const PortalHeader = (props: IPortalHeaderParams) => {
    const [pageData, setPageData] = React.useState<ICmsPage>({
        ...InitialCmsPageData,
    });

    // props
    const { mandant, activeTab, pageKey, noProject } = props;

    // Redux state
    const { cmsPageProjectData, activeProject, projectList, projectMenu } =
        props;
    // Redux state
    const { handleLogout, dispatchSetProjectList, dispatchSetProjectMenu } =
        props;

    let { userData } = props;

    const history = useHistory();
    // Laod cms page data
    React.useEffect(() => {
        if (pageKey && activeProject && activeProject.flagEnableProjectCms) {
            loadPublicCmsPage(mandant.key, pageKey).then((result: any) => {
                setPageData({
                    data: result.data.data,
                    elements: result.data.elements,
                });
                return Promise.resolve();
            });
        } else {
            setPageData(deepCopy(InitialCmsPageData));
        }
    }, [pageKey, activeProject]);

    // userdata check if it matches the mandant

    React.useEffect(() => {
        if (activeProject?.flagEnableProjectCms) {
            loadPublicProjectMenuRequest(mandant.key, "" + activeProject.id)
                .then((menuResult: any) => {
                    dispatchSetProjectMenu(menuResult.data);
                })
                .catch((error: any) => {
                    DevLogger.logError(error);
                });
        } else {
            dispatchSetProjectMenu(null);
        }
    }, [activeProject?.id]);

    React.useEffect(() => {
        loadPublicProjectListRequest(mandant.key)
            .then((result) => {
                dispatchSetProjectList(result.data);
            })
            .catch((error: any) => {
                DevLogger.logError(error);
            });
    }, [mandant]);

    if (userData?.mandant?.key && userData?.mandant?.key !== mandant.key) {
        userData = null;
    }

    const windowWidth = useWindowSize();
    const isDesktopView = mandant.flagShowBigCustomerLogo
        ? windowWidth > PAGE_BREAK_MOBILE_WITH_BIG_LOGO
        : windowWidth > PAGE_BREAK_MOBILE;

    const linkToProjects = () => {
        history.push(toPortalUrl(mandant, RoutesEnum.PORTAL_PROJECT_LIST));
        window.scrollTo({ left: 0, top: 0 });
    };

    const linkToProject = (tab: string) => {
        history.push(toPortalUrl(mandant, activeProject?.urlKey + "/" + tab));
        window.scrollTo({ left: 0, top: 0 });
    };

    const linkToLogin = () => {
        history.push(toPortalUrl(mandant, RoutesEnum.PORTAL_LOGIN));
        window.scrollTo({ left: 0, top: 0 });
    };

    const getLogoUrl = (): string => {
        let theProject = activeProject as any;

        if (!theProject && projectList?.length == 1) {
            theProject = projectList[0];
        }

        if (theProject?.logoRoute) {
            return toPortalUrl(
                mandant,
                theProject?.urlKey + "/" + theProject?.logoRoute
            );
        } else {
            return mandant.urlWebsite;
        }
    };

    // handle logo Routing
    const handleLogoRouting = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        let theProject = activeProject as any;

        if (!theProject && projectList?.length == 1) {
            theProject = projectList[0];
        }

        if (theProject?.logoRoute) {
            event.preventDefault();
            history.push(
                toPortalUrl(
                    mandant,
                    theProject?.urlKey + "/" + theProject?.logoRoute
                )
            );
        }
    };

    const logoUrl = getLogoUrl();
    /**
     * the desktop lego
     */
    const showDesktopLogo = () => {
        return (
            <div
                className={`${
                    mandant.flagShowBigCustomerLogo
                        ? "App-header-big-customer-logo-container"
                        : ""
                } App-header-logo-container`}
            >
                <a
                    href={logoUrl}
                    onClick={handleLogoRouting}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <CmsImage
                        className="App-header-logo-customer"
                        image={mandant.logoImage}
                    />
                </a>
                {mandant.partnerLogos?.map((partnerLogo) => {
                    if (!["header", "both"].includes(partnerLogo.position)) {
                        return <></>;
                    }

                    if (partnerLogo.url) {
                        return (
                            <a
                                href={partnerLogo.url}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <CmsImage
                                    className="App-header-logo-customer"
                                    image={partnerLogo.document}
                                />
                            </a>
                        );
                    }

                    return (
                        <CmsImage
                            className="App-header-logo-customer"
                            image={partnerLogo.document}
                        />
                    );
                })}
                {mandant.flagHideStegLogo !== true && (
                    <a href={"https://steg.de"} target="__blank">
                        <img
                            className="App-header-logo-steg"
                            src={logo}
                            alt="Logo"
                        />
                    </a>
                )}
            </div>
        );
    };
    // Render the "Mehr Projekte" button based on the showMoreProjectsButton value
    const renderMehrProjekteButton = () => {
        if (projectList?.length !== undefined && projectList?.length > 1) {
            return (
                <Button onClick={linkToProjects} endIcon={<ArrowForwardIcon />}>
                    Mehr Projekte
                </Button>
            );
        }
        return null;
    };
    const isNoButtonRendred =
        (projectList?.length !== undefined && projectList?.length <= 1) ||
        mandant.flagDisabledLogin;

    const logoutAction = () => {
        handleLogout(mandant.key);
    };

    /**
     * sideheader buttons
     */
    const showDesktopSideHeaderButtons = () => {
        return isDesktopView ? (
            <div className="App-header-menu--entry-buttons">
                <div className="App-header-menu--entry header-buttons-container">
                    {renderMehrProjekteButton()}{" "}
                    {/* Render the "Mehr Projekte" button */}
                    {!userData && !mandant.flagDisabledLogin && (
                        <Button
                            onClick={linkToLogin}
                            endIcon={<ArrowForwardIcon />}
                            color={"primary"}
                        >
                            Jetzt anmelden
                        </Button>
                    )}
                    {!!userData && (
                        <Button
                            onClick={logoutAction}
                            className="App-header-primary-button"
                            endIcon={<ArrowForwardIcon />}
                            color={"primary"}
                        >
                            Ausloggen
                        </Button>
                    )}
                    {userData && !mandant.flagDisabledLogin && (
                        <>
                            {userData && (
                                <Button
                                    className="App-header-user-button"
                                    onClick={linkToLogin}
                                >
                                    {userData.firstname +
                                        " " +
                                        userData.lastname}
                                </Button>
                            )}
                        </>
                    )}
                </div>
            </div>
        ) : (
            ""
        );
    };

    /**
     * show top menu
     */
    const renderHeaderTitleAndDesc = (
        headerData: IProjectHeaderData | null
    ) => {
        return isDesktopView ? (
            <div className="App-header-main-container">
                {!!activeProject && (
                    <>
                        {activeTab !== ProjectTabEnum.INFO_TAB && (
                            <div className="main-header-text">
                                <div className="header-title">
                                    <Typography
                                        variant="h2"
                                        className="header-title-fragments"
                                    >
                                        {headerData?.title !== "null"
                                            ? headerData?.title
                                            : ""}
                                    </Typography>

                                    <Typography
                                        variant={"body1"}
                                        className="header-text"
                                    >
                                        {headerData?.desc !== "null"
                                            ? headerData?.desc
                                            : ""}
                                    </Typography>
                                </div>
                                {activeTab === ProjectTabEnum.START_TAB && (
                                    <div className="portal-header__button-container gap-top">
                                        {activeProject.flagTabParticipate !==
                                            false && (
                                            <Button
                                                onClick={() => {
                                                    linkToProject(
                                                        ProjectTabEnum.PARTICIPATE_TAB
                                                    );
                                                }}
                                                color="primary"
                                                endIcon={<ArrowForwardIcon />}
                                            >
                                                Jetzt mitmachen
                                            </Button>
                                        )}
                                        {activeProject.flagTabInfo !==
                                            false && (
                                            <Button
                                                onClick={() => {
                                                    linkToProject(
                                                        ProjectTabEnum.INFO_TAB
                                                    );
                                                }}
                                                endIcon={<ArrowForwardIcon />}
                                            >
                                                Mehr erfahren
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        ) : (
            ""
        );
    };

    const TimeLine = () => {
        return (
            <iframe
                title="timelinejs"
                className={isDesktopView ? "project-data__timelinejs" : ""}
                src={
                    API_URL_STATIC +
                    "timeline/timeline.html#m=" +
                    mandant.key +
                    "&i=" +
                    activeProject?.id +
                    "&a=" +
                    encodeURI(API_URL_STATIC)
                }
                style={{
                    width: "100%",
                    height: "600px",
                    border: "none",
                }}
            />
        );
    };

    /**
     * render the image header
     */
    const showImageHeader = (headerData: IProjectHeaderData | null) => {
        if (!activeProject) {
            return (
                <ImageHeader
                    image={mandant.headerImage}
                    isMobile={!isDesktopView}
                />
            );
        }

        if (pageData.data.id === 0 && ProjectTabEnum.INFO_TAB === activeTab) {
            if (headerData?.header && headerData?.header?.url !== "") {
                return (
                    <ImageHeader
                        isMobile={!isDesktopView}
                        image={
                            headerData?.header?.url !== ""
                                ? headerData?.header
                                : mandant.headerImage
                        }
                    />
                );
            }

            return <TimeLine />;
        }

        if (pageData.data.flagShowTimelineHeader === true) {
            return <TimeLine />;
        }

        return (
            <ImageHeader
                isMobile={!isDesktopView}
                image={
                    activeProject?.imageHeader?.url === ""
                        ? mandant.headerImage
                        : activeProject.imageHeader
                }
            />
        );
    };

    const renderInDevelopmentBanner = () => {
        if (IS_TESTSYSTEM) {
            return <InDevelopmentBanner />;
        }
        return <></>;
    };

    const headerData = activeProject
        ? getProjectHeaderData(activeProject, activeTab)
        : null;

    return (
        <>
            {/*main header grid*/}
            <div className={isDesktopView ? "App-header" : "app-mobile-header"}>
                {renderInDevelopmentBanner()}
                <HeaderProjectMenu
                    mandant={mandant}
                    userData={userData}
                    flagShowMoreProjects={
                        projectList?.length !== undefined &&
                        projectList?.length > 1
                    }
                    isDesktopView={isDesktopView}
                    handleLogout={handleLogout}
                    linkToLogin={linkToLogin}
                    customMenu={projectMenu}
                    activeProject={activeProject}
                    activeTab={pageKey ? undefined : activeTab}
                    pageKey={pageKey}
                    logoUrl={logoUrl}
                    handleLogoRouting={handleLogoRouting}
                    isFullWidthMenu={isNoButtonRendred}
                />
                {/* logo grid item (xs2). Shown only in desktop view */}
                {showDesktopLogo()}
                {!pageData.data?.flagShowTimelineHeader &&
                    renderHeaderTitleAndDesc(headerData)}
                {/* top side menu grid item(xs2). Shown only in desktop view */}
                {showDesktopSideHeaderButtons()}
            </div>
            {showImageHeader(headerData)}
        </>
    );
};
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant?.mandant,
        userData: state.user?.userData,
        activeProject: state.activeProject?.projectData,
        projectMenu: state.activeProject?.projectMenu,
        cmsPageProjectData: state.cmsPageProjectSelectbox.cmsPageProjectData,
    };
};

/**
 * for async func we only set handleOnLoginEvent: (params:any) => LoginAction(params)
 * for non async we use  handleOnLoginEvent: () => {dispatch(SomeActions())
 */
const mapDispatchToProps: MapDispatchToPropsParam<
    IMapDispatchProps,
    IOwnProps
> = (dispatch: ThunkDispatch<{}, {}, Action>) => {
    return {
        handleLogout: (mandantKey: string) =>
            dispatch(LogoutAction(mandantKey)),
        dispatchSetProjectMenu: (projectMenu: IProjectMenu | null) => {
            return dispatch(setProjectMenuToStore(projectMenu));
        },
        dispatchSetProjectList: (projectList: ISyncProjectList[] | null) => {
            return dispatch(setProjectListToStore(projectList));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PortalHeader);
