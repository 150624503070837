import "./TeamForm.css";

import {
    Button,
    Checkbox,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconAdd from "@material-ui/icons/Add";
import IconSave from "@material-ui/icons/Save";
import React from "react";

import Notify from "../../components/notify/Notify";
import IDocument from "../../types/IDocument";
import InitialSnackbarState from "../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../types/ISnackbarState";
import ITeam from "../../types/ITeam";
import ImageUpload from "../imageUpload/ImageUpload";

interface ITeamFormProps {
    label: string;
    participant: ITeam;
    action: string;
    onEditHandler: (
        even: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
    onCheckEditHandler: (even: React.ChangeEvent<HTMLInputElement>) => void;
    onImageChange: (image: IDocument, key: string) => void;
    onCreateTeam: () => void;
    onTeamSave: () => void;
}

const TeamForm = (props: ITeamFormProps) => {
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const {
        onCreateTeam,
        onTeamSave,
        onEditHandler,
        onImageChange,
        onCheckEditHandler,
    } = props;

    return (
        <>
            <Typography variant={"h2"} className="main-header">
                {props.participant.id
                    ? props.label + " editieren"
                    : props.label + " erstellen"}
            </Typography>
            <ImageUpload
                onChange={onImageChange}
                docData={props.participant.image}
                name={"image"}
                label={"Avatar"}
                defaultFilename="Avatar.jpg"
                hasFreeScale={true}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!props.participant.flagShowInTeamsTab}
                        onChange={onCheckEditHandler}
                        name="flagShowInTeamsTab"
                    />
                }
                label="Mitglied anzeigen"
            />
            <TextField
                fullWidth
                variant="outlined"
                label="Rolle"
                value={
                    props.participant?.displayTitle
                        ? props.participant.displayTitle
                        : ""
                }
                name="displayTitle"
                onChange={onEditHandler}
            />
            <TextField
                fullWidth
                variant="outlined"
                label="Firma"
                value={
                    props.participant?.company ? props.participant.company : ""
                }
                name="company"
                onChange={onEditHandler}
            />
            <TextField
                fullWidth
                variant="outlined"
                label="Anrede"
                value={
                    props.participant?.salutation
                        ? props.participant.salutation
                        : ""
                }
                name="salutation"
                onChange={onEditHandler}
            />{" "}
            <TextField
                fullWidth
                variant="outlined"
                label="Name"
                value={props.participant?.name ? props.participant.name : ""}
                name="name"
                onChange={onEditHandler}
            />
            <TextField
                fullWidth
                variant="outlined"
                label="Strasse"
                value={
                    props.participant?.street ? props.participant.street : ""
                }
                name="street"
                onChange={onEditHandler}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "space-around",
                    gap: "3px",
                }}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Ort"
                    value={
                        props.participant?.city ? props.participant.city : ""
                    }
                    name="city"
                    onChange={onEditHandler}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    type="number"
                    label="Plz"
                    value={props.participant?.zip ? props.participant.zip : ""}
                    name="zip"
                    onChange={onEditHandler}
                />
            </div>
            <TextField
                fullWidth
                variant="outlined"
                label="Tel"
                value={props.participant?.phone ? props.participant.phone : ""}
                name="phone"
                onChange={onEditHandler}
            />
            <TextField
                fullWidth
                variant="outlined"
                label="Email"
                value={props.participant?.email ? props.participant.email : ""}
                name="email"
                onChange={onEditHandler}
            />
            <Grid container justifyContent="space-between">
                {props.action === "EDIT" ? (
                    <Button
                        color="primary"
                        onClick={onTeamSave}
                        startIcon={<IconSave />}
                    >
                        {props.label} speichern
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        onClick={onCreateTeam}
                        startIcon={<IconAdd />}
                    >
                        {props.label} erstellen
                    </Button>
                )}
            </Grid>
            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};
export default TeamForm;
