import ITeam from "../ITeam";
import InitialDocumentData from "./InitialDocumentData";

const InitialTeamData: ITeam = {
    id: "",
    flagShowInTeamsTab: true,
    displayTitle: "",
    company: "",
    salutation: "",
    name: "",
    street: "",
    city: "",
    zip: "",
    phone: "",
    email: "",
    priority: 0,
    project: { id: 0 },
    image: { ...InitialDocumentData },
};
export default InitialTeamData;
