import { Grid } from "@material-ui/core";
import React from "react";

import { API_URL_STATIC } from "../../config/config";
import IDocument from "../../types/IDocument";
import IDocumentRef from "../../types/IDocumentRef";
import DownloadCard from "../downloadCard/DownloadCard";

interface IFolderListCmsProps {
    refs: IDocumentRef[];
    uploads: { [key: string]: IDocument };
}

export default function FolderListCms(props: IFolderListCmsProps) {
    const { refs, uploads } = props;

    if (!refs?.length) {
        return <></>;
    }

    return (
        <>
            <Grid container spacing={2} direction="row">
                {refs.map((ref: any, index: number) => {
                    const document = uploads[ref.key];
                    return (
                        <Grid key={index + ""} item xs={12} md={6} lg={4}>
                            <DownloadCard
                                tooltip={document?.name}
                                title={
                                    document?.title
                                        ? String(document.title)
                                        : String(document.name)
                                }
                                description={
                                    document?.description
                                        ? document?.description
                                        : ""
                                }
                                actionButtonText="Herunterladen"
                                actionButtonClassName="App-header-red-button"
                                downloadUrl={API_URL_STATIC + document?.url}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
}
