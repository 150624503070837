import ISelectOption from "../../../../xhr/interface/ISelectOption";
import { ProjectTabEnum } from "../../../public/projectData/ProjectTabEnum";

const defaultTabs: ISelectOption[] = [
    {
        label: "Start",
        id: ProjectTabEnum.START_TAB,
    },
    {
        label: "Projekt Informationen",
        id: ProjectTabEnum.INFO_TAB,
    },
    {
        label: "Termine",
        id: ProjectTabEnum.CAMPAIGN_TAB,
    },
    {
        label: "Ereignisse",
        id: ProjectTabEnum.EVENTS_TAB,
    },
    {
        label: "Ergebnisse",
        id: ProjectTabEnum.RESULTS_TAB,
    },
    {
        label: "Teilnehmen",
        id: ProjectTabEnum.PARTICIPATE_TAB,
    },
    {
        label: "Beratung",
        id: ProjectTabEnum.CONSULTING_TAB,
    },
    {
        label: "Team",
        id: ProjectTabEnum.TEAM_TAB,
    },
];

export default defaultTabs;
