enum ActionTypesEnum {
    LOGIN = "login",
    LOGIN_PENDING = "login_pending",
    LOGIN_SUCCESS = "login_success",
    LOGIN_ERROR = "login_error",
    EMAIL_ERROR = "Email_error",
    PASSWORD_ERROR = "password_error",
    LOGOUT = "logout",
    LOGOUT_SUCCESS = "logout_success",
    LOGOUT_ERROR = "logout_error",
    UPDATE_USERDATA = "update_userdata",
}

export default ActionTypesEnum;
