import "./index.css";
import "react-app-polyfill/ie11";
import "./utils/polyfillStartWith";

import { deDE } from "@material-ui/core/locale";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { ConfirmProvider } from "material-ui-confirm";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import CacheBuster from "./components/cacheBuster/CacheBuster";
import MandantProvider from "./containers/mandantProvider/MandantProvider";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";
import getMandantLoadingParameter from "./utils/getMandantLoadingParameter";
import history from "./utils/history";

// @ts-ignore
if (!String.prototype.startsWith) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(String.prototype, "startsWith", {
        value: function (search: string, rawPos: number) {
            const pos = rawPos > 0 ? rawPos | 0 : 0;
            return this.substring(pos, pos + search.length) === search;
        },
    });
}

const initialState = {};
const store = configureStore(history, initialState);

const mandantLoadingParameter = getMandantLoadingParameter();

const theme = createTheme({}, deDE);

ReactDOM.render(
    <React.Fragment>
        <CacheBuster>
            {(loading, isLatestVersion, refreshCacheAndReload) => {
                if (loading) {
                    return <></>;
                }
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }
                return (
                    <ThemeProvider theme={theme}>
                        <Provider store={store.store}>
                            <MandantProvider
                                loadingParameter={mandantLoadingParameter}
                            />

                            <ConfirmProvider>
                                <App />
                            </ConfirmProvider>
                        </Provider>
                    </ThemeProvider>
                );
            }}
        </CacheBuster>
    </React.Fragment>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
