import "./ProjectTeamTab.css";

import { Grid, Typography } from "@material-ui/core";
import React from "react";

import HtmlContent from "../../../../components/cms/HtmlContent";
import IProjectFull from "../../../../types/IProjectFull";

interface IProjectStartTabProps {
    project: IProjectFull;
}

function ProjectStartTab(props: IProjectStartTabProps) {
    const { project } = props;
    const formattedContent = project.welcomeMessageContent
        ? project.welcomeMessageContent
              .replace(/<p>/g, "")
              .replace(/<\/p>/g, "<br>")
        : "";
    return (
        <>
            <Typography variant="h3" className="welcome-message-header">
                {project.welcomeMessageTitle
                    ? project.welcomeMessageTitle
                    : "Herzlich Willkommen"}
            </Typography>
            {project.welcomeMessageContent ? (
                <HtmlContent
                    className="welcome-message"
                    content={formattedContent}
                    isTypology
                    variant="h2"
                />
            ) : (
                <Typography className="gap-top welcome-message" variant="h2">
                    Schön, dass Sie unsere
                    <br /> Beteiligungsplattform besuchen
                </Typography>
            )}
            <Grid className="gap-top project-motivation" container spacing={4}>
                {!!project.motivation && !project.motivation2 && (
                    <Grid item xs={12} md={12} lg={12}>
                        <HtmlContent
                            className="content-block"
                            content={project.motivation}
                        />
                    </Grid>
                )}

                {!!project.motivation && !!project.motivation2 && (
                    <>
                        <Grid item xs={12} md={6}>
                            <HtmlContent
                                className="content-block"
                                content={project.motivation}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <HtmlContent
                                className="content-block"
                                content={project.motivation2}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
}

export default ProjectStartTab;
