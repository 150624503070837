import KFW_STATIC_DATA, { KFW40, KFW55, KFW70, KFW85 } from "./constants";
import {
    ERROR_MESSAGE_KFW40_BASIC_FUNDING_CAP_LIMIT_VALUE,
    ERROR_MESSAGE_KFW55_BASIC_FUNDING_CAP_LIMIT_VALUE,
    ERROR_MESSAGE_KFW70_BASIC_FUNDING_CAP_LIMIT_VALUE,
    ERROR_MESSAGE_KFW85_BASIC_FUNDING_CAP_LIMIT_VALUE,
} from "./data";

/******************************Handle KFW errors ******************************/
export const handleErrors = (costs: number, kfw: string): string => {
    let errorMessage = "";
    switch (kfw) {
        case KFW85:
            if (costs && costs >= KFW_STATIC_DATA[kfw].MAX_COSTS_KFW85) {
                errorMessage =
                    ERROR_MESSAGE_KFW85_BASIC_FUNDING_CAP_LIMIT_VALUE;
            }
            break;
        case KFW70:
            if (costs && costs >= KFW_STATIC_DATA[kfw].MAX_COSTS_KFW70) {
                errorMessage =
                    ERROR_MESSAGE_KFW70_BASIC_FUNDING_CAP_LIMIT_VALUE;
            }
            break;
        case KFW55:
            if (costs >= KFW_STATIC_DATA[kfw].MAX_COSTS_KFW55) {
                errorMessage =
                    ERROR_MESSAGE_KFW55_BASIC_FUNDING_CAP_LIMIT_VALUE;
            }
            break;
        case KFW40:
            if (costs >= KFW_STATIC_DATA[kfw].MAX_COSTS_KFW40) {
                errorMessage =
                    ERROR_MESSAGE_KFW40_BASIC_FUNDING_CAP_LIMIT_VALUE;
            }
            break;
    }
    return errorMessage;
};
