import { AxiosResponse } from "axios";

import IMapProposalType from "../types/IMapProposalType";
import {
    deleteData,
    getData,
    postData,
    putData,
} from "../utils/httpRequest/request";

/* admin load proposals */
export const loadPublicProposalTypeList = (
    mandant: string,
    projectId: number
): Promise<AxiosResponse<IMapProposalType[]>> => {
    return getData<any[]>(`project/mapProposalType/${projectId}`, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

export const loadProposalTypeList = (
    mandant: string,
    projectId: number
): Promise<AxiosResponse<any>> => {
    return getData<any[]>(`project/mapProposalType/${projectId}`, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

/* portal create proposal */
export const createProposalTypeRequest = (
    mandant: string,
    proposal: IMapProposalType
): Promise<AxiosResponse<IMapProposalType>> => {
    return postData<IMapProposalType, IMapProposalType>(
        "project/mapProposalType/",
        proposal,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};

export const updateProposalTypeRequest = (
    id: number,
    proposal: IMapProposalType,
    mandant: string
): Promise<AxiosResponse<IMapProposalType>> => {
    return putData<IMapProposalType, IMapProposalType>(
        "project/mapProposalType/",
        id,
        proposal,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};

export const deleteProposalTypeRequest = (
    mandant: string,
    id: number
): Promise<any> => {
    return deleteData<any>("project/mapProposalType/", id, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
