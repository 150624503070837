import { LinearProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { Helmet } from "react-helmet";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory } from "react-router-dom";

import ProjectLongBlock from "../../../components/projectsOverview/ProjectLongBlock";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import PortalHeader from "../../../layout/portalHeader/PortalHeader";
import IApplicationRootState from "../../../store/StoreTypes";
import IMandant from "../../../types/IMandant";
import IProjectList from "../../../types/IProjectList";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import { loadPublicProjectListRequest } from "../../../xhr/ProjectRequests";

interface IMapStateProps {
    userData: any;
}

interface IOwnProps {
    mandant: IMandant;
}

interface IProjectListParams extends IMapStateProps, IOwnProps {}

const ProjectList = (props: IProjectListParams) => {
    const history = useHistory();
    const { mandant } = props;

    const [projectList, setProjectList] = React.useState<IProjectList[]>([]);
    const [projectReady, setProjectsReady] = React.useState<boolean>(false);
    React.useEffect(() => {
        loadPublicProjectListRequest(mandant.key)
            .then((result: any) => {
                setProjectList(
                    result.data
                        .filter((project: IProjectList) => !project.flagArchive)
                        .sort((a: any, b: any) => {
                            const firstdate = new Date(a.theStart);
                            const seconddate = new Date(b.theStart);
                            return firstdate.getTime() - seconddate.getTime();
                        })
                );
                setProjectsReady(true);
                return Promise.resolve();
            })
            .catch(() => {
                setProjectsReady(true);
            });
    }, [mandant.key]);

    if (!mandant.id) {
        return <></>;
    }

    // if we have only one project we direct jump into the project
    if (projectList.length === 1) {
        history.push(toPortalUrl(mandant, "" + projectList[0].urlKey));
    } else if (projectList.length === 0) {
        // gaisburg rescue fix
        if (mandant.key === "gaisburgtest") {
            window.location.href = "https://gaisburg32.de/Stuttgart32Gaisburg";
        } else {
            if (projectReady) {
                history.push("/");
            }
        }
    }

    return (
        <div className="portal-section">
            <Helmet>
                <title>Unsere aktuellen Projekte für Sie</title>
            </Helmet>
            <PortalHeader mandant={mandant} noProject={true} />
            <div className="App-main">
                <div className="App-cms-container">
                    {projectReady ? (
                        <>
                            <Grid container direction="column">
                                {projectList.length === 0 && (
                                    <p>
                                        Im Moment gibt es keine aktuellen
                                        Projekte
                                    </p>
                                )}
                                {projectList.length > 0 &&
                                    projectList
                                        .sort(
                                            (
                                                a: IProjectList,
                                                b: IProjectList
                                            ) => {
                                                return a.priority - b.priority;
                                            }
                                        )
                                        .map(
                                            (
                                                project: IProjectList,
                                                index: number
                                            ) => {
                                                return (
                                                    <ProjectLongBlock
                                                        projectData={project}
                                                        mandant={mandant}
                                                        index={index % 2}
                                                        key={project.id}
                                                    />
                                                );
                                            }
                                        )}
                            </Grid>
                        </>
                    ) : (
                        <div
                            className="App-main"
                            style={{ minHeight: "300px" }}
                        >
                            <LinearProgress color="secondary" />
                        </div>
                    )}
                </div>
            </div>
            <PortalFooter mandant={mandant} />
        </div>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        userData: state.user.userData,
    };
};

export default connect(mapStateToProps)(ProjectList);
