import React from "react";

import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/IProjectFull";
import CmsPageLoader from "../../cmsPage/components/CmsPageLoader";

interface IProjectDataContentProps {
    mandant: IMandant;
    pageKey?: string;
    isMobileSize: boolean;
    projectData: IProjectFull;
    activeContent: React.ReactElement;
}

const ProjectDataContent = (props: IProjectDataContentProps) => {
    const { pageKey, mandant, projectData, activeContent } = props;
    if (pageKey) {
        return (
            <CmsPageLoader
                mandant={mandant}
                projectKey={projectData.urlKey}
                pageKey={pageKey}
            />
        );
    }
    return activeContent;
};

export default ProjectDataContent;
