import IMandant from "../../../../types/IMandant";

export type IMandantValidationState = {
    hasError: boolean;
    headerImage?: boolean;
    name?: boolean;
    key?: boolean;
};

export const requiredMandantDataFilled = (
    mandant: IMandant
): IMandantValidationState => {
    const validationState: IMandantValidationState = {
        hasError: false,
    };

    const isHeaderImageValid =
        !mandant?.headerImage?.data && !mandant?.headerImage?.url;

    if (isHeaderImageValid) {
        validationState.headerImage = true;
        validationState.hasError = true;
    }

    if (!mandant?.name) {
        validationState.hasError = true;
        validationState.name = true;
    }

    if (!mandant?.key) {
        validationState.hasError = true;
        validationState.key = true;
    }

    return validationState;
};
