import ActionTypes from "../ProjectActionTypesEnum";
import { AppActions, IProjectState } from "./types";

export const initialState: IProjectState = {
    projectData: null,
    projectMenu: null,
    projectSelectList: null,
};

function ProjectReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: IProjectState = initialState,
    action: AppActions
): IProjectState {
    switch (action.type) {
        case ActionTypes.SET_PROJECT:
            return {
                ...state,
                projectData: action.payload,
            };
        case ActionTypes.SET_PROJECT_MENU:
            return {
                ...state,
                projectMenu: action.payload,
            };
        case ActionTypes.SET_PROJECT_LIST:
            return {
                ...state,
                projectSelectList: action.payload,
            };
        case ActionTypes.EMPTY_PROJECT:
            return {
                ...state,
                projectData: null,
            };

        default:
            return state;
    }
}

export default ProjectReducer;
