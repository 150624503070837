/* eslint-disable no-console */
export default class DevLogger {
    public static logError(...args: any[]): void {
        console.error(...args);
    }

    public static logWarn(...args: any[]): void {
        console.warn(...args);
    }

    public static logInfo(...args: any[]): void {
        console.log(...args);
    }
}
