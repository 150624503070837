import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

import IMapProposal from "../../../types/IMapProposal";

type ITableWithPagination = {
    tableData: IMapProposal[];
    onProposalClick: (proposal: IMapProposal) => void;
    onDeleteClick: (event: any) => void;
};
interface IColumn {
    id:
        | "id"
        | "creationDate"
        | "comment"
        | "nameOfPerson"
        | "type"
        | "emailOfPerson";
    label: string;
    minWidth?: number;
    align?: "right";
    type?: string;
    format?: (value: number) => string;
}

const columns: IColumn[] = [
    { id: "creationDate", type: "value", label: "Datum", minWidth: 75 },
    {
        id: "comment",
        type: "value",
        label: "Kommenar",
        minWidth: 250,
    },
    {
        id: "type",
        type: "type",
        label: "Kategorie",
        minWidth: 90,
    },
    {
        id: "comment",
        type: "menu",
        label: "Menu",
        minWidth: 75,
    },
];

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    container: {
        maxHeight: 600,
    },
});

export default function TableWithPagination(props: ITableWithPagination) {
    const { tableData, onProposalClick, onDeleteClick } = props;
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(250);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper variant="outlined" className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column: IColumn, index: number) => (
                                <TableCell
                                    key={column.id + +"" + index}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index: number) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row?.project.id + "" + index}
                                    >
                                        {columns.map(
                                            (column, colIdx: number) => {
                                                // @ts-ignore
                                                const value = row[
                                                    column.id
                                                ] as any;
                                                return (
                                                    <TableCell
                                                        key={
                                                            column.id +
                                                            "" +
                                                            colIdx
                                                        }
                                                        align={column.align}
                                                    >
                                                        {column.type ===
                                                            "type" && (
                                                            <>{value?.label}</>
                                                        )}

                                                        {column.type ===
                                                            "menu" && (
                                                            <>
                                                                <a
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                        onProposalClick(
                                                                            row
                                                                        )
                                                                    }
                                                                >
                                                                    ansehen
                                                                </a>{" "}
                                                                |{" "}
                                                                <a
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    data-id={
                                                                        row.id
                                                                    }
                                                                    onClick={
                                                                        onDeleteClick
                                                                    }
                                                                >
                                                                    löschen
                                                                </a>
                                                            </>
                                                        )}

                                                        {column.type ===
                                                            "value" &&
                                                            (column.format &&
                                                            typeof value ===
                                                                "number"
                                                                ? column.format(
                                                                      value
                                                                  )
                                                                : value)}
                                                    </TableCell>
                                                );
                                            }
                                        )}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100, 250]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
