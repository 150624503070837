import IMapProposal from "../IMapProposal";

const InitialMapProposal: IMapProposal = {
    id: 0,
    project: { id: 0 },
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    creationDate: "",
    comment: "",
    answer: null,
    user: null,
};
export default InitialMapProposal;
