import { Grid } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import CheckIcon from "@material-ui/icons/Check";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React from "react";
import { Helmet } from "react-helmet";

import CmsImage from "../../../../components/cms/CmsImage";
import HtmlContent from "../../../../components/cms/HtmlContent";
import FolderListCms from "../../../../components/folderList/FolderListCms";
import FundingCalculator from "../../../../components/fundingCalculatorForm/FundingCalculatorForm";
import PersonTile from "../../../../components/personTile/PersonTile";
import CmsPageElementTypeEnum from "../../../../enums/CmsPageElementTypeEnum";
import ICmsPage from "../../../../types/ICmsPage";
import ICmsPageElement from "../../../../types/ICmsPageElement";
import IDocument from "../../../../types/IDocument";
import IQuestionRef from "../../../../types/IQuestionRef";
import ITeam from "../../../../types/ITeam";
import ITeamRef from "../../../../types/ITeamRef";
import DevLogger from "../../../../utils/logger/DevLogger";
import CmsPageFragmentHeader1 from "./sections/CmsPageFragmentHeader1";
import CmsPageFragmentHeader2 from "./sections/CmsPageFragmentHeader2";
import CmsPageFragmentHeader3 from "./sections/CmsPageFragmentHeader3";

interface ICmsPageRendererProps {
    pageData: ICmsPage;
    images: { [key: string]: IDocument };
    uploads: { [key: string]: IDocument };
    teams: { [key: string]: ITeam };
}

const CmsPageRenderer = (props: ICmsPageRendererProps) => {
    const { images, uploads, teams, pageData } = props;
    const [open, setOpen] = React.useState<string | number>(-1);
    const renderHeader1 = (pageFragment: ICmsPageElement, key: number): any => {
        return (
            <CmsPageFragmentHeader1
                key={"" + key}
                pageFragment={pageFragment}
            />
        );
    };

    const renderHeader2 = (pageFragment: ICmsPageElement, key: number): any => {
        return (
            <CmsPageFragmentHeader2
                key={"" + key}
                pageFragment={pageFragment}
            />
        );
    };

    const renderHeader3 = (pageFragment: ICmsPageElement, key: number): any => {
        return (
            <CmsPageFragmentHeader3
                key={"" + key}
                pageFragment={pageFragment}
            />
        );
    };

    const renderSimpleText = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        return (
            <Grid item key={key} xs={12}>
                <p>{pageFragment.content}</p>
            </Grid>
        );
    };

    const renderHtml = (pageFragment: ICmsPageElement, key: number): any => {
        return (
            <Grid item key={key} xs={12}>
                <HtmlContent content={pageFragment.content as string} />
            </Grid>
        );
    };

    const renderHtmlTextText = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const content1 = pageFragment.content[0] as string;
        // @ts-ignore
        const content2 = pageFragment.content[1] as string;

        return (
            <React.Fragment key={key}>
                <Grid item xs={12} md={6}>
                    <HtmlContent content={content1} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <HtmlContent content={content2} />
                </Grid>
            </React.Fragment>
        );
    };

    const renderHtmlTextTextText = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const content1 = pageFragment.content[0] as string;
        // @ts-ignore
        const content2 = pageFragment.content[1] as string;
        // @ts-ignore
        const content3 = pageFragment.content[2] as string;

        return (
            <React.Fragment key={key}>
                <Grid item xs={12} md={4}>
                    <HtmlContent content={content1} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HtmlContent content={content2} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HtmlContent content={content3} />
                </Grid>
            </React.Fragment>
        );
    };

    const renderHtmlTextImage = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const content1 = pageFragment.content[0] as string;
        // @ts-ignore
        const content2 = pageFragment.content[1] as IDocumentRef;

        return (
            <React.Fragment key={key}>
                <Grid item xs={12} md={6}>
                    <HtmlContent content={content1} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CmsImage
                        image={images[content2.key]}
                        className="portal-cms-image"
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const renderHtmlImageText = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const content1 = pageFragment.content[0] as IDocumentRef;
        // @ts-ignore
        const content2 = pageFragment.content[1] as string;

        return (
            <React.Fragment key={key}>
                <Grid item xs={12} md={6}>
                    <CmsImage
                        image={images[content1.key]}
                        className="portal-cms-image"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <HtmlContent content={content2} />
                </Grid>
            </React.Fragment>
        );
    };

    const renderHtmlImage = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const content1 = pageFragment.content[0] as IDocumentRef;
        // @ts-ignore
        const content2 = pageFragment.content[1] as string;

        return (
            <React.Fragment key={key}>
                <Grid item xs={12}>
                    <CmsImage
                        image={images[content1.key]}
                        className="portal-cms-image"
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const renderHtmlImageImage = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const content1 = pageFragment.content[0] as IDocumentRef;
        // @ts-ignore
        const content2 = pageFragment.content[1] as IDocumentRef;

        return (
            <React.Fragment key={key}>
                <Grid item xs={12} md={6}>
                    <CmsImage
                        image={images[content1.key]}
                        className="portal-cms-image"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CmsImage
                        image={images[content2.key]}
                        className="portal-cms-image"
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const renderHtmlImageImageImage = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const content1 = pageFragment.content[0] as IDocumentRef;
        // @ts-ignore
        const content2 = pageFragment.content[1] as IDocumentRef;
        // @ts-ignore
        const content3 = pageFragment.content[2] as IDocumentRef;

        return (
            <React.Fragment key={key}>
                <Grid item xs={12} md={4}>
                    <CmsImage
                        image={images[content1.key]}
                        className="portal-cms-image"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CmsImage
                        image={images[content2.key]}
                        className="portal-cms-image"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CmsImage
                        image={images[content3.key]}
                        className="portal-cms-image"
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const renderHtmlDownloadList = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const refs = pageFragment.content[0] as IDocumentRef[];

        return (
            <React.Fragment key={key}>
                <Grid item xs={12}>
                    <FolderListCms refs={refs} uploads={uploads} />
                </Grid>
            </React.Fragment>
        );
    };

    const renderTeamList = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const refs = pageFragment.content as ITeamRef[];

        return (
            <Grid item xs={12} md={12} lg={12} key={key}>
                <Grid container spacing={2} direction="row">
                    {refs.map((teamRef, teamIndex) => {
                        return (
                            <Grid item xs={12} md={6} lg={6} key={teamIndex}>
                                <PersonTile
                                    hasAvatar={teams[teamRef.id]?.image != null}
                                    minData={false}
                                    {...teams[teamRef.id]}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        );
    };

    const renderCalculator = (): any => {
        return <FundingCalculator />;
    };
    const handleClick = (id: string) => {
        if (id === open) {
            setOpen(-1); // If it is already open, close it.
        } else {
            setOpen(id); // else open the clicked element
        }
    };
    const renderFaqList = (pageFragment: ICmsPageElement, key: number): any => {
        // @ts-ignore
        const refs = pageFragment.content as IQuestionRef[];
        return (
            <Grid item xs={12} md={12} lg={12} key={key}>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12} md={12} lg={12}>
                        <List
                            component="nav"
                            aria-labelledby="faq-nested-list-subheader"
                            subheader={
                                <ListSubheader
                                    component="h3"
                                    id="nested-list-subheader"
                                >
                                    FAQ
                                </ListSubheader>
                            }
                            className="faq-questions-list"
                        >
                            {refs.map((questionRef) => {
                                return (
                                    <div
                                        key={questionRef.id}
                                        className="faq-questions-list__item"
                                    >
                                        <ListItem
                                            button
                                            onClick={() =>
                                                handleClick(questionRef.id)
                                            }
                                            className="faq-questions-list__item__question"
                                        >
                                            <ListItemText
                                                className="faq-questions-list__item__question__text"
                                                primary={questionRef.question}
                                            />
                                            {open === questionRef.id ? (
                                                <ExpandLess />
                                            ) : (
                                                <ExpandMore />
                                            )}
                                        </ListItem>
                                        <Collapse
                                            in={open === questionRef.id}
                                            timeout="auto"
                                            unmountOnExit
                                            className="faq-questions-list__response-list"
                                        >
                                            <List component="ul" disablePadding>
                                                {questionRef.answers.map(
                                                    (answer) => {
                                                        return (
                                                            <ListItem
                                                                className="faq-questions-list__item__answer"
                                                                key={answer.id}
                                                            >
                                                                {answer.content ? (
                                                                    <div
                                                                        className={
                                                                            "faq-questions-list__item__answer__content"
                                                                        }
                                                                    >
                                                                        <ListItemIcon>
                                                                            <CheckIcon />
                                                                        </ListItemIcon>
                                                                        <HtmlContent
                                                                            content={
                                                                                answer.content
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </ListItem>
                                                        );
                                                    }
                                                )}
                                            </List>
                                        </Collapse>
                                    </div>
                                );
                            })}
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    const renderer: any = {};
    renderer[CmsPageElementTypeEnum.HEADER_1] = renderHeader1;
    renderer[CmsPageElementTypeEnum.HEADER_2] = renderHeader2;
    renderer[CmsPageElementTypeEnum.HEADER_3] = renderHeader3;
    renderer[CmsPageElementTypeEnum.SIMPLE_TEXT] = renderSimpleText;
    renderer[CmsPageElementTypeEnum.HTML] = renderHtml;

    renderer[CmsPageElementTypeEnum.HTML_TEXT_TEXT] = renderHtmlTextText;
    renderer[CmsPageElementTypeEnum.HTML_TEXT_TEXT_TEXT] =
        renderHtmlTextTextText;
    renderer[CmsPageElementTypeEnum.HTML_TEXT_IMAGE] = renderHtmlTextImage;
    renderer[CmsPageElementTypeEnum.HTML_IMAGE_TEXT] = renderHtmlImageText;
    renderer[CmsPageElementTypeEnum.HTML_IMAGE] = renderHtmlImage;
    renderer[CmsPageElementTypeEnum.HTML_IMAGE_IMAGE] = renderHtmlImageImage;
    renderer[CmsPageElementTypeEnum.HTML_IMAGE_IMAGE_IMAGE] =
        renderHtmlImageImageImage;
    renderer[CmsPageElementTypeEnum.DOWNLOAD_LIST] = renderHtmlDownloadList;
    renderer[CmsPageElementTypeEnum.TEAM_LIST] = renderTeamList;
    renderer[CmsPageElementTypeEnum.CALCULATOR] = renderCalculator;
    renderer[CmsPageElementTypeEnum.FAQ_LIST] = renderFaqList;

    return (
        <div className="App-cms-container">
            <Helmet>
                <title>{pageData.data.title}</title>
            </Helmet>
            <Grid container spacing={2}>
                {pageData.elements.map(
                    (pageElement: ICmsPageElement, index: number) => {
                        if (renderer[pageElement.type]) {
                            return renderer[pageElement.type](
                                pageElement,
                                index
                            );
                        } else {
                            DevLogger.logError(
                                "missing type " + pageElement.type
                            );
                            return <React.Fragment key={index} />;
                        }
                    }
                )}
            </Grid>
        </div>
    );
};

export default CmsPageRenderer;
