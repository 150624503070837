import { useLayoutEffect, useState } from "react";

const useWindowScroll = () => {
    const [scrollTop, setScrollTop] = useState<number>(0);
    useLayoutEffect(() => {
        function updateSize() {
            setScrollTop(window.scrollY);
        }
        window.addEventListener("scroll", updateSize);
        updateSize();
        return () => window.removeEventListener("scroll", updateSize);
    }, []);
    return scrollTop;
};
export default useWindowScroll;
