import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import { loadCmsPageProjectSelectboxRequest } from "../../xhr/CmsPageRequests";
import { loadPublicProjectSelectboxRequest } from "../../xhr/ProjectRequests";
import {
    loadCmsPageProjectSelectboxError,
    loadCmsPageProjectSelectboxSuccess,
} from "./CmsPageProjectSelectboxActions";

const LoadCmsPageProjectSelectbox = (
    mandantKey: string,
    projectId: string
): ThunkAction<void, {}, {}, Action> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>) => {
        try {
            const result = await loadCmsPageProjectSelectboxRequest(
                mandantKey,
                projectId
            );
            //can make status check here
            dispatch(loadCmsPageProjectSelectboxSuccess(result.data));
        } catch (err) {
            dispatch(loadCmsPageProjectSelectboxError(err.text));
        }
    };
};

export default LoadCmsPageProjectSelectbox;
