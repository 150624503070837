import { IMenuEntry } from "../../masks/admin/projectForm/tabs/ProjectMenuTab";
import IMandant from "../../types/IMandant";
import IProjectFull from "../../types/IProjectFull";
import { toPortalUrl } from "../toPortalUrl";

const getLinkActionUrl = (
    menuEntry: IMenuEntry,
    mandant?: IMandant,
    activeProject?: IProjectFull | null
): string => {
    if (activeProject && mandant) {
        if (menuEntry.type === "page") {
            return toPortalUrl(
                mandant,
                activeProject.urlKey + "/seite/" + menuEntry.url
            );
        } else {
            return toPortalUrl(
                mandant,
                activeProject.urlKey + "/" + menuEntry.url
            );
        }
    }
    return "";
};

export default getLinkActionUrl;
