export const formatBytes = (bytes?: number | null, decimals = 2): string => {
    if (bytes == null) {
        return "0 Bytes";
    }

    if (bytes === 0) {
        return "0 Bytes";
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export function getImageSizeInBytes(base64Data: string) {
    const withoutPrefix = base64Data.replace(/^data:image\/[a-z]+;base64,/, "");
    const binaryData = atob(withoutPrefix);
    const bytes = new Uint8Array(binaryData.length);

    for (let i = 0; i < binaryData.length; i++) {
        bytes[i] = binaryData.charCodeAt(i);
    }

    return bytes.length;
}
