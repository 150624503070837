import React from "react";
import CookieConsent from "react-cookie-consent";

import IMandant from "../../types/IMandant";
import DevLogger from "../../utils/logger/DevLogger";
import { saveCookieStatus } from "./helper";

function CookiesConsent(props: { mandant: Partial<IMandant> }) {
    const [showBanner, setShowBanner] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    // Check if the user has already given cookieConsent on component mount
    React.useEffect(() => {
        if (props.mandant.key) {
            const fetchData = async () => {
                try {
                    const cookieConsent = localStorage.getItem(
                        "cookieConsent-" + props.mandant.key
                    );
                    if (!cookieConsent) {
                        setShowBanner(true);
                    }
                } catch (error) {
                    DevLogger.logError("Error fetching cookie consent:", error);
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }
    }, [props.mandant.key]);

    return showBanner && !loading ? (
        <CookieConsent
            acceptOnScroll={false}
            buttonText={"Alle zulassen"}
            setDeclineCookie={false}
            cookieName={"noCookieCookie-" + props.mandant?.key}
            contentStyle={{
                flex: "none",
                margin: "0 0 32px 0",
                width: "calc(100% - 32px)",
            }}
            style={{
                color: "#4A4A4A",
                background: "white",
                left: "32px",
                maxWidth: "920px",
                width: "50vw",
                borderRadius: "12px",
                fontSize: "15px",
                filter: "drop-shadow(5px 5px 25px rgba(0, 0, 0, 0.15))",
                padding: "16px",
                marginBottom: "32px",
            }}
            overlay
            buttonStyle={{
                backgroundColor: props.mandant.primaryColor,
                borderRadius: "25px",
                paddingLeft: "16px",
                paddingRight: "16px",
                fontSize: "15px",
                color: "white",
                margin: "0",
            }}
            enableDeclineButton
            declineButtonStyle={{
                backgroundColor: "#4A4A4A",
                color: "white",
                borderRadius: "25px",
                paddingLeft: "16px",
                paddingRight: "16px",
                fontSize: "15px",
                margin: "0 16px 0 0",
            }}
            expires={150}
            declineButtonText={"Nur technisch nötige"}
            onAccept={(acceptedByScrolling) => {
                saveCookieStatus(props.mandant, acceptedByScrolling);
            }}
            onDecline={() => {
                saveCookieStatus(props.mandant);
            }}
        >
            Diese Website verwendet Cookies – nähere Informationen dazu und zu
            Ihren Rechten als Benutzer finden Sie in unseren
            Datenschutzerklärung am Ende der Seite. Wir geben keine Daten an
            Dritte weiter. Zur Verbesserung unseres Angebots möchten wir die
            Zugriffe auf unser Portal analysieren. Wenn Sie nicht damit
            einverstanden sind wählen Sie "Nur technisch nötige". <br />
            Wenn Sie uns unterstützen möchten klicken Sie bitte auf den "Alle
            zulassen" Button. <br />
            Vielen Dank für Ihren Besuch.
        </CookieConsent>
    ) : (
        <></>
    );
}

/*
 *            {Boolean(props.mandant?.matomoTrackingId) && (
 *                 <>
 *                     Zur Verbesserung unseres Angebots möchten wir die Zugriffe
 *                     auf unser Portal analysieren. Wenn Sie nicht damit
 *                     einverstanden sind wählen Sie "Nur technisch nötige". Wenn
 *                     Sie uns unterstützen möchten klicken Sie bitte auf den "Alle
 *                     zulassen" Button. <br />
 *                     Vielen Dank für Ihren Besuch.
 *                 </>
 *             )}
 *             {!props.mandant?.matomoTrackingId && (
 *                 <>
 *                     Klicken Sie bitte auf „Ich habe verstanden“, um nur
 *                     technisch für die Funktion der Seite nötige Cookies zu
 *                     akzeptieren und direkt unsere Website besuchen zu können.{" "}
 *                     <br />
 *                     Vielen Dank für Ihren Besuch.
 *                 </>
 *             )}
 */

export default CookiesConsent;
