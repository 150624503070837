import moment from "moment";

import IDeadlineList from "../IDeadlineList";
import InitialDocumentData from "./InitialDocumentData";
import InitialProjectFullData from "./InitialProjectFullData";

const InitialDeadlineData: IDeadlineList = {
    id: 0,
    title: "",
    deadlineDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
    content: " ",
    project: { ...InitialProjectFullData },
    latitude: 0,
    longitude: 0,
    zoom: 10,
    image: { ...InitialDocumentData },
};
export default InitialDeadlineData;
