import "./MasonryCss.css";

import { Grid, Typography } from "@material-ui/core";
import React from "react";
import Masonry from "react-masonry-css";
import { useHistory } from "react-router-dom";

import EventTile from "../../../../components/project/eventTile/EventTile";
import RoutesEnum from "../../../../routes/RoutesEnum";
import IEventList from "../../../../types/IEventList";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/IProjectFull";
import { toPortalUrl } from "../../../../utils/toPortalUrl";

interface IProjectEventsTabProps {
    project: IProjectFull;
    mandant: IMandant;
}

const breakpointColumnsObj = {
    default: 2,
    700: 1,
};

function ProjectEventsTab(props: IProjectEventsTabProps) {
    const { project, mandant } = props;
    const history = useHistory();

    const linkToEvent = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        history.push(
            toPortalUrl(
                mandant,
                RoutesEnum.PORTAL_EVENT + "/" + event.currentTarget.dataset.id
            )
        );
    };

    return (
        <>
            <Typography variant="h3">Veranstaltungen</Typography>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="masonry-grid gap-top"
                columnClassName="masonry-grid_column"
            >
                {project.events
                    .sort((a: any, b: any) => {
                        const firstdate = new Date(a.theStart);
                        const seconddate = new Date(b.theStart);
                        return seconddate.getTime() - firstdate.getTime();
                    })
                    .map((event: IEventList, index) => (
                        <Grid item key={`event-${index}`}>
                            <EventTile {...event} onLinkClick={linkToEvent} />
                        </Grid>
                    ))}
            </Masonry>
        </>
    );
}

export default ProjectEventsTab;
