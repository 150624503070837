import React from "react";
import { Route } from "react-router-dom";

import LoginContainer from "../../masks/admin/loginContainer/LoginContainer";
import IMandant from "../../types/IMandant";
import { isStillAuthenticated } from "../../utils";

const PrivateRouteWithProps: React.FC<{
    component: any;
    path: string;
    exact?: boolean;
    mandant: IMandant;
    handleBackhome?: any;
}> = (props) => {
    const condition = isStillAuthenticated(props.mandant.key);

    return condition ? (
        <Route
            path={props.path}
            exact={props.exact}
            render={(renderProps: any) => (
                <props.component
                    {...renderProps}
                    handleSelectedHome={props.handleBackhome}
                    mandant={props.mandant}
                />
            )}
        />
    ) : (
        <Route
            path={props.path}
            exact={props.exact}
            render={(renderProps: any) => (
                <LoginContainer {...renderProps} mandant={props.mandant} />
            )}
        />
    );
};
export default PrivateRouteWithProps;
