import "ol/ol.css";
import "./publicMap.css";
import "ol-ext/dist/ol-ext.css";
import "ol-ext/style/FontAwesomeDef.js";

import moment from "moment";
import { Feature, Map, Overlay, View } from "ol";
import { FullScreen, defaults as defaultControls } from "ol/control";
import Point from "ol/geom/Point";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import OverlayPositioning from "ol/OverlayPositioning";
import { transform } from "ol/proj";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import React, { useEffect } from "react";

import { Format } from "../../../config/config";

interface IOlMapProps {
    projects?: any;
    events?: any;
    mandant: string;
}

/* circle feature style */
const myStyle = new Style({
    image: new Circle({
        radius: 7,
        fill: new Fill({ color: "black" }),
        stroke: new Stroke({
            color: [255, 0, 0],
            width: 4,
        }),
    }),
});
/* the layer that contain the Marker */
const markerLayer = new VectorLayer({
    source: new VectorSource({ wrapX: false }),
    style: myStyle,
});

const olMap = new Map({
    target: undefined,
    layers: [
        new TileLayer({
            source: new XYZ({
                url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                maxZoom: 18,
            }),
        }),
        markerLayer,
    ],
    controls: defaultControls().extend([new FullScreen()]),

    view: new View({
        center: transform([11.576124, 48.137154], "EPSG:4326", "EPSG:3857"),
        zoom: 5,
        // projection: "EPSG:4326",
    }),
});

/* function to transform coordinates type */

/* the search control instance */
/* defining the map and assigning to it the layers and controls */

/*  function to put a Point marker on the map */

/* put multiple markers */
const putEvents = (events: any) => {
    events.forEach((event: any) => {
        const transformed = transform(
            [event.longitude, event.latitude],
            "EPSG:4326",
            "EPSG:3857"
        );
        const source = markerLayer.getSource();
        const myfeature = new Feature({
            geometry: new Point(transformed),
            value: event,
            name: "event",
        });
        myfeature.setStyle(
            new Style({
                image: new Circle({
                    radius: 7,
                    fill: new Fill({ color: "red" }),
                    stroke: new Stroke({
                        color: "red",
                        width: 4,
                    }),
                }),
            })
        );
        source.addFeature(myfeature);
    });
};
const putProjects = (projects: any) => {
    projects.forEach((project: any) => {
        const transformed = transform(
            [project.longitude, project.latitude],
            "EPSG:4326",
            "EPSG:3857"
        );
        const source = markerLayer.getSource();
        const myfeature = new Feature({
            geometry: new Point(transformed),
            value: project,
            name: "project",
        });
        myfeature.setStyle(
            new Style({
                image: new Circle({
                    radius: 7,
                    fill: new Fill({ color: "purple" }),
                    stroke: new Stroke({
                        color: "green",
                        width: 4,
                    }),
                }),
            })
        );
        source.addFeature(myfeature);
    });
};

/* the OLMap Component */
export default function PublicMap(props: IOlMapProps) {
    let popup: Overlay;
    const { projects, events, mandant } = props;
    React.useEffect(() => {
        olMap.getView().animate(
            { zoom: 6 },
            {
                center: transform(
                    [9.73322, 52.37052],
                    "EPSG:4326",
                    "EPSG:3857"
                ),
            }
        );

        if (projects.length > 0 || events.length > 0) {
            putProjects(projects);
            putEvents(events);
        }
    }, [events, projects]);

    const displayPopup = (content: string, feature: any) => {
        const popupElement: any = document.getElementById("popup");
        popupElement.style.display = "block";
        popupElement.innerHTML = content;

        popup = new Overlay({
            id: "popup",
            element: popupElement,
            position: feature.getGeometry().getCoordinates(),
            positioning: OverlayPositioning.BOTTOM_CENTER,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
        });

        olMap.addOverlay(popup);
    };

    const handlePointerMove = (e: any) => {
        /*  if (popup) {
            popup.setPosition(undefined);
        } */
        olMap.forEachFeatureAtPixel(e.pixel, (feature) => {
            const myData = feature.getProperties().value;
            const type =
                feature.getProperties().name === "event"
                    ? "veranstaltung"
                    : "projekt";
            const dataTohtml =
                myData.title +
                "<br/>" +
                "Start :" +
                moment(myData.theStart).format(
                    feature.getProperties().name === "project"
                        ? Format.date
                        : Format.dateTime
                ) +
                "<br/>" +
                "Ende :" +
                moment(myData.theEnd).format(
                    feature.getProperties().name === "project"
                        ? Format.date
                        : Format.dateTime
                ) +
                "<br/>";
            displayPopup(
                dataTohtml +
                    `<a  href='/${mandant}/${type}/${myData.id}
                    ' > ${dataTohtml}<a/>`,
                feature
            );
        });
    };

    const handleZoomEnd = () => {
        olMap
            .getOverlays()
            .getArray()
            .forEach((overlay) => {
                overlay.setPosition(undefined);
            });
    };

    useEffect(() => {
        olMap.setTarget("map");
        olMap.on("click", handleZoomEnd);
        olMap.on("pointermove", handlePointerMove);
        return () => olMap.setTarget(undefined);
    }, []);

    return (
        <div className="map-wrapper">
            <div className="public-map-container" id="map">
                <div className="ol-popup" id="popup" />
            </div>
        </div>
    );
}
