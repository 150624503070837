import { Alert } from "@material-ui/lab";
import React from "react";
import { useHistory } from "react-router-dom";

import Comments from "../../../../components/comment/Comments";
import ProposalMap from "../../../../components/olMap/proposal/ProposalMap";
import RoutesEnum from "../../../../routes/RoutesEnum";
import IUser from "../../../../store/userStore/interfaces/IUser";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/IProjectFull";
import { toPortalUrl } from "../../../../utils/toPortalUrl";

interface IProjectParticipateTabProps {
    project: IProjectFull;
    mandant: IMandant;
    actualUser: IUser | null;
}

function ProjectParticipateTab(props: IProjectParticipateTabProps) {
    const { project, mandant, actualUser } = props;

    const history = useHistory();

    const onClickAnmelden = () => {
        history.push(toPortalUrl(mandant, RoutesEnum.PORTAL_LOGIN));
    };

    return (
        <>
            {!actualUser && project.flagCommentsRegistered == true && (
                <Alert severity="info" className="gap-top">
                    Willkommen auf der &quot;mitmachen&quot; Seite. Um selbst
                    Kommentare und Vorschläge zu erstellen{" "}
                    <a onClick={onClickAnmelden} className="clickable">
                        melden Sie sich bitte zuerst im Portal an.
                    </a>
                </Alert>
            )}

            {project.flagMapComments && (
                <ProposalMap project={project} signedUser={actualUser} />
            )}

            {project.flagCommentsOpen && (
                <div className="project-comments">
                    <Comments
                        project={project}
                        projectId={project.id}
                        projectRoles={project.roles}
                        mandant={mandant}
                        user={actualUser}
                    />
                </div>
            )}
        </>
    );
}

export default ProjectParticipateTab;
