import { Grid } from "@material-ui/core";
import React from "react";

import HtmlContent from "../../../../components/cms/HtmlContent";
import { API_URL_STATIC } from "../../../../config/config";
import IUser from "../../../../store/userStore/interfaces/IUser";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/IProjectFull";

interface IProjectConsultingTabProps {
    project: IProjectFull;
    mandant: IMandant;
    actualUser: IUser | null;
}

function ProjectConsultingTab(props: IProjectConsultingTabProps) {
    const { project } = props;

    return (
        <>
            <Grid container spacing={4}>
                {!!project.consultingText && !project.consultingText2 && (
                    <Grid item xs={12} md={12} lg={12}>
                        <HtmlContent
                            className="content-block"
                            content={project.consultingText}
                        />
                    </Grid>
                )}

                {!!project.consultingText && !!project.consultingText2 && (
                    <>
                        <Grid item xs={12} md={6}>
                            <HtmlContent
                                className="content-block"
                                content={project.consultingText}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <HtmlContent
                                className="content-block"
                                content={project.consultingText2}
                            />
                        </Grid>
                    </>
                )}
            </Grid>

            {project?.imageConsulting?.url && (
                <>
                    <img
                        src={API_URL_STATIC + project?.imageConsulting?.url}
                        alt={project?.imageConsulting?.description}
                        style={{ maxWidth: "100%" }}
                        className="gap-top"
                    />
                </>
            )}
        </>
    );
}

export default ProjectConsultingTab;
