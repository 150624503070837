import "./HtmlContent.css";

import { Typography, TypographyVariant } from "@material-ui/core";
import React from "react";

interface IHtmlContentProps {
    content: string;
    className?: string;
    isTypology?: boolean;
    variant?: TypographyVariant;
}

const HtmlContent = (props: IHtmlContentProps) => {
    if (!props.content) {
        return <></>;
    }
    if (props.isTypology) {
        return (
            <Typography
                className={`gap-top ${props.className}`}
                variant={props.variant}
                dangerouslySetInnerHTML={{ __html: props.content }}
            />
        );
    }

    return (
        <div
            className={"html-content-force-style " + props.className}
            dangerouslySetInnerHTML={{ __html: props.content }}
        />
    );
};

export default HtmlContent;
