import IEventGalleryImage from "../IEventGalleryImage";
import InitialDocumentData from "./InitialDocumentData";

const InitialEventGalleryElementData: IEventGalleryImage = {
    id: null,
    priority: 0,
    type: "",
    document: { ...InitialDocumentData },
    event: "",
};

export default InitialEventGalleryElementData;
