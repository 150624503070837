export interface IFundingTypes {
    id: string;
    label: string;
}
export const fundingTypes: IFundingTypes[] = [
    { id: "1", label: "KFW85" },
    { id: "2", label: "KFW70" },
    { id: "3", label: "KFW55" },
    { id: "4", label: "KFW40" },
];

export const ERROR_MESSAGE_UNDER_LIMIT_COSTS =
    "Beträge unter 150.000 € werden mit 20% gefördert.";
export const ERROR_MESSAGE_KFW85_BASIC_FUNDING_CAP_LIMIT_VALUE =
    "KFW85: Ab 40.000.000€ anrechenbaren Kosten bleibt der Deckel der Basisforderung bei 350.000€";
export const ERROR_MESSAGE_KFW70_BASIC_FUNDING_CAP_LIMIT_VALUE =
    "KFW70: Ab 4.454.000€ anrechenbaren Kosten bleibt der Deckel der Basisforderung bei 400.000€";
export const ERROR_MESSAGE_KFW55_BASIC_FUNDING_CAP_LIMIT_VALUE =
    "KFW55: Ab 2.857.000€ anrechenbaren Kosten bleibt der Deckel der Basisforderung bei 450.000€";
export const ERROR_MESSAGE_KFW40_BASIC_FUNDING_CAP_LIMIT_VALUE =
    "KFW40: Ab 2.258.000€ anrechenbaren Kosten bleibt der Deckel der Basisforderung bei 350.000€";
