/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { connectRouter } from "connected-react-router";

import history from "../utils/history";
import CmsPageProjectSelectboxReducer from "./cmsPageProjectSelectbox/reducer/CmsPageProjectSelectboxReducer";
import languageProviderReducer from "./languageStore/LanguageReducer/LanguageReducer";
import MandantReducer from "./mandantStore/reducer/MandantReducer";
import ProjectReducer from "./project/reducer/ProjectReducer";
import ProjectSelectboxReducer from "./projectSelectbox/reducer/ProjectSelectboxReducer";
import UserReducer from "./userStore/userReducer/UserReducer";

/**
 * main reducer
 */
export default function createReducer(injectedReducers: any = {}) {
    return {
        language: languageProviderReducer,
        mandant: MandantReducer,
        projectSelectbox: ProjectSelectboxReducer,
        cmsPageProjectSelectbox: CmsPageProjectSelectboxReducer,
        activeProject: ProjectReducer,
        user: UserReducer,
        router: connectRouter(history),
        ...injectedReducers,
    };
}
