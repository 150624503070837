export enum ProjectTabEnum {
    START_TAB = "start",
    INFO_TAB = "ueber",
    CAMPAIGN_TAB = "termine",
    EVENTS_TAB = "ereignisse",
    RESULTS_TAB = "ergebnisse",
    PARTICIPATE_TAB = "teilnehmen",
    CONSULTING_TAB = "beratung",
    TEAM_TAB = "team",
}
