import "./EventTile.css";

import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import IconDelete from "@material-ui/icons/Delete";
import IconEdit from "@material-ui/icons/Edit";
import IconPublish from "@material-ui/icons/Visibility";
import IconUnPublish from "@material-ui/icons/VisibilityOff";
import moment from "moment";
import React from "react";

import { API_URL_STATIC, Format } from "../../../config/config";
import IEventList from "../../../types/IEventList";

interface IEventTileParams extends IEventList {
    onLinkClick: (event: any) => void;
    onDeletelick?: (event: any) => void;
    onPublishClick?: (event: any) => void;
    editLabel?: string;
    fromPublicMask?: boolean;
    onDownloadClick?: (event: any) => void;
}

const EventTile = (props: IEventTileParams) => {
    const eventImage = props.imageMenu
        ? props.imageMenu
        : props.project?.imageMenu;
    return (
        <Card
            className="with-shadow rounded-bottom-only tile__card"
            variant="outlined"
            data-id={props.id}
            onClick={
                props.onLinkClick && !props.editLabel
                    ? props.onLinkClick
                    : () => null
            }
        >
            <CardActionArea disableRipple={true}>
                <CardMedia
                    style={{ height: "200px" }}
                    image={API_URL_STATIC + eventImage?.url}
                    title={eventImage?.description}
                />
                <CardContent>
                    <Typography
                        gutterBottom
                        variant="subtitle1"
                        component="h3"
                        className={"text-color-primary"}
                    >
                        Ab: {moment(props.theStart).format(Format.dateTime)}
                        {" Uhr "}
                        bis: {moment(props.theEnd).format("HH:mm")}
                        {" Uhr "}
                        {!!props.locationName && (
                            <>
                                <br />
                                <a
                                    className="content-ellipsis"
                                    href={props.locationUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {props.locationName}
                                </a>
                            </>
                        )}
                        {props.fromPublicMask && (
                            <CalendarTodayIcon
                                data-id={props.id}
                                color="primary"
                                onClick={props.onDownloadClick}
                                fontSize="small"
                            />
                        )}
                        {props.project && (
                            <>
                                <br />
                                Projekt: {props.project?.title}
                            </>
                        )}
                    </Typography>
                    <Typography
                        className="content-ellipsis"
                        gutterBottom
                        variant="h2"
                        component="h2"
                    >
                        {props.title}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        component="p"
                        align="left"
                        className="content-ellipsis"
                    >
                        {props.shortDesc}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                {!props.editLabel && (
                    <Button
                        data-id={props.id}
                        size="small"
                        color="primary"
                        onClick={props.onLinkClick}
                        endIcon={<ArrowForwardIcon />}
                    >
                        Mehr lesen
                    </Button>
                )}

                {props.editLabel && (
                    <IconButton
                        data-id={props.id}
                        onClick={props.onLinkClick}
                        title={props.editLabel}
                    >
                        <IconEdit />
                    </IconButton>
                )}
                {props.onPublishClick && (
                    <IconButton
                        data-id={props.id}
                        onClick={props.onPublishClick}
                        title={
                            props.flagIsPublished
                                ? "Zurücknehmen"
                                : "Veröffentlichen"
                        }
                    >
                        {props.flagIsPublished ? (
                            <IconUnPublish />
                        ) : (
                            <IconPublish />
                        )}
                    </IconButton>
                )}
                {props.onDeletelick && (
                    <IconButton
                        data-id={props.id}
                        color="secondary"
                        title="Löschen"
                        onClick={props.onDeletelick}
                    >
                        <IconDelete />
                    </IconButton>
                )}
            </CardActions>
        </Card>
    );
};

export default EventTile;
