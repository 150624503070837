import { Grid } from "@material-ui/core";
import React from "react";

import HtmlContent from "../../../../components/cms/HtmlContent";
import FolderList from "../../../../components/folderList/FolderList";
import ImmageGallery from "../../../../components/imageGallery/ImageGallery";
import IEventFull from "../../../../types/IEventFull";

interface IAboutProps {
    event: IEventFull;
}

function AboutTab(props: IAboutProps) {
    const { event } = props;

    return (
        <>
            {!event.description2 && (
                <HtmlContent
                    className="content-block"
                    content={event.description}
                />
            )}
            {event.description2 && (
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <HtmlContent
                            className="content-block"
                            content={event.description}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <HtmlContent
                            className="content-block"
                            content={String(event.description2)}
                        />
                    </Grid>
                </Grid>
            )}

            {Boolean(event.attachments?.length) && (
                <FolderList files={event.attachments} fromEventTab={true} />
            )}

            {Boolean(event.images?.length) && (
                <>
                    <h3>Bilder zur Veranstaltung</h3>
                    <ImmageGallery images={event.images} />
                </>
            )}
        </>
    );
}

export default AboutTab;
