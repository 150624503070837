import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import { logoutError, logoutSuccess } from "../userAction/UserAction";

const LogoutAction = (mandantKey: string): ThunkAction<void, {}, {}, Action> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>) => {
        try {

            localStorage.removeItem(
              "jwt-" + mandantKey
            );
            localStorage.removeItem(
              "expiresAt-" + mandantKey
            );
            localStorage.removeItem(
              "user-" + mandantKey
            );

            //const response = await logoutRequest();
            //can make status check here
            dispatch(logoutSuccess());
        } catch (err) {
            dispatch(logoutError(err.text));
        }
    };
};

export default LogoutAction;
