import IUser from "../../store/userStore/interfaces/IUser";
import InitialDocumentData from "./InitialDocumentData";

const InitialLoginUserData: IUser = {
    id: 0,
    dateCreated: "",
    description: "",
    displayTitle: "",
    email: "",
    firstname: "",
    nickName: "",
    city: "",
    zip: "",
    street: "",
    streetNo: "",
    image: { ...InitialDocumentData },
    lastLogin: "",
    lastname: "",
    salutation: "",
    roles: [],
    loginToken: {
        expiresAt: "",
        token: "",
    },
    password: "",
};
export default InitialLoginUserData;
