import * as React from "react";

import IDocument from "../../types/IDocument";
import CmsImage from "../cms/CmsImage";

interface IImageHeaderParams {
    image: IDocument;
    isMobile: boolean;
}

const ImageHeader = (props: IImageHeaderParams) => {
    const className = props.isMobile
        ? "App-main-image isMobile"
        : "App-main-image";

    return (
        <div className={className}>
            <CmsImage image={props.image} />
        </div>
    );
};

export default ImageHeader;
