import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { CSSProperties } from "react";

import IMandant from "../../types/IMandant";

interface ITabPanelProps {
    children?: React.ReactNode;
    idKey: string;
    index: any;
    value: any;
}

function TabPanel(props: ITabPanelProps) {
    const { children, value, index, idKey, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${idKey}-tabpanel-${index}`}
            aria-labelledby={`${idKey}-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function createTabProps(key: string, index: number | string) {
    return {
        id: `${key}-tab-${index}`,
        "aria-controls": `${key}-tabpanel-${index}`,
    };
}

export interface ITab {
    id: string;
    label: React.ReactNode;
    content: React.ReactNode;
    display: boolean;
    tabKey: string | number;
}

interface ITabContainerProps {
    tabs: ITab[];
    activeTab: number | string;
    ariaLabel: string;
    idKey: string;
    handleChange: (event: React.ChangeEvent<{}>, newValue: any) => void;
    hideTabHeader?: boolean;
    isAdmin?: boolean;
    mandant?: IMandant;
}

export default function TabContainer(props: ITabContainerProps) {
    const {
        tabs,
        activeTab,
        ariaLabel,
        handleChange,
        hideTabHeader,
        idKey,
        isAdmin,
        mandant,
    } = props;

    const styles: CSSProperties = isAdmin
        ? { backgroundColor: "#00b5e2", border: "none" }
        : {
              backgroundColor: "white",
              color: mandant?.headerH1Color ? mandant?.headerH1Color : "black",
              border: "none",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          };

    if (hideTabHeader) {
        styles.display = "none";
    }

    return (
        <>
            <AppBar position="static" variant="outlined" style={styles}>
                <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    aria-label={ariaLabel}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {!hideTabHeader &&
                        tabs
                            .filter((tabData: ITab) => {
                                return tabData.display;
                            })
                            .map((tabData: ITab) => {
                                return (
                                    <Tab
                                        key={"tabhead-" + tabData.tabKey}
                                        label={tabData.label}
                                        value={tabData.tabKey}
                                        {...createTabProps(
                                            idKey,
                                            tabData.tabKey
                                        )}
                                    />
                                );
                            })}
                </Tabs>
            </AppBar>
            {tabs
                .filter((tabData: ITab) => {
                    return tabData.display;
                })
                .map((tabData: ITab) => {
                    return (
                        <TabPanel
                            value={activeTab}
                            idKey={idKey}
                            index={tabData.tabKey}
                            key={"tabbody-" + tabData.tabKey}
                        >
                            {tabData.content}
                        </TabPanel>
                    );
                })}
        </>
    );
}
