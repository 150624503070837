import {
    ADMIN_EVENT,
    ADMIN_PUBLISH_EVENT,
    PORTAL_EVENT,
    UPLOADS_ATTACHEMENTS_SORT,
} from "../enums/Endpoints";
import IEventFull from "../types/IEventFull";
import IEventGalleryImage from "../types/IEventGalleryImage";
import IEventList from "../types/IEventList";
import {
    deleteData,
    getData,
    postData,
    putData,
} from "../utils/httpRequest/request";

const loadPublicEventList = (mandant: string): Promise<any> => {
    return getData<IEventList[]>(PORTAL_EVENT, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

const loadPublicEventData = (
    mandant: string,
    eventId: string
): Promise<any> => {
    return getData<IEventFull>(PORTAL_EVENT + eventId, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

const loadSingleEventRequest = (
    mandant: string,
    eventId: string
): Promise<any> => {
    return getData<IEventFull>(ADMIN_EVENT + eventId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
const deleteEventRequest = (id: number, mandant: string): Promise<any> => {
    return deleteData<any>(ADMIN_EVENT, id, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

/* UPDATE event */
const updateEventRequest = (
    mandant: string,
    event: IEventFull
): Promise<any> => {
    return putData<any, string>(ADMIN_EVENT, event.id, event, {
        mandant,
        withLang: true,
        isPublic: false,
    });
};

const createEventRequest = (
    mandant: string,
    event: IEventFull
): Promise<any> => {
    return postData<any, any>(ADMIN_EVENT, event, {
        mandant,
        withLang: true,
        isPublic: false,
    });
};

const updateEventGalleryRequest = (
    mandant: string,
    eventId: number,
    type: string,
    images: IEventGalleryImage[]
): Promise<any> => {
    return putData<any, string>(
        ADMIN_EVENT + eventId + "/gallery/",
        type,
        images,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};

const switchEventPublishFlagRequest = (
    projectId: number,
    mandant: string
): Promise<any> => {
    return putData<any, string>(
        ADMIN_PUBLISH_EVENT,
        projectId,
        {},
        { mandant, withLang: true, isPublic: false }
    );
};

const sortEventGalleryRequest = (
    mandant: string,
    images: number[],
    eventId: number,
    type: string
): Promise<any> => {
    return putData<any, string>(
        ADMIN_EVENT,
        eventId + "/gallery/" + type + "/sort",
        images,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};
const loadEventAttachmentsRequest = (
    mandant: string,
    eventId: string
): Promise<any> => {
    return getData<any>(`${ADMIN_EVENT}${eventId}/uploads/attachments/`, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
//updateEventFileRequest
const updateEventFileRequest = (
    mandant: string,
    eventId: number,
    type: string,
    images: IEventGalleryImage[]
): Promise<any> => {
    return putData<any, string>(
        ADMIN_EVENT + eventId + "/uploads/",
        type + "/",
        images,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};
/* save event files order request */

const sortEventFilesRequest = (
    mandant: string,
    files: any,
    eventId: number
): Promise<any> => {
    return putData<any, string>(
        ADMIN_EVENT,
        eventId + UPLOADS_ATTACHEMENTS_SORT,
        files,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};
export {
    loadPublicEventList,
    loadPublicEventData,
    loadSingleEventRequest,
    deleteEventRequest,
    updateEventRequest,
    createEventRequest,
    updateEventGalleryRequest,
    switchEventPublishFlagRequest,
    sortEventGalleryRequest,
    loadEventAttachmentsRequest,
    updateEventFileRequest,
    sortEventFilesRequest,
};
