import "../ProjectData.css";

import { Box, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";

import campaignPlaceHolder from "../../../../assets/images/campaign-placeholder.png";
import { API_URL_STATIC, Format } from "../../../../config/config";
import ICampaign from "../../../../types/appointment/ICampaign";
import IPortalAppointment from "../../../../types/appointment/IPortalAppointment";
import IPortalBookingParticipant from "../../../../types/appointment/IPortalBookingParticipant";
import IMandant from "../../../../types/IMandant";
import IUserData from "../../../../types/IUserData";
import stringIsEmpty from "../../../../utils/form/stringIsEmpty";

interface IAppointmentSuccessMessageProps {
    mandant: IMandant;
    campaignData: ICampaign;
    appointment: IPortalAppointment;
    userData: Partial<IUserData>;
    participant: IPortalBookingParticipant;
}

const AppointmentSuccessMessage: React.FunctionComponent<
    IAppointmentSuccessMessageProps
> = (props: IAppointmentSuccessMessageProps) => {
    const { campaignData, appointment, userData, participant } = props;

    if (!campaignData) {
        return <></>;
    }

    return (
        <Grid container className="gap-top">
            <Grid item xs={12} sm={6}>
                <Typography gutterBottom variant="h5">
                    {campaignData.title}
                </Typography>
                <Typography gutterBottom variant="h6">
                    Ihr Termin:{" "}
                    {moment(appointment.startDate).format(Format.dateTime)} -{" "}
                    {moment(appointment.startDate)
                        .add(appointment.duration, "m")
                        .format(Format.time)}
                </Typography>
                <Typography gutterBottom variant="h6">
                    Ort: {campaignData.location}
                    <br />
                    Zimmer: {appointment.campaignRoom?.name}
                    <br />
                </Typography>
                <Typography variant="body1">
                    Sehr geehrte/r{" "}
                    <strong>
                        {userData.salutation} {userData.firstname}{" "}
                        {userData.lastname}
                    </strong>
                    ,<br />
                    Ihr Termin wurde bestätigt.
                    <br />
                    Vielen Dank für Ihre Reservierung.
                    <br />
                    <br />
                    {!stringIsEmpty(participant?.email) && (
                        <>
                            <br />
                            Sie erhalten in Kürze eine Bestätigungs-E-Mail mit
                            Ihren Buchungsdaten.
                        </>
                    )}
                    {participant.flagSendPostalMail && (
                        <>
                            <br />
                            Eine Terminbestätigung wird wie gewünscht per Post
                            an die von Ihnen angegebene Adresse versandt:
                            <br />
                            <strong>
                                {userData.salutation} {userData.firstname}{" "}
                                {userData.lastname}
                                <br />
                                {userData.street} {userData.streetNo}
                                <br />
                                {userData.zip} {userData.city}
                                <br />
                            </strong>
                        </>
                    )}
                    <br />
                    <br />
                    Wir freuen uns auf Sie.
                    <br />
                    <br />
                    Viele Grüße,
                    <br />
                    Das STEG Team.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Box>
                    {campaignData?.image?.url ? (
                        <img
                            src={API_URL_STATIC + campaignData?.image?.url}
                            alt={campaignData.image.description}
                            style={{ maxWidth: "100%" }}
                        />
                    ) : (
                        <img
                            src={campaignPlaceHolder}
                            alt="campaign-not-found"
                            style={{ maxWidth: "100%" }}
                        />
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default AppointmentSuccessMessage;
