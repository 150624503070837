import "./LoginContainer.css";

import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    TextField,
} from "@material-ui/core";
import IconVpnKey from "@material-ui/icons/VpnKey";
import React from "react";
import { Helmet } from "react-helmet";
import {
    MapDispatchToPropsParam,
    MapStateToPropsParam,
    connect,
} from "react-redux";
import { Redirect } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import favIcon from "../../../assets/favicons/favicon.gif";
import loginImage from "../../../assets/images/login-side-original.jpg";
import stegLogo from "../../../assets/images/logo.png";
import InDevelopmentBanner from "../../../components/inDevelopmentBanner/InDevelopmentBanner";
import { IS_TESTSYSTEM } from "../../../config/config";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import LoginAction from "../../../store/userStore/asyncActions/LoginAction";
import IUser from "../../../store/userStore/interfaces/IUser";
import IMandant from "../../../types/IMandant";
import { isStillAuthenticated } from "../../../utils";
import { toPortalUrl } from "../../../utils/toPortalUrl";

type ILoginContainerStateProps = {
    emailIsValid: boolean;
    emailErrorMessage: string;
    passwordIsValid: boolean;
    passwordErrorMessage: string;
    errorMessage: string;
    loading: boolean;
    userData: IUser | null;
    mandant: IMandant;
};

type ILoginContainerDispatchProps = {
    handleOnLoginEvent: (
        email: string,
        password: string,
        mandant: IMandant
    ) => void;
};

type Props = ILoginContainerStateProps & ILoginContainerDispatchProps;

/** component */

const LoginContainer: React.FunctionComponent<Props> = (props: any) => {
    const {
        handleOnLoginEvent,
        loading,
        emailIsValid,
        emailErrorMessage,
        errorMessage,
        passwordErrorMessage,
        passwordIsValid,
        mandant,
    } = props;

    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const handleOnLoginClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleOnLoginEvent(email, password, mandant);
    };

    const pageTitle = "STEG Bürgerbeteiligungsportal";

    const renderInDevelopmentBanner = () => {
        if (IS_TESTSYSTEM) {
            return <InDevelopmentBanner />;
        }
        return <></>;
    };

    if (
        localStorage.getItem("jwt-" + mandant.key) !== null &&
        isStillAuthenticated(mandant.key)
    ) {
        return <Redirect to={toPortalUrl(mandant, RoutesEnum.ADMIN_HOME)} />;
    } else {
        const sectionStyle = {
            width: "100%",
            height: "100%",
            backgroundImage: `url(${loginImage})`,
            backgroundPosition: "center",
        };
        return (
            <Grid container>
                <Helmet>
                    <title>{pageTitle}</title>
                    <link
                        rel="icon"
                        type="image/png"
                        href={favIcon}
                        sizes="16x16"
                    />
                </Helmet>
                <Grid item xs={4}>
                    <div style={sectionStyle} />
                </Grid>
                <Grid item xs={8}>
                    {renderInDevelopmentBanner()}
                    <Card variant="outlined" className="login-form-container">
                        <img alt="logo" className="logo-image" src={stegLogo} />
                        <form onSubmit={handleOnLoginClick}>
                            <CardContent className="login-form-content">
                                <TextField
                                    error={emailIsValid}
                                    helperText={emailErrorMessage}
                                    required
                                    name="email"
                                    id="outlined-basic"
                                    label="E-Mail Adresse"
                                    variant="outlined"
                                    className="input-text"
                                    type="text"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <TextField
                                    error={passwordIsValid}
                                    helperText={passwordErrorMessage}
                                    required
                                    name="password"
                                    id="outlined-basic"
                                    label="Passwort"
                                    variant="outlined"
                                    className="input-text"
                                    type="password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />

                                {loading ? (
                                    <CircularProgress />
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={"login-button"}
                                        startIcon={<IconVpnKey />}
                                    >
                                        Jetzt Anmelden
                                    </Button>
                                )}
                            </CardContent>
                        </form>
                        {("" + errorMessage).length > 0 && (
                            <p className="login-form-error-message">
                                {errorMessage}
                            </p>
                        )}

                        {/*
                        <p className="login-form-link">
                            Ich habe mein Passwort vergessen.
                        </p>*/}
                    </Card>
                </Grid>
            </Grid>
        );
    }
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    ILoginContainerStateProps,
    {},
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        emailIsValid: state.user.emailIsValid,
        emailErrorMessage: state.user.emailErrorMessage,
        passwordIsValid: state.user.passwordIsValid,
        passwordErrorMessage: state.user.passwordErrorMessage,
        loading: state.user.loading,
        userData: state.user.userData,
        errorMessage: state.user.errorMessage,
        mandant: state.mandant.mandant,
    };
};
/**
 * for async func we only set handleOnLoginEvent: (params:any) => LoginAction(params)
 * for non async we use  handleOnLoginEvent: () => {dispatch(SomeActions())
 */
const mapDispatchToProps: MapDispatchToPropsParam<
    ILoginContainerDispatchProps,
    {}
> = (dispatch: ThunkDispatch<IApplicationRootState, {}, Action>) => {
    return {
        handleOnLoginEvent: (
            email: string,
            password: string,
            mandant: IMandant
        ) => dispatch(LoginAction(email, password, mandant)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
