import { action } from "typesafe-actions";

import { ICmsPageChild } from "../../types/ICmsPage";
import ActionTypes from "./CmsPageProjectSelectboxTypesEnum";

export const loadCmsPageProjectSelectboxSuccess = (
    cmsPageProjectData: ICmsPageChild[]
) => action(ActionTypes.LOAD_SUCCESS, cmsPageProjectData);
export const loadCmsPageProjectSelectboxError = (error: any) =>
    action(ActionTypes.LOAD_ERROR, error);
