import IMandant from "../IMandant";
import InitialDocumentData from "./InitialDocumentData";
import InitialPartnerLogoData from "./InitialPartnerLogoData";

const InitialMandantData: IMandant = {
    id: 0,
    key: "",
    name: "",
    slogan: "",
    matomoTrackingId: "",
    domain: "",
    contactEmail: "",
    notifyEmailsElse: "",
    urlWebsite: "",
    headerImage: { ...InitialDocumentData },
    logoImage: { ...InitialDocumentData },
    partnerLogoImage: { ...InitialDocumentData },
    partnerLogos: [],
    favIcon: { ...InitialDocumentData },
    backgroundColorFooter1: "#222222",
    backgroundColorFooter2: "#000000",
    buttonBackground: "rgba(1,1,1,0.7)",
    buttonBorder: "none",
    buttonTextColor: "#ffffff",
    colorLink: "#000000",
    colorLinkHover: "#000000",
    contactFormTextAfter:
        "Das ist der Text der nach dem Kontakt Formular steht",
    contactFormTextBefore:
        "Das ist der Text der vor dem Kontakt Formular steht",
    footerLeftText: "Hallo ich bin der Text im Footer",
    footerLeftTitel: "Footer Titel",
    gap: "10px",
    headerH1Color: "black",
    headerH1FontFamily:
        "Verdana, sans-serif, Verdana,Arial,Helvetica,sans-serif",
    headerH1Size: "15pt",
    headerH1Weight: "400",
    headerH2Color: "black",
    headerH2H3FontFamily:
        "Verdana, sans-serif, Verdana,Arial,Helvetica,sans-serif",
    headerH2Size: "13pt",
    headerH2Weight: "400",
    headerH3Color: "black",
    headerH3Size: "11pt",
    headerH3Weight: "400",
    primaryColor: "black",
    secondaryColor: "black",
    textColor: "rgb(119, 119, 119)",
    textColorFooter1: "white",
    textColorFooter2: "white",
    textColorSmaller: "black",
    textFontFamily: "Verdana, sans-serif, Verdana,Arial,Helvetica,sans-serif",
    textFontSize: "11pt",
    textFontSizeSmaller: "9pt",
    textFontWeight: "normal",
    /**
     * seems to be missing
     */
    linkColorFooter1: "white",
    linkColorFooter1Hover: "white",
    linkColorFooter2: "white",
    linkColorFooter2Hover: "white",
    flagUseOldUi: false,
};

export default InitialMandantData;
