import IMandant from "../../types/IMandant";

export const saveCookieStatus = (
    mandant: Partial<IMandant>,
    acceptedByScrolling?: any
) => {
    if (acceptedByScrolling) {
        localStorage.setItem("cookieConsent-" + mandant.key, "all");
    } else {
        localStorage.setItem("cookieConsent-" + mandant.key, "required");
    }
};
