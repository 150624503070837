import { Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

import HtmlContent from "../../../../components/cms/HtmlContent";
import PersonTile from "../../../../components/personTile/PersonTile";
import IProjectFull from "../../../../types/IProjectFull";
import ITeam from "../../../../types/ITeam";

interface IProjectTeamTabProps {
    project: IProjectFull;
}

function ProjectTeamTab(props: IProjectTeamTabProps) {
    const { project } = props;

    const hasAvatar: boolean =
        project.team.filter((team: ITeam) => {
            // eslint-disable-next-line eqeqeq
            return team.image?.url != null;
        }).length > 0;

    return (
        <>
            {!!project.teamText && (
                <>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <Typography
                                gutterBottom
                                variant="h3"
                                className="gap-top"
                            >
                                Über das Team
                            </Typography>
                        </Grid>

                        {!!project.teamText && !project.teamText2 && (
                            <Grid item xs={12} md={12} lg={12}>
                                <HtmlContent
                                    className="content-block"
                                    content={project.teamText}
                                />
                            </Grid>
                        )}

                        {!!project.teamText && !!project.teamText2 && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <HtmlContent
                                        className="content-block"
                                        content={project.teamText}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <HtmlContent
                                        className="content-block"
                                        content={project.teamText2}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </>
            )}

            {!!project.team.length && (
                <>
                    <Grid container spacing={4} className="team-container">
                        <Grid item xs={12} md={12}>
                            <Typography
                                gutterBottom
                                variant="h3"
                                className="team gap-top"
                            >
                                Teammitglieder
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        className="team-member-tile-wrapper"
                    >
                        {project.team.map((member: ITeam, index: number) => {
                            return (
                                <Grid
                                    key={member.displayTitle + index}
                                    item
                                    xs={12}
                                    md={6}
                                    lg={6}
                                >
                                    <div className="project-team-tab__team-member-tile">
                                        <PersonTile
                                            hasAvatar={hasAvatar}
                                            key={index}
                                            {...member}
                                            minData={false}
                                        />
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            )}

            {!project.team.length &&
                !project.teamText &&
                !project.teamText2 && (
                    <Alert color="info">
                        In Kürze finden Sie hier Informationen zu unserem Team.
                    </Alert>
                )}
        </>
    );
}

export default ProjectTeamTab;
