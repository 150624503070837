import ICampaignAppointmentList from "../appointment/ICampaignAppointmentList";

const InitialCampaignAppointmentsData: ICampaignAppointmentList = {
    id: "",
    startDate: "",
    bookingTime: "",
    duration: 60,
    attorneyName: "",
    hasAttorney: true,
    additionalPerson: "",
    userComment: "",
    isGroup: false,
};

export default InitialCampaignAppointmentsData;
