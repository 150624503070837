import "date-fns";

import { Button, Checkbox, Grid, LinearProgress } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useState } from "react";

import DeleteDatasetDialog from "../../../../components/deleteDialog/DeleteDatasetDialog";
import Notify from "../../../../components/notify/Notify";
import { Format, dateStateFormat } from "../../../../config/config";
import ICampaign from "../../../../types/appointment/ICampaign";
import ICampaignAppointmentList from "../../../../types/appointment/ICampaignAppointmentList";
import ICampaignRoom from "../../../../types/appointment/ICampaignRoom";
import IMandant from "../../../../types/IMandant";
import InitialCampaignAppointmentsData from "../../../../types/initialData/InitialCampaignAppointmentsData";
import InitialSnackbarState from "../../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../../types/ISnackbarState";
import {
    deleteAppointmentRequest,
    updateAppointmentRequest,
} from "../../../../xhr/AppointmentRequests";
import exportTableToExcel from "../exports/exportAppointments";

interface IAppointmentListingProps {
    campaignData: ICampaign;
    campaignId: string | number;
    appointments: ICampaignAppointmentList[];
    campaignRooms: ICampaignRoom[];
    mandant: IMandant;
    onUpdate: () => void;
}

const AppointmentListingTab: React.FC<IAppointmentListingProps> = (
    props: IAppointmentListingProps
) => {
    const { appointments, campaignRooms, onUpdate, campaignData } = props;

    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const [IsLoading, setIsLoading] = React.useState<boolean>(false);

    const [appointmentState, setAppointmentState] =
        useState<ICampaignAppointmentList[]>(appointments);

    React.useEffect(() => {
        setAppointmentState(appointments);
    }, []);

    const [editAppointment, setEditAppointment] =
        useState<ICampaignAppointmentList>({
            ...InitialCampaignAppointmentsData,
        });

    const handleDeleteAppointment = (id: string) => {
        setIsLoading(true);
        deleteAppointmentRequest(props.mandant.key, id)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Das Ereignis wurde gelöscht",
                    type: "success",
                });
                setEditAppointment({ ...InitialCampaignAppointmentsData });
                onUpdate();
                setIsLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Es ist ein Fehler beim Löschen des Termins aufgetreten",
                    type: "error",
                });
                setIsLoading(false);
            });
        const newAppointmentList = appointmentState.filter(
            (elem: any) => elem.id !== id
        );

        setAppointmentState(newAppointmentList);
    };

    //handle update appointment
    const handleUpdateAppointment = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        updateAppointmentRequest(
            {
                ...editAppointment,
            },
            props.mandant.key
        )
            .then(() => {
                setEditAppointment({ ...InitialCampaignAppointmentsData });
                onUpdate();
                setSnackBarState({
                    isOpen: true,
                    message: "Der Termin wurde erfolgreich aktualisiert",
                    type: "success",
                });
                setIsLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Es ist ein Fehler beim Speichern des Ereignis aufgetreten",
                    type: "error",
                });
                setIsLoading(false);
            });
    };

    const handleResetAppointment = () => {
        setEditAppointment({ ...InitialCampaignAppointmentsData });
    };

    /*
    Handle appointment date change
    */
    const handleAppointmentDateChange = (date: Date | null, type: string) => {
        setEditAppointment({
            ...editAppointment,
            [type]: dateStateFormat(date),
        });
    };

    /*
    Handle appointment time change
    */
    const handleAppointmentTimeChange = (time: string | null, type: string) => {
        setEditAppointment({
            ...editAppointment,
            [type]: time,
        });
    };

    /*
    Handle appointment is group flag
    */
    const handleCheckboxChange = (event: any) => {
        const newData = {
            ...editAppointment,
            [event.target.name]: event.target.checked,
        };
        setEditAppointment(newData);
    };

    /*
    Handle appointment duration
    */
    const handleChangeDuration = (e: any) => {
        setEditAppointment({
            ...editAppointment,
            duration: e.target.value,
        });
    };

    const changeCampaignRoom = (event: any) => {
        setEditAppointment({
            ...editAppointment,
            campaignRoom: campaignRooms.filter(
                (room: ICampaignRoom) => room.id === event.target.value
            )[0],
        });
    };

    return (
        <>
            {IsLoading && (
                <LinearProgress
                    color={"primary"}
                    style={{ marginBottom: "16px" }}
                />
            )}
            <Grid container className="gap-bottom">
                <Grid item xs={12}>
                    <Button
                        color={"primary"}
                        onClick={() => exportTableToExcel(appointmentState)}
                    >
                        Termine exportieren
                    </Button>
                </Grid>
            </Grid>
            <Typography variant="h2" className="main-header gap-bottom">
                Liste der Termine
            </Typography>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Start</TableCell>
                            <TableCell>Dauer</TableCell>
                            <TableCell>Raum</TableCell>
                            <TableCell>Buchungsdatum</TableCell>
                            {!campaignData.isPublicAvailable && (
                                <TableCell>Ordnungsnummer</TableCell>
                            )}
                            <TableCell>Buchender</TableCell>
                            {!campaignData.isPublicAvailable && (
                                <TableCell>Gemeinschaft</TableCell>
                            )}
                            <TableCell>Menu</TableCell>
                        </TableRow>
                    </TableHead>
                    {appointmentState.length > 0 ? (
                        <TableBody>
                            {appointmentState.map(
                                (row: ICampaignAppointmentList) => (
                                    <TableRow key={row.id}>
                                        <TableCell scope="row">
                                            {moment(row.startDate).format(
                                                Format.dateTime
                                            )}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.duration}
                                        </TableCell>
                                        <TableCell>
                                            {row.campaignRoom?.name}
                                        </TableCell>
                                        <TableCell>
                                            {row.bookingTime
                                                ? moment(
                                                      row.bookingTime
                                                  ).format(Format.dateTime)
                                                : ""}
                                        </TableCell>
                                        {!campaignData.isPublicAvailable && (
                                            <TableCell>
                                                {
                                                    row?.bookingParticipant
                                                        ?.orderNumber
                                                }
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            {
                                                row?.bookingParticipant?.user
                                                    ?.firstname
                                            }{" "}
                                            {
                                                row?.bookingParticipant?.user
                                                    ?.lastname
                                            }
                                        </TableCell>
                                        {!campaignData.isPublicAvailable && (
                                            <TableCell>
                                                <Checkbox
                                                    checked={row.isGroup}
                                                />
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            <DeleteDatasetDialog
                                                deleteHandler={
                                                    handleDeleteAppointment
                                                }
                                                id={row.id}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    ) : (
                        <></>
                    )}
                </Table>
            </TableContainer>

            {/*
                    <Grid item xs={12} sm={4} xl={3}>
                        <Paper className={"main-paper"}>
                            <Typography variant={"h3"} className="gap-bottom">
                                {editAppointment.id ? (
                                    <>Termin bearbeiten</>
                                ) : (
                                    <>Termin erstellen</>
                                )}
                            </Typography>
                            <MuiPickersUtilsProvider
                                locale={deLocal}
                                utils={DateFnsUtils}
                            >
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    name="appointment-date"
                                    margin="normal"
                                    id="appointment-date"
                                    variant="inline"
                                    label="Date"
                                    required={true}
                                    fullWidth
                                    format={Format.dateInp}
                                    value={
                                        editAppointment.startDate
                                            ? new Date(
                                                editAppointment.startDate
                                            )
                                            : null
                                    }
                                    onChange={(date: any) =>
                                        handleAppointmentDateChange(
                                            date,
                                            "startDate"
                                        )
                                    }
                                    KeyboardButtonProps={{
                                        "aria-label": "Startdatum anpassen",
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider
                                locale={deLocal}
                                utils={DateFnsUtils}
                            >
                                <KeyboardTimePicker
                                    inputVariant="outlined"
                                    name="appointment-booking-time"
                                    margin="normal"
                                    id="appointment-booking-time"
                                    variant="inline"
                                    label="Booking Time"
                                    required={true}
                                    fullWidth
                                    format={Format.time}
                                    value={
                                        editAppointment.bookingTime
                                            ? new Date(
                                                editAppointment.bookingTime
                                            )
                                            : null
                                    }
                                    onChange={(time: any) =>
                                        handleAppointmentTimeChange(
                                            time,
                                            "bookingTime"
                                        )
                                    }
                                />
                            </MuiPickersUtilsProvider>
                            <Select
                                id="input-projekt"
                                value={editAppointment.campaignRoom.id}
                                fullWidth={true}
                                onChange={changeCampaignRoom}
                                variant="outlined"
                            >
                                <MenuItem key="empty" value="">
                                    <em>Bitte Roum wählen</em>
                                </MenuItem>
                                {campaignRooms.map(
                                    (campaignRoom: ICampaignRoom) => {
                                        return (
                                            <MenuItem
                                                key={campaignRoom.id}
                                                value={campaignRoom.id}
                                            >
                                                {campaignRoom.name}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                            <TextField
                                label="Duration *"
                                fullWidth
                                type="number"
                                value={
                                    editAppointment.duration
                                        ? editAppointment.duration
                                        : ""
                                }
                                onChange={handleChangeDuration}
                                variant="outlined"
                            />
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={editAppointment.isGroup}
                                            onChange={handleCheckboxChange}
                                            name="isGroup"
                                        />
                                    }
                                    label="Ist ein gruppen termin"
                                />
                            </Grid>
                            <Button
                                onClick={handleUpdateAppointment}
                                color="primary"
                                startIcon={
                                    editAppointment.id ? (
                                        <SaveIcon />
                                    ) : (
                                        <AddIcon />
                                    )
                                }
                            >
                                {editAppointment.id ? (
                                    <>Speichern</>
                                ) : (
                                    <>Ereignis Erstellen</>
                                )}
                            </Button>
                            <Button
                                onClick={handleResetAppointment}
                                color="secondary"
                                startIcon={<CancelIcon />}
                            >
                                Abbrechen
                            </Button>
                        </Paper>
                    </Grid>*/}
            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

export default AppointmentListingTab;
