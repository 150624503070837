import "./DownloadCard.css";

import {
    Box,
    Card,
    CardActions,
    CardContent,
    Tooltip,
    Typography,
} from "@material-ui/core";
import * as React from "react";

import ButtonAsLink from "../buttonAsLink/ButtonAsLink";

interface IDownloadCardProps {
    tooltip?: string;
    title: string;
    description: string;
    actionButtonText?: string;
    actionButtonClickHandler?: () => {};
    actionButtonClassName?: string;
    downloadUrl: string;
}

function DownloadCard(props: IDownloadCardProps) {
    const renderEntry = () => {
        return (
            <Box sx={{ minWidth: 275 }}>
                <Card variant="outlined" className="download-card__card">
                    <CardContent>
                        <Typography variant="h3" className="text-color">
                            {props.title}
                        </Typography>
                        {props.description && (
                            <Typography variant="subtitle1">
                                {props.description}
                            </Typography>
                        )}
                    </CardContent>
                    <CardActions>
                        <div className="outlined-card__card-actions-container">
                            <ButtonAsLink
                                actionButtonText="Herunterladen"
                                linkUrl={props.downloadUrl}
                            />
                        </div>
                    </CardActions>
                </Card>
            </Box>
        );
    };

    if (props.tooltip) {
        return <Tooltip title={props.tooltip}>{renderEntry()}</Tooltip>;
    }

    return renderEntry();
}

export default DownloadCard;
