import IEventFull from "../IEventFull";
import InitialDocumentData from "./InitialDocumentData";

const InitialEventFullData: IEventFull = {
    id: 0,
    title: "",
    urlKey: "",
    imageMenu: { ...InitialDocumentData },
    imageHeader: { ...InitialDocumentData },
    theStart: "",
    theEnd: "",
    locationName: "",
    locationUrl: "",
    description: "",
    descriptionAfter: "",
    shortDesc: "",
    images: [],
    imagesAfter: [],
    participants: [],
    attachments: [],
    longitude: 0,
    latitude: 0,
    zoom: 2,
    roles: [],
};
export default InitialEventFullData;
