import { action } from "typesafe-actions";

import { IProjectMenu } from "../../masks/admin/projectForm/tabs/ProjectMenuTab";
import ISyncProjectFull from "../../xhr/interface/ISyncProjectFull";
import ISyncProjectList from "../../xhr/interface/ISyncProjectList";
import ActionTypes from "./ProjectActionTypesEnum";

export const setProjectDataToStore = (projectData: ISyncProjectFull) =>
    action(ActionTypes.SET_PROJECT, projectData);

export const setProjectMenuToStore = (projectData: IProjectMenu | null) =>
    action(ActionTypes.SET_PROJECT_MENU, projectData);

export const setProjectListToStore = (projectList: ISyncProjectList[] | null) =>
    action(ActionTypes.SET_PROJECT_LIST, projectList);

export const unsetProjectDataInStore = () => action(ActionTypes.EMPTY_PROJECT);
