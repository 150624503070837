import "./EventList.css";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ical from "ical-generator";
import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import EventTile from "../../../components/project/eventTile/EventTile";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import PortalHeader from "../../../layout/portalHeader/PortalHeader";
import RoutesEnum from "../../../routes/RoutesEnum";
import IEventList from "../../../types/IEventList";
import IMandant from "../../../types/IMandant";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import { loadPublicEventList } from "../../../xhr/EventRequests";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const fileDownload = require("js-file-download");
interface IMapStateProps {}

interface IMapDispatchProps {}

interface IOwnProps {
    mandant: IMandant;
}

type IEventListParams = IMapStateProps & IMapDispatchProps & IOwnProps;

const EventList = (props: IEventListParams) => {
    const { mandant } = props;
    const history = useHistory();
    const [eventList, setEventList] = React.useState<IEventList[]>([]);

    React.useEffect(() => {
        const loadEvent = () => {
            loadPublicEventList(mandant.key).then((result: any) => {
                setEventList(result.data);
                return Promise.resolve();
            });
        };
        loadEvent();
    }, [mandant.key]);

    const linkToEvent = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        history.push(
            toPortalUrl(
                mandant,
                RoutesEnum.PORTAL_EVENT + "/" + event.currentTarget.dataset.id
            )
        );
    };

    // if we have only one project we direct jump into the project
    if (eventList.length === 1) {
        history.push(
            toPortalUrl(
                mandant,
                RoutesEnum.PORTAL_EVENT + "/" + eventList[0].id
            )
        );
    }

    const handleDownloadClick = (e: any, event: any) => {
        const cal = ical({ name: "my first iCal" });
        cal.createEvent({
            start: new Date(event.theStart),
            end: new Date(event.theEnd),
            summary: event.shortDesc,
            description: event.description,
            location: event.location,
        });

        const dataTodownload = cal.toString();
        fileDownload(dataTodownload, `${event.title}.ics`);
        // download(dataTodownload, "Event Calendar.ics");
    };
    return (
        <div className="portal-section">
            <Helmet>
                <title>Die Liste der aktuellen Veranstaltungen</title>
            </Helmet>

            <PortalHeader mandant={mandant} noProject={true} />

            <div className="portal-section-body-position event-list-body-flex">
                <div className="App-main-list">
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Paper
                            className="App-list-container-body"
                            square
                            elevation={0}
                        >
                            <h2 className="App-list-container-header">
                                Die Liste der aktuellen Veranstaltungen
                            </h2>

                            {eventList.length === 0 && (
                                <>
                                    <p>
                                        Im Moment gibt es keine anstehenden
                                        Veranstaltungen.
                                    </p>
                                </>
                            )}
                            <Grid container spacing={2}>
                                {eventList.length > 0 &&
                                    eventList.map(
                                        (event: IEventList, index: number) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={3}
                                                    lg={4}
                                                >
                                                    <EventTile
                                                        key={index}
                                                        onLinkClick={
                                                            linkToEvent
                                                        }
                                                        {...event}
                                                        onDownloadClick={(e) =>
                                                            handleDownloadClick(
                                                                e,
                                                                event
                                                            )
                                                        }
                                                        fromPublicMask
                                                    />
                                                </Grid>
                                            );
                                        }
                                    )}
                            </Grid>
                        </Paper>
                    </Grid>
                </div>
            </div>
            <PortalFooter mandant={mandant} />
        </div>
    );
};

export default EventList;
