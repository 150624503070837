export default function stringIsEmpty(
    value: string | null | undefined
): boolean {
    // eslint-disable-next-line eqeqeq
    if (value == null) {
        return true;
    }

    return !value.length;
}
