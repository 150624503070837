import { Divider } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import {
    MapDispatchToPropsParam,
    MapStateToPropsParam,
    connect,
} from "react-redux";
import * as router from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import RoleEnum from "../../enums/RoleEnum";
import IApplicationRootState from "../../store/StoreTypes";
import LogoutAction from "../../store/userStore/asyncActions/LogoutAction";
import IMandant from "../../types/IMandant";
import userHasRoles from "../../utils/userHasRole";

interface IListMenuItems {
    menuItem: string;
    route: string;
    roles?: RoleEnum[];
    onlySteg?: boolean | null;
}

interface IAdminSideMenuMapStateProps {
    activeUser: any;
}

interface IAdminSideMenuMapDispatchProps {
    handleLogout: (mandantKey: string) => void;
}

interface IOwnProps {
    mandant: IMandant;
    selectedIndex: number;
    setSelectedIndex: (index: number) => void;
    url: string;
}
interface IAdminSideMenuParams
    extends IAdminSideMenuMapStateProps,
        IAdminSideMenuMapDispatchProps,
        IOwnProps {}

const AdminSideMenu = (props: IAdminSideMenuParams) => {
    const {
        activeUser,
        selectedIndex,
        setSelectedIndex,
        handleLogout,
        url,
        mandant,
    } = props;
    const [shouldDownloadPdf, setShouldDownloadPdf] = React.useState(false);

    // Listen to the download action
    React.useEffect(() => {
        if (shouldDownloadPdf) {
            window.open(
                `${process.env.PUBLIC_URL}/docu/AdminDoku.pdf`,
                "_blank"
            );

            // Dynamically create an link element
            const link = document.createElement("a");
            link.href = `${process.env.PUBLIC_URL}/docu/AdminDoku.pdf`;
            link.download = "AdminDoku.pdf";
            link.style.display = "none"; // Hide the link element

            // Append the link to the body and click it to initiate download
            document.body.appendChild(link);
            link.click();

            // Clean up by removing the link from the body
            document.body.removeChild(link);
        }
    }, [shouldDownloadPdf]);

    const listMenuItems: IListMenuItems[] = [
        { menuItem: "Kunden Verwaltung", route: "portale", onlySteg: true },
        { menuItem: "Projekt Verwaltung", route: "projekte" },
        { menuItem: "Termin Kampagnen", route: "kampagne" },
        { menuItem: "Kommentare", route: "kommentare" },
        { menuItem: "Kommentare Karten", route: "geofeedback" },
        { menuItem: "Umfragen", route: "umfragen" },
        { menuItem: "Kontakt Anfragen", route: "feedback/" },
        {
            menuItem: "CMS Seiten Verwaltung",
            route: "cms",
            roles: [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_CONTENT_MANAGER],
        },
        {
            menuItem: "Portal Einstellungen",
            route: "portal",
            roles: [RoleEnum.ROLE_ADMIN],
        },
        {
            menuItem: "Startseite Verwaltung",
            route: "projektliste",
            roles: [RoleEnum.ROLE_ADMIN],
            onlySteg: true,
        },
        {
            menuItem: "Benutzer Verwaltung",
            route: "anwender",
            roles: [RoleEnum.ROLE_ADMIN],
            onlySteg: null,
        },
        {
            menuItem: "Passwort ändern",
            route: "passwortAendern",
            onlySteg: null,
        },
        {
            menuItem: "Admindoku",
            route: "adminDoku/pdf",
            roles: [RoleEnum.ROLE_ADMIN],
            onlySteg: null,
        },
        { menuItem: "Abmelden", route: "login", onlySteg: null },
    ];

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number
    ) => {
        setSelectedIndex(index);
    };
    // Check is route for adminDoku pdf download
    const isDownloadRoute = (route: string) => route === "adminDoku/pdf";

    // Handle click on meny items
    const handleMenuItemsRoutesClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
        elem: IListMenuItems
    ) => {
        if (elem.route === "adminDoku/pdf") {
            setShouldDownloadPdf(true);
        } else if (elem.route === "login") {
            handleLogout(mandant.key);
        } else {
            handleListItemClick(event, index);
        }
    };
    return (
        <Drawer className="drawer" variant="permanent">
            <Toolbar />
            <div className="drawerContainer">
                <List>
                    <Divider />
                    {listMenuItems.map((elem, index) => {
                        if (elem.roles) {
                            if (!userHasRoles(activeUser, elem.roles)) {
                                return null;
                            }
                        }

                        // the steg admin page
                        if (mandant.key === "stegadmin") {
                            if (elem.onlySteg === undefined) {
                                return null;
                            }
                        } else {
                            if (elem.onlySteg === true) {
                                return null;
                            }
                        }
                        return (
                            <div key={index}>
                                <router.Link
                                    key={`${elem.route}${index}`}
                                    style={{
                                        textDecoration: "none",
                                    }}
                                    to={
                                        !elem.route
                                            ? "/login"
                                            : `${url}/${elem.route}`
                                    }
                                    className={`${
                                        isDownloadRoute(elem.route)
                                            ? "download-pdf"
                                            : ""
                                    }`}
                                >
                                    <ListItem
                                        button
                                        selected={selectedIndex === index}
                                        onClick={(event) => {
                                            handleMenuItemsRoutesClick(
                                                event,
                                                index,
                                                elem
                                            );
                                        }}
                                    >
                                        <ListItemText primary={elem.menuItem} />
                                    </ListItem>
                                </router.Link>
                                <Divider />
                            </div>
                        );
                    })}
                </List>
            </div>
        </Drawer>
    );
};
const mapStateToProps: MapStateToPropsParam<
    IAdminSideMenuMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        activeUser: state.user.userData,
    };
};

/**
 * for async func we only set handleOnLoginEvent: (params:any) => LoginAction(params)
 * for non async we use  handleOnLoginEvent: () => {dispatch(SomeActions())
 */
const mapDispatchToProps: MapDispatchToPropsParam<
    IAdminSideMenuMapDispatchProps,
    IOwnProps
> = (dispatch: ThunkDispatch<{}, {}, Action>) => {
    return {
        handleLogout: (mandantKey: string) =>
            dispatch(LogoutAction(mandantKey)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSideMenu);
