import { Grid, Typography } from "@material-ui/core";
import React from "react";

import HtmlContent from "../../../../components/cms/HtmlContent";
import FolderList from "../../../../components/folderList/FolderList";
import ImmageGallery from "../../../../components/imageGallery/ImageGallery";

interface IAboutProps {
    event: any;
}

function ReportTab(props: IAboutProps) {
    const { event } = props;

    return (
        <>
            {!!event.descriptionAfter && !event.descriptionAfter2 && (
                <HtmlContent
                    className="content-block"
                    content={event.description}
                />
            )}
            {!!event.descriptionAfter && event.descriptionAfter2 && (
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <HtmlContent
                            className="content-block"
                            content={event.descriptionAfter}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <HtmlContent
                            className="content-block"
                            content={String(event.descriptionAfter2)}
                        />
                    </Grid>
                </Grid>
            )}

            <FolderList files={event.attachments} fromEventTab={true} />

            {!!event.imagesAfter.length && (
                <>
                    <Typography gutterBottom variant="h2" className="gap-top">
                        Bildergalerie
                    </Typography>
                    <Typography variant="body1">
                        Hier sehen Sie eine Auswahl von Bildern der
                        Veranstaltung.
                    </Typography>
                    <ImmageGallery images={event.imagesAfter} />
                </>
            )}
        </>
    );
}

export default ReportTab;
