import { Grid, Typography } from "@material-ui/core";
import React from "react";

import ResultTile from "../../../../components/project/resultTile/ResultTile";
import IProjectFull from "../../../../types/IProjectFull";
import IProjectResult from "../../../../types/IProjectResult";

interface IProjectResultsTabProps {
    project: IProjectFull;
}

function ProjectResultsTab(props: IProjectResultsTabProps) {
    const { project } = props;

    return (
        <>
            <Typography variant="h3">Bisherige Ergebnisse</Typography>
            <Grid
                container
                spacing={2}
                className="gap-top result-tile-container"
            >
                {project.projectResults
                    .sort((a, b) => {
                        return a.priority - b.priority;
                    })
                    .map((result: IProjectResult, index: number) => (
                        <ResultTile key={`result-${index}`} {...result} />
                    ))}
            </Grid>
        </>
    );
}

export default ProjectResultsTab;
