import IFeedback from "../IFeedback";

const InitialFeedbackData: IFeedback = {
    id: 0,
    title: "",
    description: "",
    emailOfPerson: "",
    nameOfPerson: "",
    project: { id: 0 },
};
export default InitialFeedbackData;
