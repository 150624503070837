import "./CampaignForm.css";

import {
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link,
    Typography,
} from "@material-ui/core";
import draft, { EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import TabContainer from "../../../components/tabContainer/TabContainer";
import { dateStateFormat } from "../../../config/config";
import RoutesEnum from "../../../routes/RoutesEnum";
import IAppointmentBulk from "../../../types/appointment/IAppointmentBulk";
import ICampaign from "../../../types/appointment/ICampaign";
import ICampaignAppointmentList from "../../../types/appointment/ICampaignAppointmentList";
import ICampaignParticipantList from "../../../types/appointment/ICampaignParticipantList";
import ICampaignRoom from "../../../types/appointment/ICampaignRoom";
import IDocument from "../../../types/IDocument";
import IMandant from "../../../types/IMandant";
import InitialAppointmentBulkData from "../../../types/initialData/InitialAppointmentBulkData";
import InitialCampaignData from "../../../types/initialData/InitialCampaignData";
import InitialCampaignRoom from "../../../types/initialData/InitialCampaignRoom";
import InitialDocumentData from "../../../types/initialData/InitialDocumentData";
import InitialEmail from "../../../types/initialData/InitialEmail";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import IParticipantImport from "../../../types/IParticipantImport";
import ISnackbarState from "../../../types/ISnackbarState";
import deepCopy from "../../../utils/deepCopy";
import { getEditorStateContent } from "../../../utils/editorContentHelper/editorContentHelper";
import capitalize from "../../../utils/form/capitalize";
import dateToTime from "../../../utils/form/dateToTime";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import {
    createParticipantListRequest,
    loadAppointmentListRequest,
    loadParticipantListRequest,
} from "../../../xhr/AppointmentRequests";
import {
    createAppointmentBulkRequest,
    createCampaignRequest,
    loadAdminSingleCampaignRequest,
    updateCampaignRequest,
} from "../../../xhr/CampaignRequests";
import ISelectOption from "../../../xhr/interface/ISelectOption";
import { loadAdminProjectSelectboxRequest } from "../../../xhr/ProjectRequests";
import AppointmentBulkDataTab from "./tabs/AppointmentBulkDataTab";
import AppointmentListingTab from "./tabs/AppointmentListingTab";
import CampaignDataTab from "./tabs/CampaignDataTab";
import ParticipantImportDataTab from "./tabs/ParticipantImportDataTab";
import ParticipantListingTab from "./tabs/ParticipantListingTab";
import {
    IAppointmentValidationState,
    validationAppointmentDataField,
} from "./validations/appointmentDataValidation";
import {
    ICampaignValidationState,
    requiredCampaignDataFilled,
} from "./validations/campaignDataValidation";
import {
    IRoomValidationState,
    requiredRoomDataFilled,
} from "./validations/roomDataValidation";

interface IOwnProps {
    mandant: IMandant;
}

const CampaignForm = (props: IOwnProps) => {
    const history = useHistory();

    const { id }: { id: any } = useParams<{
        id: any;
    }>();
    const { mandant } = props;
    const [isEditForm, setIsEditForm] = React.useState<boolean>(!isNaN(id));
    const [nextRoom, setNextRoom] = React.useState<ICampaignRoom>({
        ...InitialCampaignRoom,
    });

    const [editIndex, setEditIndex] = React.useState<number | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    const [errorState, setErrorState] =
        React.useState<ICampaignValidationState>({ hasError: false });
    const [errorRoomState, setErrorRoomState] =
        React.useState<IRoomValidationState>({ hasError: false });
    const [errorAppointmentState, setErrorAppointmentState] =
        React.useState<IAppointmentValidationState>({ hasError: false });
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [activeTab, setActiveTab] = React.useState<number>(1);

    /**
     * For the Project List
     */
    const [projectList, setProjectList] = React.useState<ISelectOption[]>([]);

    /**
     * List of imported participants
     */
    const [participantImportData, setParticipantImportData] =
        React.useState<IDocument>({ ...InitialDocumentData });

    /**
     * The form data
     */
    const [campaignData, setCampaignData] = React.useState<ICampaign>({
        ...InitialCampaignData,
    });

    /**
     * Campaign appointments data
     */
    const [appointmentsData, setAppointmentsData] = React.useState<
        ICampaignAppointmentList[]
    >([]);

    /**
     * Campaign participants data
     */
    const [participantsData, setParticipantsData] = React.useState<
        ICampaignParticipantList[]
    >([]);

    /**
     * The form data
     */
    const [appointmentData, setAppointmentData] =
        React.useState<IAppointmentBulk>({
            ...InitialAppointmentBulkData,
        });

    /**
     * Snackkbar related states
     */
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    /**
     *
     */
    React.useEffect(() => {
        if (isEditForm) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            loadDataFromServer(id);
        } else if (id === "create") {
            // if we create an event we need at least the project
            setCampaignData({
                ...InitialCampaignData,
                campaignRooms: [],
                id: "create",
            });
        }
    }, [id]);

    /**
     *
     */
    React.useEffect(() => {
        loadAdminProjectSelectboxRequest(mandant.key).then((response) => {
            setProjectList(response.data);
        });
    }, [mandant.key]);

    /* load appointment data to state */
    const loadAppointmentsToState = (campaignId: string | number) => {
        setLoading(true);
        loadAppointmentListRequest(mandant.key, campaignId)
            .then((response) => {
                setIsEditForm(true);
                setAppointmentsData(
                    response.data.sort((a, b) => {
                        const firstdate = new Date(a.startDate);
                        const seconddate = new Date(b.startDate);
                        return firstdate.getTime() - seconddate.getTime();
                    })
                );
                setLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Daten ist ein Fehler aufgetreten.",
                    type: "error",
                });
            });
        setLoading(false);
    };

    /* load participants data to state */
    const loadParticipantsToState = (campaignId: string | number) => {
        setLoading(true);
        loadParticipantListRequest(mandant.key, campaignId)
            .then((response) => {
                setIsEditForm(true);
                setParticipantsData(response.data);
                setLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Daten ist ein Fehler aufgetreten.",
                    type: "error",
                });
            });
        setLoading(false);
    };

    /* load appointment data to state */
    const handleParticipantListImport = () => {
        setLoading(true);
        const requestBody: IParticipantImport = {
            campaign: {
                id: campaignData.id,
            },
            defaultPassword: "HansWurst",
            data: participantImportData.data,
        };
        createParticipantListRequest(mandant.key, requestBody)
            .then((response) => {
                setIsEditForm(true);
                setParticipantsData(response.data);
                setLoading(false);

                loadParticipantsToState(campaignData.id);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Importieren der Teilnehmer ist ein Fehler aufgetreten.",
                    type: "error",
                });
            });
        setLoading(false);
    };

    /**
     * load from the server
     * @param campaignId
     */
    const loadDataFromServer = (campaignId: number) => {
        setIsLoading(true);
        loadAdminSingleCampaignRequest(mandant.key, campaignId)
            .then((response) => {
                setCampaignData(response.data);
                setIsLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Seite ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setIsLoading(false);
            });
        loadAppointmentsToState(campaignId);
        loadParticipantsToState(campaignId);
    };

    /**
     * Save the campaign
     */
    const handleSave = () => {
        const theValidation = requiredCampaignDataFilled(
            campaignData,
            nextRoom
        );

        setErrorState(theValidation);

        if (theValidation.hasError) {
            setSnackBarState({
                isOpen: true,
                message: "Fehlende Pflichtfelder.",
                type: "error",
            });
            return;
        }

        setIsLoading(true);
        if (isEditForm) {
            updateCampaignRequest(mandant.key, campaignData)
                .then((response: any) => {
                    setCampaignData(response.data);
                    setSnackBarState({
                        isOpen: true,
                        message: "Die Kampagne wurde erfolgreich gespeichert",
                        type: "success",
                    });
                    setIsLoading(false);
                    history.push(
                        toPortalUrl(mandant, RoutesEnum.ADMIN_APPOINTMENT)
                    );
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Beim Speichern der Kampagne ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        } else {
            if (campaignData?.id) {
                createCampaignRequest(mandant.key, campaignData)
                    .then((response: any) => {
                        setCampaignData(response.data);
                        setSnackBarState({
                            isOpen: true,
                            message: "Die Kampagne wurde erfolgreich erstellt.",
                            type: "success",
                        });
                        setIsLoading(false);
                        history.push(
                            toPortalUrl(
                                mandant,
                                RoutesEnum.ADMIN_APPOINTMENT +
                                    response?.data?.id
                            )
                        );
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message:
                                "Beim Erstellen der Kampagne ist ein Fehler aufgetreten",
                            type: "error",
                        });
                        setIsLoading(false);
                    });
            }
        }
    };

    const handleAppointmentBulkSave = () => {
        setIsLoading(true);
        const newErrorAppointmentState: IAppointmentValidationState =
            validationAppointmentDataField(appointmentData, campaignData);
        setErrorAppointmentState(newErrorAppointmentState);
        if (newErrorAppointmentState.hasError) {
            setIsLoading(false);
            if (
                newErrorAppointmentState.startTime ||
                newErrorAppointmentState.endTime ||
                newErrorAppointmentState.period ||
                newErrorAppointmentState.room ||
                newErrorAppointmentState.startDate?.empty ||
                newErrorAppointmentState.endDate?.empty
            ) {
                setSnackBarState({
                    isOpen: true,
                    type: "error",
                    message: "Pflichtfelder sind leer.",
                });
            } else if (newErrorAppointmentState.startDate?.exceedCampaign) {
                setSnackBarState({
                    isOpen: true,
                    type: "error",
                    message:
                        "Das Startdatum des Termins liegt nach dem Startdatum der Kampagne.",
                });
            } else if (newErrorAppointmentState.endDate?.exceedCampaign) {
                setSnackBarState({
                    isOpen: true,
                    type: "error",
                    message:
                        "Das Enddatum des Termins überschreitet das Enddatum der Kampagne.",
                });
            } else if (newErrorAppointmentState.endDate?.invalid) {
                setSnackBarState({
                    isOpen: true,
                    type: "error",
                    message: "Startdatum überschreitet Enddatum.",
                });
            }
            return;
        }

        const appointmentBulkData = {
            ...appointmentData,
            campaign: { id: campaignData?.id },
            startTime: { date: dateToTime(appointmentData.startTime.date) },
            endTime: { date: dateToTime(appointmentData.endTime.date) },
        };

        createAppointmentBulkRequest(mandant.key, appointmentBulkData)
            .then((response) => {
                setAppointmentsData(
                    response.data.sort(
                        (
                            a: ICampaignAppointmentList,
                            b: ICampaignAppointmentList
                        ) => {
                            const firstdate = new Date(a.startDate);
                            const seconddate = new Date(b.startDate);
                            return firstdate.getTime() - seconddate.getTime();
                        }
                    )
                );
                setSnackBarState({
                    isOpen: true,
                    message: "Die Termine wurden erfolgreich angelegt.",
                    type: "success",
                });
                setIsLoading(false);

                loadAppointmentsToState(campaignData?.id);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Erstellen der Termine ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setIsLoading(false);
            });
    };

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    /**
     * save the rooms
     */
    const handleSaveRoom = () => {
        const newErrorRoomState = requiredRoomDataFilled(nextRoom);
        setErrorRoomState(newErrorRoomState);

        if (newErrorRoomState.hasError) {
            return;
        }

        if (editIndex !== null) {
            campaignData.campaignRooms[editIndex] = { ...nextRoom };
        } else {
            campaignData.campaignRooms.push({ ...nextRoom });
        }

        setCampaignData(deepCopy<ICampaign>(campaignData as ICampaign));
        setNextRoom({ ...InitialCampaignRoom });
        setEditIndex(null);
    };

    /**
     * @param event
     */
    const handleChangeNextRoom = (event: any) => {
        const newRoomData = {
            ...nextRoom,
            [event.target.name]: event.target.value,
        };
        setNextRoom(newRoomData);
    };

    /**
     * @param event
     */
    const deleteCampaignPoint = (event: any) => {
        const deletePos = event.currentTarget.dataset.pos;

        campaignData.campaignRooms.splice(deletePos, 1);
        setCampaignData(deepCopy<ICampaign>(campaignData as ICampaign));
    };

    /**
     * @param event
     */
    const setRoomToEdit = (event: any) => {
        const nextEditIndex = event.currentTarget.dataset.pos;
        setEditIndex(nextEditIndex);
        setNextRoom({ ...campaignData.campaignRooms[nextEditIndex] });
    };

    /*
    Handle generic change
    */
    const handleTextChange = (event: any) => {
        const newCampaignData = {
            ...campaignData,
            [event.target.name]: event.target.value,
        };
        setCampaignData(newCampaignData);
    };

    /**
     * Handle mail text change
     */
    const handleMailTextChange = (
        mailkey: string,
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        const newCampaignData = { ...campaignData };

        // @ts-ignore
        if (newCampaignData[mailkey] == null) {
            // @ts-ignore
            newCampaignData[mailkey] = { ...InitialEmail };
        }

        // @ts-ignore
        newCampaignData[mailkey][event.target.name] = event.target.value;
        setCampaignData(newCampaignData);
    };

    /**
     * Handle mail wysiwyg change
     */
    const handleMailWysiwygChange = (
        mailkey: string,
        field: string,
        editorState: EditorState
    ) => {
        const rawContentState = draft.convertToRaw(
            editorState.getCurrentContent()
        );
        const markup = draftToHtml(rawContentState);
        const newData = { ...campaignData };

        // @ts-ignore
        if (newData[mailkey] == null) {
            // @ts-ignore
            newCampaignData[mailkey] = { ...InitialEmail };
        }

        // @ts-ignore
        newData[mailkey][field] = getEditorStateContent(editorState, markup);
        setCampaignData(newData);
    };

    /*
    Handle generic change
    */
    const handleAppointmentBulkTextChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        const newCampaignData = {
            ...appointmentData,
            [event.target.name]: event.target.value,
        };
        setAppointmentData(newCampaignData);
    };

    /*
    Handle dates change
    */
    const handleCampaignDateChange = (date: Date | null, type: string) => {
        setCampaignData({
            ...campaignData,
            [type]: dateStateFormat(date),
            [`appointment${capitalize(type)}`]: dateStateFormat(date),
        });
    };

    /*
    Handle appointments date change
    */
    const handleAppointmentDateChange = (date: Date | null, type: string) => {
        setAppointmentData({
            ...appointmentData,
            [type]: {
                date: dateStateFormat(date),
            },
        });
    };

    /*
    Handle appointments time change
    */
    const handleAppointmentTimeChange = (time: any | null, type: string) => {
        setAppointmentData({
            ...appointmentData,
            [type]: {
                date: time,
            },
        });
    };

    /**
     * @param event
     */
    const handleFlagGroupCheckboxChange = (event: any) => {
        const newData = {
            ...appointmentData,
            [event.target.name]: event.target.checked,
        };
        setAppointmentData(newData);
    };

    const handleRoomCheckBoxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        room: number
    ) => {
        if (event.target.checked) {
            setAppointmentData({
                ...appointmentData,
                rooms: [...appointmentData.rooms, room],
            });
        } else {
            setAppointmentData({
                ...appointmentData,
                rooms: appointmentData.rooms.filter((r: number) => r !== room),
            });
        }
    };

    /**
     */
    const changeProject = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCampaignData({
            ...(campaignData as ICampaign),
            project: { id: event.target.value as number },
        });
    };

    const handleUploadClick = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            setCampaignData({
                ...campaignData,
                document: {
                    ...campaignData.document,
                    data: reader.result,
                    name: file.name,
                    size: file.size,
                    //type: file.type,
                },
            });
        };
    };

    const handleEmptyUploadClick = () => {
        setCampaignData({
            ...campaignData,
            document: deepCopy<IDocument>(InitialDocumentData),
        });
    };

    /**
     * @param event
     */
    function handleBread(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    /**
     * handle the image change
     */
    const handleImageChange = (image: IDocument) => {
        setCampaignData({
            ...campaignData,
            image: {
                ...campaignData.image,
                ...image,
            },
        });
    };

    const handleCheckboxChange = (event: any) => {
        const newData = {
            ...campaignData,
            [event.target.name]: event.target.checked,
        };

        setCampaignData(newData);
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    const handleFileImport = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setParticipantImportData({
                ...participantImportData,
                data: reader.result,
                name: file.name,
                size: file.size,
            });
        };
        reader.readAsText(file);
    };

    const handleImportReset = () => {
        setParticipantImportData(InitialDocumentData);
    };

    if (!campaignData.id) {
        return <></>;
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    onClick={handleBread}
                >
                    Start
                </Link>
                <Link
                    color="inherit"
                    href={toPortalUrl(mandant, RoutesEnum.ADMIN_APPOINTMENT)}
                    data-url={toPortalUrl(
                        mandant,
                        RoutesEnum.ADMIN_APPOINTMENT
                    )}
                    onClick={handleBread}
                >
                    Liste der Kampagne
                </Link>
                <Typography color="textPrimary">
                    {isEditForm && <>Kampagne: {campaignData.title}</>}
                    {!isEditForm && <>Eine neue Kampagne erstellen</>}
                </Typography>
            </Breadcrumbs>

            <Grid container>
                <Grid item xs={12}>
                    <Button onClick={handleSave} color={"primary"}>
                        Termin Kampagne speichern
                    </Button>
                </Grid>
            </Grid>

            {isLoading && (
                <LinearProgress
                    color={"primary"}
                    style={{ marginBottom: "16px" }}
                />
            )}
            <TabContainer
                isAdmin={true}
                tabs={[
                    {
                        id: "tab-campaign-data",
                        label: "Kampagne Daten",
                        display: true,
                        tabKey: 1,
                        content: (
                            <CampaignDataTab
                                errorRoomState={errorRoomState}
                                errorState={errorState}
                                campaignData={{
                                    ...campaignData,
                                }}
                                handleMailTextChange={handleMailTextChange}
                                handleMailWysiwygChange={
                                    handleMailWysiwygChange
                                }
                                handleUploadClick={handleUploadClick}
                                handleEmptyUploadClick={handleEmptyUploadClick}
                                handleCheckboxChange={handleCheckboxChange}
                                deleteCampaignPoint={deleteCampaignPoint}
                                handleSaveRoom={handleSaveRoom}
                                editIndex={editIndex}
                                handleChangeNextRoom={handleChangeNextRoom}
                                projectList={projectList}
                                handleTextChange={handleTextChange}
                                handleImageChange={handleImageChange}
                                handleCampaignDateChange={
                                    handleCampaignDateChange
                                }
                                nextRoom={nextRoom}
                                changeProject={changeProject}
                                setRoomToEdit={setRoomToEdit}
                            />
                        ),
                    },
                    {
                        id: "tab-event-data",
                        label: "Termine erstellen",
                        display: isEditForm,
                        tabKey: 2,
                        content: (
                            <AppointmentBulkDataTab
                                errorAppointmentState={errorAppointmentState}
                                campaignData={campaignData}
                                appointmentData={appointmentData}
                                onTextChange={handleAppointmentBulkTextChange}
                                handleSave={handleAppointmentBulkSave}
                                onCheckBoxChange={handleFlagGroupCheckboxChange}
                                onRoomCheckBoxChange={handleRoomCheckBoxChange}
                                onDateChange={handleAppointmentDateChange}
                                onTimeChange={handleAppointmentTimeChange}
                            />
                        ),
                    },
                    {
                        id: "tab-appointment-data",
                        label: "Termine",
                        display: isEditForm,
                        tabKey: 3,
                        content: (
                            <AppointmentListingTab
                                campaignData={campaignData}
                                appointments={appointmentsData}
                                mandant={mandant}
                                campaignId={campaignData.id}
                                campaignRooms={campaignData.campaignRooms}
                                onUpdate={() => {
                                    loadAppointmentsToState(campaignData.id);
                                }}
                            />
                        ),
                    },
                    {
                        id: "tab-participant-import-data",
                        label: "Teilnehmer importieren",
                        display: isEditForm, // && !campaignData.isPublicAvailable,
                        tabKey: 4,
                        content: (
                            <ParticipantImportDataTab
                                document={participantImportData}
                                handleFileImport={handleFileImport}
                                handleSave={handleParticipantListImport}
                                handleReset={handleImportReset}
                            />
                        ),
                    },
                    {
                        id: "tab-participant-listing-data",
                        label: "Teilnehmer",
                        display: isEditForm,
                        tabKey: 5,
                        content: (
                            <ParticipantListingTab
                                participants={participantsData}
                                mandant={mandant}
                                campaignId={campaignData.id}
                                onUpdate={() => {
                                    loadParticipantsToState(campaignData.id);
                                }}
                            />
                        ),
                    },
                ]}
                activeTab={activeTab}
                ariaLabel={"Campaign Edit Form"}
                idKey={"campaign-admin"}
                handleChange={handleChange}
            />
            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};
export default CampaignForm;
