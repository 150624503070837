import React from "react";

import Comments from "../../../../components/comment/Comments";
import IUser from "../../../../store/userStore/interfaces/IUser";
import IEventFull from "../../../../types/IEventFull";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/IProjectFull";

interface ICommentTabProps {
    event: IEventFull;
    mandant: IMandant;
    user: IUser;
}

function CommentTab(props: ICommentTabProps) {
    const { event, mandant, user } = props;

    const project = event.project as IProjectFull;

    return (
        <div className="gap-top">
            <Comments
                project={project}
                eventId={event.id}
                projectRoles={event.roles}
                mandant={mandant}
                user={user}
            />
        </div>
    );
}

export default CommentTab;
