import IProjectFull from "../IProjectFull";
import InitialDocumentData from "./InitialDocumentData";

const InitialProjectFullData: IProjectFull = {
    attachments: [],
    id: 0,
    title: "",
    shortTitle: "",
    urlKey: "",
    entryPage: "",
    imageMenu: { ...InitialDocumentData },
    imageHeader: { ...InitialDocumentData },
    imageMap: { ...InitialDocumentData },
    imageConsulting: { ...InitialDocumentData },
    theStart: "",
    theEnd: "",
    description: "",
    motivation: "",
    teamText: "",
    consultingText: "",
    description2: "",
    motivation2: "",
    teamText2: "",
    consultingText2: "",
    mapProposalText: "",
    commmentRulesText: "",
    shortDesc: "",
    projectResults: [],
    images: [],
    deadlines: [],
    events: [],
    team: [],
    flagMyProject: false,
    flagArchive: false,
    flagCommentsOpen: true,
    flagPublished: false,
    flagPolls: false,
    flagLinkExternalOnly: false,
    flagHideInOverview: false,
    longitude: 10.4515,
    latitude: 51.1657,
    zoom: 4,
    roles: [],
    flagCommentsReadonly: true,
    flagCommentsRegistered: true,
    flagMapCommentsReadonly: true,
    flagHideStegLogo: false,

    flagTabStart: true,
    titleTabStart: "Startseite",
    menuTabStart: "Startseite",
    flagTabInfo: true,
    titleTabInfo: "Informieren",
    menuTabInfo: "Informieren",
    flagTabEvents: true,
    titleTabEvents: "Veranstaltungen",
    menuTabEvents: "Veranstaltungen",
    flagTabResults: true,
    titleTabResults: "Bisherige Ergebnisse",
    menuTabResults: "Bisherige Ergebnisse",
    flagTabParticipate: true,
    titleTabParticipate: "Beteiligung",
    menuTabParticipate: "Beteiligung",
    flagTabConsulting: false,
    titleTabConsulting: "Beratung",
    menuTabConsulting: "Beratung",
    flagTabTeam: true,
    titleTabTeam: "Projektteam",
    menuTabTeam: "Projektteam",
    flagTabCampaign: true,
    titleTabCampaign: "Termine",
    menuTabCampaign: "Termine",
    flagMapComments: false,
    flagShowTitle: true,
    flagEnableProjectCms: false,
};
export default InitialProjectFullData;
