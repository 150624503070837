import "ol/ol.css";
import "./proposalMap.css";
import "ol-ext/dist/ol-ext.css";
import "ol-ext/style/FontAwesomeDef.js";

import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    Paper,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import { Pagination } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import { Feature, Map, Overlay, View } from "ol";
import { FullScreen, defaults as defaultControls } from "ol/control";
import Point from "ol/geom/Point";
import { defaults as defaultInteraction } from "ol/interaction";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import OverlayPositioning from "ol/OverlayPositioning";
import { transformExtent } from "ol/proj";
import { transform } from "ol/proj";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import { Fill, Style } from "ol/style";
import Circle from "ol/style/Circle";
import Stroke from "ol/style/Stroke";
import React, { useEffect } from "react";
import Masonry from "react-masonry-css";
import { MapStateToPropsParam, connect } from "react-redux";

import { Format, MAP_COMMENT_PAGINATION } from "../../../config/config";
import IApplicationRootState from "../../../store/StoreTypes";
import IMandant from "../../../types/IMandant";
import IMapProposal from "../../../types/IMapProposal";
import IMapProposalType from "../../../types/IMapProposalType";
import InitialMapProposal from "../../../types/initialData/InitialMapProposal";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import IUser from "../../../types/IPortalUser";
import IProjectFull from "../../../types/IProjectFull";
import ISnackbarState from "../../../types/ISnackbarState";
import toInputString, {
    toInputObject,
} from "../../../utils/form/toInputString";
import DevLogger from "../../../utils/logger/DevLogger";
import {
    createProposalRequest,
    getProjectProposalsRequest,
} from "../../../xhr/ProposalRequests";
import { loadPublicProposalTypeList } from "../../../xhr/ProposalTypeRequests";
import HtmlContent from "../../cms/HtmlContent";
import { IFormValidationState, isValidProposal } from "./formValidation";

interface IMapStateProps {
    mandant: IMandant;
}

interface IOwnProps {}

export const hex2rgb = (hex: string) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // return {r, g, b}
    return [r, g, b];
};

/* style function  */
const makeStyle = (color = [255, 0, 0]) => {
    return [
        new Style({
            image: new Circle({
                radius: 7,
                fill: new Fill({ color: "black" }),
                stroke: new Stroke({
                    color,
                    width: 4,
                }),
            }),
        }),
    ];
};

/* the layer that contain the Marker */
const markerLayer = new VectorLayer({
    source: new VectorSource({ wrapX: false }),
});
function transformTo(extent: any) {
    return transformExtent(extent, "EPSG:4326", "EPSG:3857");
}
const myIneration = defaultInteraction({
    doubleClickZoom: false,
    //dragPan: false,
    //mouseWheelZoom: false,
    shiftDragZoom: false,
    altShiftDragRotate: false,
});
const olMap = new Map({
    target: undefined,
    interactions: myIneration,
    layers: [
        new TileLayer({
            source: new XYZ({
                url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                maxZoom: 18,
            }),
        }),
        markerLayer,
    ],
    controls: defaultControls().extend([new FullScreen()]),

    view: new View({
        center: transform([11.576124, 48.137154], "EPSG:4326", "EPSG:3857"),
        zoom: 1,
        extent: transformTo([3, 36, 17, 56]),
    }),
});
/* clear popup */
const clearPopup = (popType: string) => {
    const popupElement: any = document.getElementById(popType);
    popupElement.style.display = "none";
};
/* put popup custom */
const displayHoverPopup = (proposal: IMapProposal) => {
    clearPopup("info-popup");
    clearPopup("popup");
    const convertedPosition = transform(
        [proposal.longitude, proposal.latitude],
        "EPSG:4326",
        "EPSG:3857"
    );
    const hoverPopup: any = document.getElementById("hover-popup");
    hoverPopup.style.display = "block";
    hoverPopup.innerHTML = proposal.comment;

    const popup = new Overlay({
        id: "hover-popup",
        element: hoverPopup,
        position: convertedPosition,
        positioning: OverlayPositioning.BOTTOM_CENTER,
        autoPan: true,
        autoPanAnimation: {
            duration: 250,
        },
    });

    olMap.addOverlay(popup);
    olMap.getView().animate(
        { zoom: 16 },
        {
            center: convertedPosition,
        }
    );
};
const putAllProposal = (Proposals: IMapProposal[]) => {
    const source = markerLayer.getSource();
    source.clear();
    Proposals.forEach((Proposal: IMapProposal) => {
        const transformed = transform(
            [Proposal.longitude, Proposal.latitude],
            "EPSG:4326",
            "EPSG:3857"
        );
        const myfeature = new Feature({
            geometry: new Point(transformed),
            value: Proposal,
        });
        myfeature.setStyle(
            makeStyle(
                hex2rgb(
                    Proposal.type
                        ? toInputString(Proposal.type?.color)
                        : "#ff000"
                )
            )
        );
        source.addFeature(myfeature);
    });
};

interface IOwnProps {
    signedUser: IUser | null;
    project: IProjectFull;
}
interface IProposalMapProps extends IMapStateProps, IOwnProps {}
/* the ProposalMap Component */
function ProposalMap(props: IProposalMapProps) {
    const { project, signedUser, mandant } = props;

    const [proposals, setProposals] = React.useState<IMapProposal[]>([]);

    const [proposalTypes, setProposalTypes] = React.useState<
        IMapProposalType[]
    >([]);

    /* paginations states  */
    const [page, setPage] = React.useState(1);
    const [commentsToDisplay, setCommentsToDisplay] = React.useState<
        IMapProposal[]
    >([]);

    const [snackBar, setSnackBar] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const breakpointColumnsObj = {
        default: 2,
        700: 1,
    };

    React.useEffect(() => {
        getProjectProposalsRequest(mandant.key, "" + project.id)
            .then((response: any) => {
                setProposals(response.data);
                setCommentsToDisplay(
                    response.data
                        .sort((a: IMapProposal, b: IMapProposal) => {
                            const firstdate = new Date(a.creationDate);
                            const seconddate = new Date(b.creationDate);
                            return seconddate.getTime() - firstdate.getTime();
                        })
                        .slice(
                            (page - 1) * MAP_COMMENT_PAGINATION,
                            page * MAP_COMMENT_PAGINATION
                        )
                );
            })
            .catch((err: any) => {
                DevLogger.logError(err);
            });

        loadPublicProposalTypeList(mandant.key, project.id)
            .then((response) => {
                setProposalTypes(response.data);
            })
            .catch((err) => {
                DevLogger.logError(err);
            });

        return () => {
            const popupElement: any = document.getElementById("popup");
            popupElement.style.display = "none";
            const popupInfoElement: any = document.getElementById("info-popup");
            popupInfoElement.style.display = "none";
        };
    }, [mandant, project]);

    React.useEffect(() => {
        putAllProposal(commentsToDisplay);
    }, [commentsToDisplay]);

    /* proposal State state  */
    const [mapProposal, setMapProposal] = React.useState<IMapProposal>({
        ...InitialMapProposal,
        project: { id: parseInt("" + project.id) },
    });
    const closeSnackbar = () => {
        setSnackBar({ ...InitialSnackbarState });
    };

    /* textfielEnabled state */
    const [textfieldEnabled, setTextfieldEnabled] =
        React.useState<boolean>(false);
    /* error form state  */
    const [errorState, setErrorState] = React.useState<IFormValidationState>({
        hasError: false,
    });
    /* loading state */
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    /* info popup state  */
    const [popupC, setPopupC] = React.useState<any>(<></>);

    /* clicked position state */
    const [clickedPos, setClickedPos] = React.useState<number[]>([0.0]);

    const displayInfoPopup = (content: string, position: any, feature: any) => {
        clearPopup("popup");
        clearPopup("hover-popup");
        const popupElement: any = document.getElementById("info-popup");
        popupElement.style.display = "block";
        // popupElement.innerHTML = content;
        if (content) {
            setPopupC(content);
        }
        const popup = new Overlay({
            id: "popup",
            element: popupElement,
            position: feature.getGeometry().getCoordinates(),
            positioning: OverlayPositioning.BOTTOM_CENTER,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
        });

        olMap.addOverlay(popup);
    };
    const displayPopup = (position: any) => {
        clearPopup("info-popup");
        clearPopup("hover-popup");
        const popupElement: any = document.getElementById("popup");
        popupElement.style.display = "block";

        const popup = new Overlay({
            id: "popup",
            element: popupElement,
            position,
            positioning: OverlayPositioning.BOTTOM_CENTER,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
        });

        olMap.addOverlay(popup);
    };

    React.useEffect(() => {
        olMap.getView().animate(
            { zoom: project?.zoom + 1 },
            {
                center: transform(
                    [project.longitude, project.latitude],
                    "EPSG:4326",
                    "EPSG:3857"
                ),
            }
        );
    }, [project]);

    const handlePointerMove = (e: any) => {
        olMap.forEachFeatureAtPixel(e.pixel, (feature) => {
            /* const popupElement: any = document.getElementById("popup");
            popupElement.style.display = "none"; */
            displayInfoPopup(
                feature.getProperties().value.comment,
                e.coordinate,
                feature
            );
        });
    };

    const handleMapClick = (e: any) => {
        const popupElement: any = document.getElementById("info-popup");
        popupElement.style.display = "none";
        setClickedPos(e.coordinate);
        // setMyComment("");
        displayPopup(e.coordinate);
    };

    /* handle commentChange */
    const handleTextChange = (event: any) => {
        setMapProposal({
            ...mapProposal,
            [event.target.name]: event.target.value,
        });
    };

    const changeSelectbox = (event: React.ChangeEvent<any>) => {
        setMapProposal({
            ...mapProposal,
            type: { id: event.target.value },
        });

        console.log("got id", { id: event.target.value });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const validateProposal = isValidProposal(mapProposal, proposalTypes);
        setErrorState(validateProposal);
        if (validateProposal.hasError) {
            return;
        }
        const convertedPosition = transform(
            clickedPos,
            "EPSG:3857",
            "EPSG:4326"
        );

        setIsLoading(true);
        setTextfieldEnabled(true);
        createProposalRequest(
            {
                ...mapProposal,
                longitude: convertedPosition[0],
                latitude: convertedPosition[1],
            },
            mandant.key
        )
            .then((response: any) => {
                setIsLoading(false);
                setMapProposal({
                    ...InitialMapProposal,
                    project: { id: parseInt("" + project.id) },
                });
                setTextfieldEnabled(false);
                const popupElement: any = document.getElementById("popup");
                popupElement.style.display = "none";

                commentsToDisplay.unshift(response.data);

                if (commentsToDisplay.length + 2 > MAP_COMMENT_PAGINATION) {
                    commentsToDisplay.pop();
                }

                setCommentsToDisplay([...commentsToDisplay]);

                const newData = [...proposals];
                newData.unshift(response.data);
                setProposals(newData);

                setSnackBar({
                    isOpen: true,
                    type: "success",
                    message: "Vielen Dank für Ihren Vorschlag.",
                });
            })
            .catch(() => {
                setSnackBar({
                    isOpen: true,
                    type: "error",
                    message:
                        "Beim Erstellen des Vorschlags ist leider ein Fehler aufgetreten.",
                });

                setIsLoading(false);
            });
    };

    const handlePagerChange = (event: object, pagerPos: number) => {
        clearPopup("info-popup");
        clearPopup("hover-popup");
        clearPopup("popup");
        olMap.setView(
            new View({
                center: transform(
                    [project.longitude, project.latitude],
                    "EPSG:4326",
                    "EPSG:3857"
                ),
                zoom: project?.zoom + 1,
            })
        );
        setPage(pagerPos);

        setCommentsToDisplay(
            proposals.slice(
                (pagerPos - 1) * MAP_COMMENT_PAGINATION,
                pagerPos * MAP_COMMENT_PAGINATION
            )
        );
    };
    useEffect(() => {
        olMap.setTarget("map");

        if (signedUser && !project.flagMapCommentsReadonly) {
            olMap.on("singleclick", handleMapClick);
            // olMap.on("doubleclick", () => console.log("double"));
            olMap.on("pointermove", handlePointerMove);
        }

        return () => olMap.setTarget(undefined);
    }, [signedUser]);

    const handleCommentClick = (proposal: IMapProposal) => {
        displayHoverPopup(proposal);
    };
    return (
        <>
            <Grid container className="comment-map-container">
                <Grid item xs={12}>
                    <Typography variant="h3" className="App-sidebar-h3 gap-top">
                        Machen Sie mit!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <HtmlContent
                        content={project.mapProposalText}
                        className="gap-top gap-bottom"
                    />
                </Grid>

                <div className="proposal-map-wrapper">
                    <div className="public-map-container" id="map">
                        <div
                            className="ol-popup hover-popup"
                            id="hover-popup"
                        />
                        <div className="ol-popup" id="popup">
                            <Grid container spacing={1} direction="column">
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        autoFocus
                                        id="standard-multiline-flexible"
                                        label="Mein Vorschlag"
                                        fullWidth
                                        value={mapProposal.comment}
                                        onChange={handleTextChange}
                                        disabled={textfieldEnabled}
                                        multiline
                                        name="comment"
                                        variant="outlined"
                                        error={errorState.comment}
                                        required={true}
                                    />

                                    {proposalTypes.length > 0 && (
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="project-select-label">
                                                Kategorie
                                            </InputLabel>
                                            <Select
                                                labelId="project-select-label"
                                                value={toInputString(
                                                    mapProposal.type?.id
                                                )}
                                                onChange={changeSelectbox}
                                                label="Kategorie"
                                            >
                                                <MenuItem key="empty" value="">
                                                    <em>
                                                        Bitte Kategorie wählen
                                                    </em>
                                                </MenuItem>
                                                {proposalTypes.map(
                                                    (type, index: number) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    "type-" +
                                                                    index
                                                                }
                                                                value={type.id}
                                                            >
                                                                {type.label}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                </form>
                                {isLoading && <CircularProgress />}
                                {!isLoading && (
                                    <Button
                                        className="proposal-submit"
                                        color="primary"
                                        onClick={handleSubmit}
                                        size="small"
                                    >
                                        Vorschlag abschicken
                                    </Button>
                                )}
                            </Grid>
                        </div>
                        <div className="ol-popup" id="info-popup">
                            {popupC}
                        </div>
                    </div>
                </div>

                <Grid item xs={12}>
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="masonry-grid comments-container gap-bottom gap-top"
                        columnClassName="masonry-grid_column"
                    >
                        {commentsToDisplay.map(
                            (comment: IMapProposal, index: number) => {
                                return (
                                    <div
                                        key={"map_comment" + index + comment.id}
                                        onClick={() => {
                                            handleCommentClick(comment);
                                        }}
                                    >
                                        <Box>
                                            <Paper
                                                elevation={0}
                                                className="comment_paper"
                                            >
                                                <div
                                                    className="comment"
                                                    key={
                                                        "comment_number_" +
                                                        comment.id
                                                    }
                                                >
                                                    <div className="content">
                                                        <div className="content-header">
                                                            {/*
                                                            <div className="author">
                                                                {
                                                                    comment.user
                                                                        ?.nickName
                                                                }
                                                            </div>
                                                            */}
                                                            <div className="metadata">
                                                                <div>
                                                                    {moment(
                                                                        comment.creationDate
                                                                    ).format(
                                                                        Format.dateTime
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {comment.type !=
                                                            null && (
                                                            <Typography>
                                                                Kategorie:{" "}
                                                                {
                                                                    comment.type
                                                                        .label
                                                                }
                                                            </Typography>
                                                        )}
                                                        <Typography variant="subtitle1">
                                                            {comment.comment}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Paper>
                                        </Box>
                                    </div>
                                );
                            }
                        )}
                    </Masonry>
                </Grid>
            </Grid>
            {proposals.length > MAP_COMMENT_PAGINATION && (
                <Pagination
                    className="comment-pager"
                    onChange={handlePagerChange}
                    count={Math.ceil(proposals.length / MAP_COMMENT_PAGINATION)}
                />
            )}
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={closeSnackbar}
                    severity={snackBar.type}
                >
                    {snackBar.message}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
    };
};

export default connect(mapStateToProps)(ProposalMap);
