/**
 * Create the store with dynamic reducers
 */

import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
//import { composeWithDevTools } from "redux-devtools-extension";
//import { composeWithDevTools } from "redux-devtools-extension";
//import { persistCombineReducers, persistStore } from "redux-persist";
//import storage from "redux-persist/lib/storage";
import Thunk from "redux-thunk";

import createReducer from "./Reducers";

export default function configureStore(history: any, initialState: any = {}) {
    /*
    const persistConfig = {
        key: "root",
        storage: storage,
    };
     */

    const persistCombinedReducers = combineReducers(createReducer());

    const store = createStore<any, any, any, any>(
        persistCombinedReducers,
        initialState,
        compose(
            applyMiddleware(Thunk),
            applyMiddleware(routerMiddleware(history))
            //composeWithDevTools()
        )
    ); // FIX: disable any
    // const persistor = persistStore(store); , persistor: persistor
    return { store: store };
}
