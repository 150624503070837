import "./ForgotPassword.css";

import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import IconVpnKey from "@material-ui/icons/VpnKey";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import PortalHeader from "../../../layout/portalHeader/PortalHeader";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import stringIsEmpty from "../../../utils/form/stringIsEmpty";
import toInputString from "../../../utils/form/toInputString";
import validateEmail from "../../../utils/form/validateEmail";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import { demandResetPasswordRequest } from "../../../xhr/UserRequests";

type IFormError = {
    nickName?: string;
    email?: string;
};

export type IPasswordResetRequest = {
    nickName?: string;
    email?: string;
};

interface ILoginMapStateProps {}

interface ILoginDispatchProps {}

interface IOwnProps {
    mandant: IMandant;
}

interface ILoginParams
    extends ILoginMapStateProps,
        ILoginDispatchProps,
        IOwnProps {}

const ForgotPassword = (props: ILoginParams) => {
    const { mandant } = props;

    const history = useHistory();

    const [formData, setFormData] = React.useState<IPasswordResetRequest>({});
    const [errorState, setErrorState] = React.useState<IFormError>({});
    const [loading, setLoading] = React.useState<boolean>(false);

    const [snackBar, setSnackBar] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBar({ ...InitialSnackbarState });
    };

    const handleChange = (e: any) => {
        const newData = { ...formData, [e.target.name]: [e.target.value] };

        // @ts-ignore
        errorState["" + e.target.name] = null;
        setErrorState({ ...errorState });
        setFormData(newData);
    };

    const handleClickReset = () => {
        const newErrorState: IFormError = {};
        let hasError = false;

        if (stringIsEmpty(formData.nickName) && stringIsEmpty(formData.email)) {
            hasError = true;
            newErrorState.email = "Bitte eine E-Mail angeben,";
            newErrorState.nickName = "oder einen Anzeigenamen.";
        } else if (
            !stringIsEmpty(formData.email) &&
            !validateEmail("" + formData.email)
        ) {
            hasError = true;
            newErrorState.email = "Bitte eine korrekte E-Mail angeben.";
        }

        setErrorState(newErrorState);

        if (hasError) {
            return;
        }

        setLoading(true);

        demandResetPasswordRequest(mandant.key, formData)
            .then(() => {
                setSnackBar({
                    isOpen: true,
                    message: "Die Anfrage zum zurücksetzen wurde verschickt",
                    type: "success",
                });
                setLoading(false);
                setFormData({});
                setErrorState({});

                history.push(toPortalUrl(mandant, `/passwort_zuruecksetzen`));
            })
            .catch(() => {
                setSnackBar({
                    isOpen: true,
                    message: "Die Anfrage konnte nicht bearbeitet werden.",
                    type: "error",
                });
                setLoading(false);
                setErrorState({});
            });
    };

    return (
        <div className="portal-section">
            <Helmet>
                <title>Passwort vergessen</title>
            </Helmet>

            <PortalHeader mandant={mandant} />

            <Container className="login-container">
                <Grid container>
                    <Card
                        variant="outlined"
                        className="public-login-form-container gap-bottom"
                    >
                        <CardContent className="resetpwd-form-content">
                            <Grid item xs={12} md={12}>
                                <div className="half-gap-top">
                                    <Typography className="gap-bottom">
                                        Bitte geben Sie ihre E-Mail oder Ihren
                                        Anzeigenamen an.
                                    </Typography>

                                    <TextField
                                        required
                                        id="outlined-basic"
                                        label="E-Mail Adresse"
                                        variant="outlined"
                                        className="public-input-text"
                                        type="text"
                                        fullWidth
                                        onChange={handleChange}
                                        error={!!errorState.email}
                                        helperText={toInputString(
                                            errorState.email
                                        )}
                                        name="email"
                                    />

                                    <TextField
                                        required
                                        id="outlined-basic"
                                        label="Anzeigename"
                                        variant="outlined"
                                        className="public-input-text"
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errorState.nickName}
                                        helperText={toInputString(
                                            errorState.nickName
                                        )}
                                        name="nickName"
                                    />

                                    {loading && <CircularProgress />}
                                    {!loading && (
                                        <Button
                                            type="submit"
                                            color="primary"
                                            fullWidth
                                            onClick={handleClickReset}
                                            className="half-gap-top"
                                            endIcon={<IconVpnKey />}
                                        >
                                            Passwort zurücksetzen
                                        </Button>
                                    )}
                                </div>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Container>
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <MuiAlert
                    elevation={0}
                    variant="filled"
                    onClose={closeSnackbar}
                    severity={snackBar.type}
                >
                    {snackBar.message}
                </MuiAlert>
            </Snackbar>
            <PortalFooter mandant={mandant} />
        </div>
    );
};

export default ForgotPassword;
