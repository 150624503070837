import IPollCampaign from "../IPollCampaign";

const InitialPollCampaignData: IPollCampaign = {
    title: "",
    description: "",
    flagPublished: false,
    flagShowResults: false,
    flagName: 0,
    flagEmail: 0,
    thankYouText: "",
    creationDate: "",
    points: [],
};

export default InitialPollCampaignData;
