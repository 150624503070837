import "ol/ol.css";
import "./proposalMap.css";
import "ol-ext/dist/ol-ext.css";
import "ol-ext/style/FontAwesomeDef.js";

import { Grid, Paper, rgbToHex } from "@material-ui/core";
import { Feature, Map, Overlay, View } from "ol";
import { FullScreen } from "ol/control";
import Attribution from "ol/control/Attribution";
import Point from "ol/geom/Point";
import { defaults as defaultInteraction } from "ol/interaction";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import OverlayPositioning from "ol/OverlayPositioning";
import { transform } from "ol/proj";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import { Fill, Style } from "ol/style";
import Circle from "ol/style/Circle";
import Stroke from "ol/style/Stroke";
import React, { useEffect } from "react";

import IMapProposal from "../../../types/IMapProposal";
import toInputString from "../../../utils/form/toInputString";
import { hex2rgb } from "../proposal/ProposalMap";

interface IProposalMapProps {
    proposals: IMapProposal[];
    activeProposal: IMapProposal;
}

/* style function  */
const makeStyle = (color?: string) => {
    const hexColor = toInputString(color) !== "" ? hex2rgb(color as string) : [255, 0, 0];

    return [
        new Style({
            image: new Circle({
                radius: 7,
                fill: new Fill({ color: "black" }),
                stroke: new Stroke({
                    color: hexColor,
                    width: 4,
                }),
            }),
        }),
    ];
};
/* the layer that contain the Marker */
const markerLayer = new VectorLayer({
    source: new VectorSource({ wrapX: false }),
});
const myIneration = defaultInteraction({
    doubleClickZoom: true,
    dragPan: true,
    mouseWheelZoom: true,
    shiftDragZoom: true,
    altShiftDragRotate: true,
});

const olMap = new Map({
    target: undefined,
    interactions: myIneration,
    layers: [
        new TileLayer({
            source: new XYZ({
                url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                maxZoom: 18,
            }),
        }),
        markerLayer,
    ],
    controls: [new FullScreen(), new Attribution()],

    view: new View({
        center: transform([11.576124, 48.137154], "EPSG:4326", "EPSG:3857"),
        zoom: 1,
        // projection: "EPSG:4326",
    }),
});
const putAllProposal = (Proposals: IMapProposal[]) => {
    markerLayer.getSource().clear();
    Proposals.forEach((Proposal: IMapProposal) => {
        const transformed = transform(
            [Proposal.longitude, Proposal.latitude],
            "EPSG:4326",
            "EPSG:3857"
        );
        const source = markerLayer.getSource();
        const myfeature = new Feature({
            geometry: new Point(transformed),
            value: Proposal,
        });
        myfeature.setStyle(makeStyle(Proposal.type?.color));
        source.addFeature(myfeature);
    });
};
/* show popup with different color */
const displayHoverPopup = (proposal: IMapProposal) => {
    const convertedPosition = transform(
        [proposal.longitude, proposal.latitude],
        "EPSG:4326",
        "EPSG:3857"
    );
    const hoverPopup: any = document.getElementById("hover-popup");
    hoverPopup.style.display = "block";
    hoverPopup.innerHTML = proposal.comment;

    const popup = new Overlay({
        id: "hover-popup",
        element: hoverPopup,
        position: convertedPosition,
        positioning: OverlayPositioning.BOTTOM_CENTER,
        autoPan: true,
        autoPanAnimation: {
            duration: 250,
        },
    });

    olMap.addOverlay(popup);
    olMap.getView().animate(
        { zoom: 15 },
        {
            center: convertedPosition,
        }
    );
};

/* the ProposalMap Component */
export default function AdminProposalMap(props: IProposalMapProps) {
    const { proposals, activeProposal } = props;

    React.useEffect(() => {
        putAllProposal(proposals);
    }, [proposals]);
    /* clear popup*/
    const clearPopup = (popType: string) => {
        const popupElement: any = document.getElementById(popType);
        popupElement.style.display = "none";
    };

    /* show popup evry actual proposal change */
    React.useEffect(() => {
        if (activeProposal.longitude !== 0 && activeProposal.latitude !== 0) {
            clearPopup("info-popup");

            displayHoverPopup(activeProposal);
        }
    }, [activeProposal]);

    /* info popup state  */
    const [popupC, setPopupC] = React.useState<any>(<div />);

    const displayInfoPopup = (content: string, position: any, feature: any) => {
        const popupElement: any = document.getElementById("info-popup");
        popupElement.style.display = "block";
        // popupElement.innerHTML = content;
        if (content) {
            setPopupC(content);
        }
        const popup = new Overlay({
            id: "popup",
            element: popupElement,
            position: feature.getGeometry().getCoordinates(),
            positioning: OverlayPositioning.BOTTOM_CENTER,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
        });

        olMap.addOverlay(popup);
    };

    React.useEffect(() => {
        olMap.getView().animate(
            { zoom: 16 },
            {
                center: transform(
                    [9.73322, 51.37052],
                    "EPSG:4326",
                    "EPSG:3857"
                ),
            }
        );
    }, []);

    const handlePointerMove = (e: any) => {
        olMap.forEachFeatureAtPixel(e.pixel, (feature) => {
            displayInfoPopup(
                feature.getProperties().value.comment,
                e.coordinate,
                feature
            );
        });
    };

    const handleClick = () => {
        clearPopup("info-popup");
        clearPopup("hover-popup");
    };

    useEffect(() => {
        olMap.setTarget("map");
        olMap.on("click", handleClick);
        olMap.on("pointermove", handlePointerMove);
        return () => {
            clearPopup("info-popup");

            olMap.setTarget(undefined);
        };
    }, []);

    return (
        <>
            <Grid container className="comment-map-container">
                <Paper
                    variant="outlined"
                    className="Proposal-Tab"
                    style={{ width: "100%" }}
                >
                    <div className="proposal-map-wrapper">
                        <div className="public-map-container" id="map">
                            <div className="ol-popup" id="info-popup">
                                {popupC}
                            </div>
                            <div
                                className="ol-popup hover-popup"
                                id="hover-popup"
                            />
                        </div>
                    </div>
                </Paper>
            </Grid>
        </>
    );
}
