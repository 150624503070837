import "./AdminShowContentModeFooter.css";

import React from "react";

interface IAdminShowContentModeFooterProps {}

function AdminShowContentModeFooter() {
    return <div className="preview-container">Vorschaumodus</div>;
}

export default AdminShowContentModeFooter;
