export const FW85_BASIC_SUPPORT_POURCENTAGE = 20 / 100;
export const KFW70_BASIC_SUPPORT_POURCENTAGE = 25 / 100;
export const KFW55_BASIC_SUPPORT_POURCENTAGE = 30 / 100;
export const KFW40_BASIC_SUPPORT_POURCENTAGE = 35 / 100;

export const DEFAULT_VALUE = 0;
export const FW85_BASIC_FUNDING_CAP_POURCENTAGE = 0.5 / 100;
export const KFW70_BASIC_FUNDING_CAP_POURCENTAGE = 5 / 100;

export const FW85BONUS_PROMOTION_POURCENTAGE = 10 / 100;
export const KFW70BONUS_PROMOTION_POURCENTAGE = 15 / 100;

export const MAX_COSTS_FW85 = 40000000;
export const MAX_BASIC_FUNDING_CAP_FW85 = 350000;

export const MAX_COSTS_KFW70 = 4454000;
export const MAX_BASIC_FUNDING_CAP_KFW70 = 400000;

export const MAX_COSTS_KFW55 = 2857000;
export const MAX_BONUS_PROMOTION_KFW55 = 450000;

export const MAX_COSTS_KFW40 = 2258000;
export const MAX_BONUS_PROMOTION_KFW40 = 500000;
export const KFW85 = "KFW85";
export const KFW70 = "KFW70";
export const KFW55 = "KFW55";
export const KFW40 = "KFW40";
export const NA = "-";
export const FIXED_VALUE_CALCULATE_BASIC_FUNDING_CAP = 150000;
export const MIN_LIMIT_COSTS = 760000;

const KFW_STATIC_DATA = {
    KFW85: {
        KFW85_BASIC_SUPPORT_POURCENTAGE: 20 / 100,
        KFW85_BASIC_FUNDING_CAP_POURCENTAGE: 0.5 / 100,
        MAX_COSTS_KFW85: 40000000,
        MAX_BASIC_FUNDING_CAP_KFW85: 350000,
        FIXED_BASIC_FUNDING_CAP_KFW85: 150000,
        MIN_LIMIT_COSTS: 760000,
        MAX_LIMIT_COSTS: 800000,
    },
    KFW70: {
        KFW70_BASIC_SUPPORT_POURCENTAGE: 25 / 100,
        KFW70_BASIC_FUNDING_CAP_POURCENTAGE: 5 / 100,
        MAX_COSTS_KFW70: 4454000,
        MAX_BASIC_FUNDING_CAP_KFW70: 400000,
    },
    KFW55: {
        KFW55_BASIC_SUPPORT_POURCENTAGE: 30 / 100,
        KFW55_BONUS_PROMOTION_POURCENTAGE: 10 / 100,
        MAX_COSTS_KFW55: 2857000,
        MAX_BONUS_PROMOTION_KFW55: 450000,
    },
    KFW40: {
        KFW40_BASIC_SUPPORT_POURCENTAGE: 35 / 100,
        KFW40_BONUS_PROMOTION_POURCENTAGE: 15 / 100,
        MAX_COSTS_KFW40: 2258000,
        MAX_BONUS_PROMOTION_KFW40: 500000,
    },
};
export default KFW_STATIC_DATA;
