import "./ButtonAsLink.css";

import { Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import * as React from "react";

interface IButtonAsLinkProps {
    actionButtonText: string;
    linkUrl: string;
    target?: string;
    endIcon?: React.ReactNode;
}

function ButtonAsLink(props: IButtonAsLinkProps) {
    return (
        <a
            href={props.linkUrl}
            rel="noopener noreferrer"
            target={props.target ? props.target : "_blank"}
            className="download-button-link"
        >
            <Button
                color="primary"
                endIcon={props.endIcon ? props.endIcon : <ArrowForwardIcon />}
            >
                {props.actionButtonText}
            </Button>
        </a>
    );
}

export default ButtonAsLink;
