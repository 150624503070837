import { action } from "typesafe-actions";

import ActionTypes from "./ActionTypesEnum";

export const setUserFromStore = (mandantKey: string) => {
    const userRawData = localStorage.getItem("user-" + mandantKey);
    if (userRawData != null) {
        return action(ActionTypes.UPDATE_USERDATA, JSON.parse(userRawData));
    } else {
        return action(ActionTypes.UPDATE_USERDATA, null);
    }
};

export default setUserFromStore;
