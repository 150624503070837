import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { API_URL_BACKEND, API_URL_CMS } from "../../config/config";
// Types
import { TId } from "../../types/baseTypes/TId";
import IMandant from "../../types/IMandant";
import { isStillAuthenticated } from "../index";

interface IHeadersOptions {
    withAuthorization?: boolean;
    withLang?: boolean;
    isFile?: boolean;
    isPublic?: boolean;
    mandant?: string;
    isWithOwnDomain?: boolean;
    domain?: string;
}

const getHeaders = (
    options: IHeadersOptions = {},
    isIpublic = true,
    mandantKey = ""
) => {
    const headers: any = {
        "content-type": "application/json",
    };

    // user must get logged out when the token expires
    const bearerToken = localStorage.getItem("jwt-" + mandantKey);

    if (!isIpublic) {
        if (bearerToken) {
            headers.Authorization = `Bearer ${bearerToken}`;
        }
    } else if (mandantKey !== "") {
        if (isStillAuthenticated(mandantKey)) {
            headers.Authorization = `Bearer ${bearerToken}`;
        }
    }

    /*
  const token = shapeToken();

  if (options.withAuthorization && token) {
    Object.assign(headers, { Authorization: token });
  }
  */
    return headers;
};

const config = (
    options: IHeadersOptions = {},
    isIpublic = true
): AxiosRequestConfig => ({
    headers: getHeaders(options, isIpublic, "" + options.mandant),
    responseType: options.isFile ? "blob" : "json",
});

const currentLanguage = () => "de/";

const initialOptions: IHeadersOptions = {
    isPublic: true,
    withLang: true,
    withAuthorization: false,
};

function getBaseUrl(options: IHeadersOptions = {}): string {
    if (options.mandant) {
        if (options.isPublic) {
            return options.withLang
                ? `${API_URL_CMS}${options.mandant}/${currentLanguage()}`
                : `${API_URL_CMS}${options.mandant}/`;
        } else {
            return options.withLang
                ? `${API_URL_BACKEND}${options.mandant}/${currentLanguage()}`
                : `${API_URL_BACKEND}${options.mandant}/`;
        }
    } else {
        if (options.isPublic) {
            return options.withLang
                ? `${API_URL_CMS}${currentLanguage()}`
                : API_URL_CMS;
        } else {
            return options.withLang
                ? `${API_URL_BACKEND}${currentLanguage()}`
                : API_URL_BACKEND;
        }
    }
}

function getData<T>(
    url: string,
    options: IHeadersOptions = initialOptions
): Promise<AxiosResponse<T>> {
    const URL = `${getBaseUrl(options)}${url}`;
    return axios.get<T>(URL, config(options, options.isPublic));
}

function postData<T, R>(
    url: string,
    data: T,
    options: IHeadersOptions = initialOptions
): Promise<AxiosResponse<R>> {
    const URL = `${getBaseUrl(options)}${url}`;
    return axios.post<R>(URL, data, config(options, options.isPublic));
}

function putData<T, R>(
    url: string,
    id: TId,
    data: T,
    options: IHeadersOptions = initialOptions
): Promise<AxiosResponse<R>> {
    const URL = `${getBaseUrl(options)}${url}`;
    return axios.put<R>(`${URL}${id}`, data, config(options, options.isPublic));
}

function deleteData<T>(
    url: string,
    id: TId,
    options: IHeadersOptions = initialOptions
): Promise<AxiosResponse<T>> {
    const URL = `${getBaseUrl(options)}${url}`;
    return axios.delete(`${URL}${id}`, config(options, options.isPublic));
}

function getMandantByDomain(domain: string): Promise<AxiosResponse<IMandant>> {
    if (domain.startsWith("/")) {
        domain = domain.substring(1);
    }

    const URL = `${API_URL_BACKEND}cms/mandantByDomain/${currentLanguage()}${domain}/`;
    return axios.get<IMandant>(URL, config({}, true));
}

export { getData, postData, putData, deleteData, getMandantByDomain };
