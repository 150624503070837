import { action } from "typesafe-actions";

import ITheme from "../../types/ITheme";
import ISyncMandant from "../../xhr/interface/ISyncMandant";
import ActionTypes from "./MandantActionTypesEnum";

export const loadMandant = (mandant: ISyncMandant) =>
    action(ActionTypes.LOAD, mandant);
export const loadMandantSuccess = (mandant: ISyncMandant) =>
    action(ActionTypes.LOAD_SUCCESS, mandant);
export const loadMandantError = (error: any) =>
    action(ActionTypes.LOAD_ERROR, error);
export const setMandantTheme = (theme: ITheme) => {
    return action(ActionTypes.SET_THEME, theme);
};
