import moment from "moment";

import { Format } from "../../../../config/config";
import IAppointmentBulk from "../../../../types/appointment/IAppointmentBulk";
import ICampaign from "../../../../types/appointment/ICampaign";

export type IAppointmentValidationState = {
    hasError: boolean;
    startDate?: {
        empty?: boolean;
        invalid?: boolean;
        exceedCampaign?: boolean;
    };
    endDate?: {
        empty?: boolean;
        invalid?: boolean;
        exceedCampaign?: boolean;
    };
    startTime?: boolean;
    endTime?: boolean;
    period?: boolean;
    room?: boolean;
};

export const validationAppointmentDataField = (
    appointment: IAppointmentBulk,
    campaign: ICampaign
): IAppointmentValidationState => {
    const validationState: IAppointmentValidationState = {
        hasError: false,
    };
    const dateFormat = (dateToFormated: string) => {
        return moment(dateToFormated).format(Format.dateEn);
    };

    const startDate = Date.parse(dateFormat(appointment.startDate.date));
    const endDate = Date.parse(dateFormat(appointment.endDate.date));
    const campaignStartDate = Date.parse(dateFormat(campaign.startDate));
    const startTime = Date.parse(appointment.startTime.date);
    const endTime = Date.parse(appointment.endTime.date);

    if (isNaN(startDate)) {
        validationState.startDate = { empty: true };
        validationState.hasError = true;
    }
    if (startDate < campaignStartDate) {
        validationState.startDate = { exceedCampaign: true };
        validationState.hasError = true;
    }
    if (isNaN(endDate)) {
        validationState.endDate = { empty: true };
        validationState.hasError = true;
    }
    if (endDate < startDate) {
        validationState.endDate = { invalid: true };
        validationState.hasError = true;
    }

    if (campaign.endDate) {
        const campaignEndDate = Date.parse(dateFormat(campaign.endDate));
        if (endDate > campaignEndDate) {
            validationState.endDate = { exceedCampaign: true };
            validationState.hasError = true;
        }
    }
    if (isNaN(startTime)) {
        validationState.hasError = true;
        validationState.startTime = true;
    }
    if (isNaN(endTime)) {
        validationState.hasError = true;
        validationState.endTime = true;
    }
    if (appointment.period === 0 || appointment.period.toString() === "") {
        validationState.hasError = true;
        validationState.period = true;
    }
    if (appointment.rooms.length === 0) {
        validationState.hasError = true;
        validationState.room = true;
    }
    return validationState;
};
