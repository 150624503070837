import { ADMIN_OVERVIEW } from "../enums/Endpoints";
import IOverview from "../types/IOverview";
import { getData, putData } from "../utils/httpRequest/request";

const loadOverviewRequest = (): Promise<any> => {
    return getData<IOverview>("overview", {
        isPublic: true,
        withLang: true,
    });
};

const saveOverviewRequest = (
    mandant: string,
    overviewData: any
): Promise<any> => {
    return putData<IOverview, any>(ADMIN_OVERVIEW, "", overviewData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

export { saveOverviewRequest, loadOverviewRequest };
