enum RoutesEnum {
    ADMIN_DASHBOARD = "/datenverwaltung/admin/projekte/",
    ADMIN_PROJECT_LIST = "/datenverwaltung/admin/projekte/",
    ADMIN_PROJECT = "/datenverwaltung/admin/projekt/",
    ADMIN_EVENT = "/veranstaltung/",
    ADMIN_SUB_EVENT = "/veranstaltung/",
    ADMIN_USER_LIST = "/datenverwaltung/admin/anwender/",
    ADMIN_COMMMENTS = "/datenverwaltung/admin/kommentare/",
    ADMIN_USER = "/datenverwaltung/admin/anwender/",
    ADMIN_PORTAL_LIST = "/datenverwaltung/admin/portale/",
    ADMIN_PORTAL = "/datenverwaltung/admin/portal/",
    ADMIN_CMS_PAGE_LIST = "/datenverwaltung/admin/cms/",
    ADMIN_CMS_PAGE = "/datenverwaltung/admin/cms/",
    ADMIN_POLL_CAMPAIGN = "/datenverwaltung/admin/umfragen/",
    ADMIN_APPOINTMENT = "/datenverwaltung/admin/kampagne/",
    ADMIN_MAP_PROPOSAL = "/datenverwaltung/admin/geofeedback/",
    FEEDBACK_LISTING = "/datenverwaltung/admin/feedback/",
    CHANGE_PASSWORD = "/datenverwaltung/admin/passwortAendern/",
    ADMIN_PROJECT_ORDER_LIST = "/datenverwaltung/admin/projektliste/",
    PORTAL_PROJECT = "/projekt",
    PORTAL_PROJECT_LIST = "/projekte",
    PORTAL_EVENT = "/veranstaltung",
    PORTAL_EVENT_LIST = "/veranstaltungen",
    PORTAL_CONTACT = "/kontakt",
    PORTAL_COMMMENTS = "/kommentare",
    PORTAL_MAP = "/karte",
    PORTAL_PAGE = "seite",
    PORTAL_LOGIN = "/anmelden",
    PORTAL_FORGOT_PASSWORD = "passwort_vergessen",
    PORTAL_RESET_PASSWORD = "/passwort_zuruecksetzen",
    ADMIN_HOME = "/datenverwaltung/admin",
}

export default RoutesEnum;
