import IProjectGalleryImage from "../IProjectGalleryImage";
import InitialDocumentData from "./InitialDocumentData";

const InitialProjectGalleryElementData: IProjectGalleryImage = {
    id: null,
    priority: 0,
    type: "",
    document: { ...InitialDocumentData },
    project: "",
};

export default InitialProjectGalleryElementData;
