import "./InDevelopmentBanner.css";

import React from "react";

/** component */

const InDevelopmentBanner: React.FunctionComponent = () => {
    return <div className="banner-label">Entwicklung</div>;
};

export default InDevelopmentBanner;
