import "./CmsPageForm.css";

import {
    Breadcrumbs,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    Link,
    Paper,
    Select,
    TextField,
    Toolbar,
    Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import DeleteIcon from "@material-ui/icons/Delete";
import MoneyIcon from "@material-ui/icons/Money";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import SyncIcon from "@material-ui/icons/Sync";
import SyncDisabledIcon from "@material-ui/icons/SyncDisabled";
import draft from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from "react-sortable-hoc";
import uuid from "uuid-random";

import FileUpload from "../../../components/fileUpload/FileUpload";
import ImageUpload from "../../../components/imageUpload/ImageUpload";
import Notify from "../../../components/notify/Notify";
import TeamTile from "../../../components/team/TeamTile";
import TextEdit from "../../../components/wysiwyg/TextEdit";
import CmsPageElementTypeEnum from "../../../enums/CmsPageElementTypeEnum";
import DocumentTypeEnum from "../../../enums/DocumentTypeEnum";
import RoutesEnum from "../../../routes/RoutesEnum";
import ICmsPage from "../../../types/ICmsPage";
import ICmsPageElement from "../../../types/ICmsPageElement";
import IDocument from "../../../types/IDocument";
import IDocumentRef from "../../../types/IDocumentRef";
import IMandant from "../../../types/IMandant";
import InitialCmsPageData from "../../../types/initialData/InitialCmsPageData";
import InitialDocumentData from "../../../types/initialData/InitialDocumentData";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import IQuestionRef from "../../../types/IQuestionRef";
import IResponse from "../../../types/IResponse";
import ISnackbarState from "../../../types/ISnackbarState";
import ITeam from "../../../types/ITeam";
import ITeamRef from "../../../types/ITeamRef";
import deepCopy from "../../../utils/deepCopy";
import { getEditorStateContent } from "../../../utils/editorContentHelper/editorContentHelper";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import {
    createCmsPageRequest,
    loadCmsPageRequest,
    updateCmsPageRequest,
} from "../../../xhr/CmsPageRequests";
import ISelectOption from "../../../xhr/interface/ISelectOption";
import {
    loadAdminProjectSelectboxRequest,
    loadPublicProjectTeamListRequest,
} from "../../../xhr/ProjectRequests";

interface IOwnProps {
    mandant: IMandant;
}

const InitialImageRef: IDocumentRef = {
    key: "",
    type: DocumentTypeEnum.IMAGE,
};

const InitialDocumentRef: IDocumentRef = {
    key: "",
    type: DocumentTypeEnum.FILE,
};

const InitialTeamRef: ITeamRef = {
    id: "",
};

const InitialFaqRef: IQuestionRef = {
    id: "",
    question: "",
    answers: [],
};
const InitialResponseRef: IResponse = {
    id: "",
    content: ``,
};

// @ts-ignore
const initialColValue: { [key: string]: ICmsPageElement } = {
    header_1: {
        type: CmsPageElementTypeEnum.HEADER_1,
        content: "",
    },
    header_2: {
        type: CmsPageElementTypeEnum.HEADER_2,
        content: "",
    },
    header_3: {
        type: CmsPageElementTypeEnum.HEADER_3,
        content: "",
    },
    html: {
        type: CmsPageElementTypeEnum.HTML,
        content: "",
    },
    html_text_text: {
        type: CmsPageElementTypeEnum.HTML_TEXT_TEXT,
        content: ["", ""],
    },
    html_text_text_text: {
        type: CmsPageElementTypeEnum.HTML_TEXT_TEXT_TEXT,
        content: ["", "", ""],
    },
    html_text_image: {
        type: CmsPageElementTypeEnum.HTML_TEXT_IMAGE,
        content: ["", { ...InitialImageRef, type: DocumentTypeEnum.IMAGE }],
    },
    html_image_text: {
        type: CmsPageElementTypeEnum.HTML_IMAGE_TEXT,
        content: [{ ...InitialImageRef, type: DocumentTypeEnum.IMAGE }, ""],
    },
    html_image: {
        type: CmsPageElementTypeEnum.HTML_IMAGE,
        content: [{ ...InitialImageRef, type: DocumentTypeEnum.IMAGE }],
    },
    html_image_image: {
        type: CmsPageElementTypeEnum.HTML_IMAGE_IMAGE,
        content: [
            { ...InitialImageRef, type: DocumentTypeEnum.IMAGE },
            { ...InitialImageRef, type: DocumentTypeEnum.IMAGE },
        ],
    },
    html_image_image_image: {
        type: CmsPageElementTypeEnum.HTML_IMAGE_IMAGE_IMAGE,
        content: [
            { ...InitialImageRef, type: DocumentTypeEnum.IMAGE },
            { ...InitialImageRef, type: DocumentTypeEnum.IMAGE },
            { ...InitialImageRef, type: DocumentTypeEnum.IMAGE },
        ],
    },
    download_list: {
        type: CmsPageElementTypeEnum.DOWNLOAD_LIST,
        content: [[]],
    },
    team_list: {
        type: CmsPageElementTypeEnum.TEAM_LIST,
        content: [],
    },
    faq_list: {
        type: CmsPageElementTypeEnum.FAQ_LIST,
        content: [],
    },
    calculator: {
        type: CmsPageElementTypeEnum.CALCULATOR,
        content: [],
    },
};

const CmsPageForm = (props: IOwnProps) => {
    const { mandant } = props;
    const history = useHistory();
    const confirm = useConfirm();
    const [open, setOpen] = React.useState<string | number>(-1);

    const { pageId }: { pageId: any } = useParams<{
        pageId: any;
    }>();

    const [refreshMode, setRefreshMode] = React.useState<boolean>(false);

    const [isEditForm, setIsEditForm] = React.useState<boolean>(!isNaN(pageId));

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    /**
     * The form data
     */
    const [pageData, setPageData] = React.useState<ICmsPage>({
        ...InitialCmsPageData,
    });

    const [images, setImages] = React.useState<{ [key: string]: IDocument }>(
        {}
    );

    const [uploads, setUploads] = React.useState<{ [key: string]: IDocument }>(
        {}
    );

    const [projectList, setProjectList] = React.useState<ISelectOption[]>([]);

    const [teamList, setTeamList] = React.useState<{ [key: string]: ITeam }>(
        {}
    );

    /****************Handle sort state ****************/
    const [sortingActive, setSortingActive] = React.useState<any>({
        downloadList: {},
        teamList: {},
    });
    const [downloadList, setDownloadList] = React.useState<any>({});
    const [sortedTeamList, setSortedTeamList] = React.useState<any>({});
    const [currentElementToSortIndex, setCurrentElementToSortIndex] =
        React.useState<any>({
            downloadList: undefined,
            teamList: undefined,
        });

    /**
     * Snackkbar related states
     */
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });
    const [changesNotSavedState, setChangesNotSavedState] =
        React.useState<boolean>(false);

    const closeChangesNotSavedWarning = () => {
        setChangesNotSavedState(false);
    };
    const openChangesNotSavedWarning = () => {
        setChangesNotSavedState(true);
    };

    React.useEffect(() => {
        if (refreshMode) {
            setRefreshMode(false);
        }
    }, [refreshMode]);

    React.useEffect(() => {
        if (isEditForm) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            loadDataFromServer(pageId);
        } else {
            // if we create an event we need at least the project
            setPageData({
                ...pageData,
            });
        }
    }, [pageId]);

    /**
     *
     */
    React.useEffect(() => {
        loadAdminProjectSelectboxRequest(mandant.key).then((response) => {
            setProjectList(response.data);
        });
    }, [mandant]);

    /**
     */
    React.useEffect(() => {
        if (pageData.data.project?.id) {
            loadPublicProjectTeamListRequest(
                mandant.key,
                "" + pageData.data.project?.id
            ).then((response) => {
                setTeamList(response.data);
            });
        } else {
            setTeamList({});
        }
    }, [pageData.data.project?.id]);

    // Load downloaded files to downloadList state from pageData
    // Load team list to sorted team list state from team list
    React.useEffect(() => {
        // Generate new data structure for download list to handle sorting by element index
        const downloadListEntryData: any = pageData.elements.reduce(
            (acc: any, el: any, index: number) => {
                if (el.type === "download_list") {
                    acc[index] = { ...el, content: el.content[0] };
                }
                return acc;
            },
            {}
        );

        // Generate new data structure for sorted team list to handle sorting by element index
        const teamListEntryData: any = pageData.elements.reduce(
            (acc: any, el: any, index: number) => {
                if (el.type === "team_list") {
                    acc[index] = el;
                }
                return acc;
            },
            {}
        );

        if (downloadListEntryData) {
            setDownloadList(downloadListEntryData);
        }
        if (teamListEntryData) {
            setSortedTeamList(teamListEntryData);
        }
    }, [pageData]);

    const loadDataFromServer = (loadPageId: number) => {
        setIsLoading(true);

        loadCmsPageRequest(mandant.key, loadPageId)
            .then((response) => {
                setPageData({
                    data: response.data.data,
                    elements: response.data.elements,
                });

                if (Array.isArray(response.data.images)) {
                    const newImages: { [key: string]: IDocument } = {};
                    response.data.images.map((img: IDocument) => {
                        newImages[img.key + ""] = img;
                    });
                    setImages(newImages);
                } else {
                    setImages(response.data.images);
                }

                if (Array.isArray(response.data.uploads)) {
                    const newUploads: { [key: string]: IDocument } = {};
                    response.data.uploads.map((upl: IDocument) => {
                        newUploads[upl.key + ""] = upl;
                    });
                    setUploads(newUploads);
                } else {
                    setUploads(response.data.uploads);
                }

                setIsLoading(false);
                closeChangesNotSavedWarning();
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Seite ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setIsLoading(false);
            });
    };

    const handleSave = () => {
        setIsLoading(true);

        if (isEditForm) {
            updateCmsPageRequest(mandant.key, pageData.data.id, {
                ...pageData.data,
                flagShowTimelineHeader:
                    pageData.data.flagShowTimelineHeader || false,
                content: pageData.elements,
                images,
                uploads,
            })
                .then((response) => {
                    //setLoading(false);

                    setPageData({
                        data: response.data.data,
                        elements: response.data.elements,
                    });

                    if (Array.isArray(response.data.images)) {
                        const newImages: { [key: string]: IDocument } = {};
                        response.data.images.map((img: IDocument) => {
                            newImages[img.key + ""] = img;
                        });
                        setImages(newImages);
                    } else {
                        setImages(response.data.images);
                    }

                    if (Array.isArray(response.data.uploads)) {
                        const newUploads: { [key: string]: IDocument } = {};
                        response.data.uploads.map((upl: IDocument) => {
                            newUploads[upl.key + ""] = upl;
                        });
                        setUploads(newUploads);
                    } else {
                        setUploads(response.data.uploads);
                    }

                    setSnackBarState({
                        isOpen: true,
                        message: "Die Seite wurde erfolgreich gespeichert.",
                        type: "success",
                    });
                    setIsLoading(false);
                    closeChangesNotSavedWarning();
                })
                .catch((error) => {
                    console.log("error", { error });
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Beim Speichern der Seite ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        } else {
            createCmsPageRequest(mandant.key, {
                ...pageData.data,
                flagShowTimelineHeader:
                    pageData.data.flagShowTimelineHeader || false,
                content: pageData.elements,
                images,
                uploads,
            })
                .then((response: any) => {
                    //setLoading(false);
                    setPageData({
                        data: response.data.data,
                        elements: response.data.elements,
                    });

                    if (Array.isArray(response.data.images)) {
                        const newImages: { [key: string]: IDocument } = {};
                        response.data.images.map((img: IDocument) => {
                            newImages[img.key + ""] = img;
                        });
                        setImages(newImages);
                    } else {
                        setImages(response.data.images);
                    }

                    if (Array.isArray(response.data.uploads)) {
                        const newUploads: { [key: string]: IDocument } = {};
                        response.data.uploads.map((upl: IDocument) => {
                            newUploads[upl.key + ""] = upl;
                        });
                        setUploads(newUploads);
                    } else {
                        setUploads(response.data.uploads);
                    }

                    setIsEditForm(true);
                    setSnackBarState({
                        isOpen: true,
                        message: "Die Seite wurde erfolgreich erstellt.",
                        type: "success",
                    });
                    setIsLoading(false);
                    closeChangesNotSavedWarning();
                })
                .catch(() => {
                    //setLoading(false);
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Beim Erstellen der Seite ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        }
    };

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const onDeleteRow = (index: number) => {
        pageData.elements.splice(index, 1);
        setPageData(deepCopy<ICmsPage>(pageData));
    };

    const movePageSectionUp = (index: number) => {
        const varReplace = pageData.elements[index - 1];

        pageData.elements[index - 1] = pageData.elements[index];
        pageData.elements[index] = varReplace;

        setPageData(deepCopy<ICmsPage>(pageData));
        setRefreshMode(true);
        openChangesNotSavedWarning();
    };

    const movePageSectionDown = (index: number) => {
        const varReplace = pageData.elements[index + 1];

        pageData.elements[index + 1] = pageData.elements[index];
        pageData.elements[index] = varReplace;

        setPageData(deepCopy<ICmsPage>(pageData));
        setRefreshMode(true);
        openChangesNotSavedWarning();
    };

    /*
    Handle generic change
   */
    const handleTextChange = (event: any) => {
        const newData = {
            ...pageData.data,
            [event.target.name]: event.target.value,
        };
        setPageData({ ...pageData, data: newData });
        openChangesNotSavedWarning();
    };

    const handleCheckboxChange = (event: any) => {
        const newData = {
            ...pageData.data,
            [event.target.name]: event.target.checked,
        };
        setPageData({ ...pageData, data: newData });
        openChangesNotSavedWarning();
    };

    const changeProject = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newData = {
            ...pageData.data,
            project: { id: event.target.value as number },
        };

        setPageData({ ...pageData, data: newData });
        openChangesNotSavedWarning();
    };

    const changeTeam = (index: number, subIndex: number, value: string) => {
        // @ts-ignore
        pageData.elements[index].content[subIndex].id = value;
        setPageData(deepCopy(pageData));
        openChangesNotSavedWarning();
    };

    /*   const handleImageChange = (image: IDocument, attrKey: string) => {
        const newData = { ...pageData, [attrKey]: image };
        setPageData(newData);
    };

    const handleWysiwygChange = (editorState: any, name: string) => {
        const rawContentState = draft.convertToRaw(
            editorState.getCurrentContent()
        );
        const markup = draftToHtml(rawContentState);
        const newData = { ...pageData, [name]: markup };
        setPageData(newData);
    }; */

    /*  const handleDateChange = (date: Date, attrKey: string) => {
        setPageData({
            ...pageData,
            [attrKey]: moment(date).format(Format.dateTimeEn),
        });
    }; */

    const addNewPageElement = (
        event: React.SyntheticEvent<HTMLButtonElement>
    ) => {
        const type = "" + event.currentTarget.dataset.type;

        const newCol: ICmsPageElement = deepCopy(
            initialColValue["" + event.currentTarget.dataset.type]
        );

        if (type === CmsPageElementTypeEnum.HTML_IMAGE_TEXT) {
            const newRef = uuid();
            images[newRef] = { ...InitialDocumentData, key: newRef, type: 1 };
            setImages(deepCopy(images));
            // @ts-ignore
            newCol.content[0] = { ...InitialImageRef, key: newRef };
        } else if (type === CmsPageElementTypeEnum.HTML_TEXT_IMAGE) {
            const newRef = uuid();
            images[newRef] = { ...InitialDocumentData, key: newRef, type: 1 };
            setImages(deepCopy(images));
            // @ts-ignore
            newCol.content[1] = { ...InitialImageRef, key: newRef };
        } else if (type === CmsPageElementTypeEnum.HTML_IMAGE) {
            const newRef = uuid();
            images[newRef] = { ...InitialDocumentData, key: newRef, type: 1 };
            setImages(deepCopy(images));
            // @ts-ignore
            newCol.content[0] = { ...InitialImageRef, key: newRef };
        } else if (type === CmsPageElementTypeEnum.HTML_IMAGE_IMAGE) {
            const newRef = uuid();
            images[newRef] = { ...InitialDocumentData, key: newRef, type: 1 };
            setImages(deepCopy(images));
            // @ts-ignore
            newCol.content[0] = { ...InitialImageRef, key: newRef };

            const newRef2 = uuid();
            images[newRef2] = { ...InitialDocumentData, key: newRef2, type: 1 };
            setImages(deepCopy(images));
            // @ts-ignore
            newCol.content[1] = { ...InitialImageRef, key: newRef2 };
        } else if (type === CmsPageElementTypeEnum.HTML_IMAGE_IMAGE_IMAGE) {
            const newRef = uuid();
            images[newRef] = { ...InitialDocumentData, key: newRef, type: 1 };
            setImages(deepCopy(images));
            // @ts-ignore
            newCol.content[0] = { ...InitialImageRef, key: newRef };

            const newRef2 = uuid();
            images[newRef2] = { ...InitialDocumentData, key: newRef2, type: 1 };
            setImages(deepCopy(images));
            // @ts-ignore
            newCol.content[1] = { ...InitialImageRef, key: newRef2 };

            const newRef3 = uuid();
            images[newRef3] = { ...InitialDocumentData, key: newRef3, type: 1 };
            setImages(deepCopy(images));
            // @ts-ignore
            newCol.content[2] = { ...InitialImageRef, key: newRef3 };
        } else if (type === CmsPageElementTypeEnum.DOWNLOAD_LIST) {
            const newRef = uuid();
            //uploads[newRef] = { ...InitialDocumentData, key: newRef, type: 1 };

            setUploads(
                deepCopy({
                    ...uploads,
                    [newRef]: { ...InitialDocumentData, key: newRef, type: 2 },
                })
            );
            // @ts-ignore
            newCol.content[0].push({ ...InitialDocumentRef, key: newRef });
        }

        pageData.elements.push(newCol);
        setPageData(deepCopy<ICmsPage>(pageData));
        openChangesNotSavedWarning();
    };

    const addAdditionalFile = (index: number) => {
        const newRef = uuid();
        setUploads(
            deepCopy({
                ...uploads,
                [newRef]: { ...InitialDocumentData, key: newRef, type: 2 },
            })
        );
        // @ts-ignore
        pageData.elements[index].content[0].push({
            ...InitialDocumentRef,
            key: newRef,
        });
        setPageData(deepCopy<ICmsPage>(pageData));
        openChangesNotSavedWarning();
    };
    const addAdditionalTeam = (index: number) => {
        // @ts-ignore
        pageData.elements[index].content.push({
            ...InitialTeamRef,
        });
        setPageData(deepCopy<ICmsPage>(pageData));
        openChangesNotSavedWarning();
    };

    const handleContentTextChange = (
        event: any,
        index: number,
        index2?: number
    ) => {
        if (index2 !== undefined) {
            // @ts-ignore
            pageData.elements[index].content[index2] = event.target.value;
        } else {
            pageData.elements[index].content = event.target.value;
        }

        setPageData(deepCopy<ICmsPage>(pageData));
        openChangesNotSavedWarning();
    };

    const handleFaqContentTextChange = (
        event: any,
        field: string,
        index: number,
        index2: number
    ) => {
        // @ts-ignore
        if (Array.isArray(pageData.elements[index].content[index2][field])) {
            // @ts-ignore
            pageData.elements[index].content[index2][field].push(
                event.target.value
            );
        } else {
            // @ts-ignore
            pageData.elements[index].content[index2][field] =
                event.target.value;
        }

        setPageData(deepCopy<ICmsPage>(pageData));
        openChangesNotSavedWarning();
    };
    const handleContentWysiwygChange = (
        editorState: any,
        index: number,
        index2?: number
    ) => {
        const rawContentState = draft.convertToRaw(
            editorState.getCurrentContent()
        );

        if (index2 !== undefined) {
            // @ts-ignore
            pageData.elements[index].content[index2] = draftToHtml(
                getEditorStateContent(editorState, rawContentState)
            );
        } else {
            pageData.elements[index].content = draftToHtml(
                getEditorStateContent(editorState, rawContentState)
            );
        }

        setPageData(deepCopy<ICmsPage>(pageData));
        openChangesNotSavedWarning();
    };

    const handleFaqContentWysiwygChange = (
        editorState: any,
        field: string,
        index: number,
        index2: number,
        index3: number
    ) => {
        const rawContentState = draft.convertToRaw(
            editorState.getCurrentContent()
        );
        // @ts-ignore
        if (Array.isArray(pageData.elements[index].content[index2][field])) {
            // @ts-ignore
            pageData.elements[index].content[index2][field].splice(index3, 1, {
                // @ts-ignore
                ...pageData.elements[index].content[index2][field][index3],
                content: draftToHtml(rawContentState),
            });
        } else {
            // @ts-ignore
            pageData.elements[index].content[index2][field] =
                draftToHtml(rawContentState);
        }

        setPageData(deepCopy<ICmsPage>(pageData));
        openChangesNotSavedWarning();
    };

    const handleImageChange = (image: IDocument) => {
        setImages({ ...images, ["" + image.key]: image });
        openChangesNotSavedWarning();
    };

    const handleUploadChange = (upload: Partial<IDocument>) => {
        setUploads({ ...uploads, ["" + upload.key]: upload as IDocument });
        openChangesNotSavedWarning();
    };

    function handleBread(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    const addAdditionalFaq = (index: number) => {
        const newRef = uuid();
        // @ts-ignore
        pageData.elements[index].content.push({
            ...InitialFaqRef,
            id: newRef,
            answers: [
                ...InitialFaqRef.answers,
                { ...InitialResponseRef, id: 1 + newRef },
            ],
        });
        setOpen(newRef);
        setPageData(deepCopy<ICmsPage>(pageData));
        openChangesNotSavedWarning();
    };

    const addAdditionalResponse = (index: number, faqIndex: number) => {
        const newRef = uuid();
        // @ts-ignore
        pageData.elements[index].content[faqIndex].answers.push({
            ...InitialResponseRef,
            id: 1 + newRef,
        });
        setPageData(deepCopy<ICmsPage>(pageData));
        openChangesNotSavedWarning();
    };

    const onDeleteFaq = (index: number) => {
        if (Array.isArray(pageData.elements[1].content)) {
            pageData.elements[1].content.splice(index, 1);
        }
        setPageData(deepCopy<ICmsPage>(pageData));
        openChangesNotSavedWarning();
    };
    const onDeleteResponse = (
        index: number,
        faqIndex: number,
        responseIndex: number
    ) => {
        // @ts-ignore
        if (
            Array.isArray(pageData.elements[index].content) &&
            // @ts-ignore
            Array.isArray(pageData.elements[index].content[faqIndex].answers)
        ) {
            // @ts-ignore
            pageData.elements[index].content[faqIndex].answers.splice(
                responseIndex,
                1
            );
        }
        setPageData(deepCopy<ICmsPage>(pageData));
        openChangesNotSavedWarning();
    };
    const headerSection = (
        index: number,
        additionalButtons?: any,
        disabled?: boolean
    ) => {
        return (
            <Toolbar disableGutters={true}>
                {index > 0 && (
                    <Tooltip title="Lass dich fallen">
                        <IconButton
                            onClick={() => movePageSectionUp(index)}
                            color="primary"
                            disabled={disabled}
                        >
                            <ArrowDropUpIcon />
                        </IconButton>
                    </Tooltip>
                )}

                {pageData.elements.length - 1 !== index && (
                    <Tooltip title="Runterfallen">
                        <IconButton
                            onClick={() => movePageSectionDown(index)}
                            color="primary"
                            disabled={disabled}
                        >
                            <ArrowDropDownIcon />
                        </IconButton>
                    </Tooltip>
                )}

                {additionalButtons !== undefined && additionalButtons}
                <Tooltip title="Löschen">
                    <IconButton
                        onClick={() => onDeleteRow(index)}
                        color="primary"
                        disabled={disabled}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        );
    };

    const header_1 = (index: number, element: ICmsPageElement) => {
        return (
            <Grid item xs={12} key={index}>
                <div className="aligned-header">
                    <h5>Überschrift 1</h5>
                    {headerSection(index)}
                </div>
                <TextField
                    variant="outlined"
                    label="Header 1"
                    value={element.content ? element.content : ""}
                    onChange={(event: any) => {
                        handleContentTextChange(event, index);
                    }}
                    fullWidth
                    required={true}
                />
            </Grid>
        );
    };

    const header_2 = (index: number, element: ICmsPageElement) => {
        return (
            <Grid item xs={12} key={index}>
                <div className="aligned-header">
                    <h5>Überschrift 2</h5>
                    {headerSection(index)}
                </div>
                <TextField
                    variant="outlined"
                    label="Header 2"
                    value={element.content ? element.content : ""}
                    onChange={(event: any) => {
                        handleContentTextChange(event, index);
                    }}
                    fullWidth
                    required={true}
                />
            </Grid>
        );
    };

    const header_3 = (index: number, element: ICmsPageElement) => {
        return (
            <Grid item xs={12} key={index}>
                <div className="aligned-header">
                    <h5>Überschrift 3</h5>
                    {headerSection(index)}
                </div>
                <TextField
                    variant="outlined"
                    label="Header 3"
                    value={element.content ? element.content : ""}
                    onChange={(event: any) => {
                        handleContentTextChange(event, index);
                    }}
                    fullWidth
                    required={true}
                />
            </Grid>
        );
    };

    const html = (index: number, element: ICmsPageElement) => {
        return (
            <Grid item xs={12} key={index}>
                <div className="aligned-header">
                    <h5>Text</h5>
                    {headerSection(index)}
                </div>
                <TextEdit
                    handleChange={(editorState) => {
                        handleContentWysiwygChange(editorState, index);
                    }}
                    title="Seiten Inhalt"
                    defaultValue={element.content ? element.content : ""}
                />
            </Grid>
        );
    };

    const html_text_text = (index: number, element: ICmsPageElement) => {
        const content = element.content as string[];

        return (
            <React.Fragment key={index}>
                <Grid item xs={12} className="aligned-header">
                    <h5>Text|Text</h5>
                    {headerSection(index)}
                </Grid>
                <Grid item xs={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 0);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[0] ? content[0] : ""}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 1);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[1] ? content[1] : ""}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const html_text_text_text = (index: number, element: ICmsPageElement) => {
        const content = element.content as string[];

        return (
            <React.Fragment key={index}>
                <Grid item xs={12} className="aligned-header">
                    <h5>Text|Text|Text</h5>
                    {headerSection(index)}
                </Grid>
                <Grid item xs={4}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 0);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[0] ? content[0] : ""}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 1);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[1] ? content[1] : ""}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 2);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[2] ? content[2] : ""}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const html_text_image = (index: number, element: ICmsPageElement) => {
        const content = element.content as string[] | IDocumentRef[];

        const imgRef: IDocumentRef = content[1] as IDocumentRef;
        const img = images[imgRef.key]
            ? (images[imgRef.key] as IDocument)
            : {
                  ...InitialDocumentData,
                  key: imgRef.key,
                  type: 1,
              };

        return (
            <React.Fragment key={index}>
                <Grid item xs={12} className="aligned-header">
                    <h5>Text|Bild</h5>
                    {headerSection(index)}
                </Grid>
                <Grid item xs={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 0);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[0] ? content[0] : ""}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ImageUpload
                        onChange={handleImageChange}
                        docData={img}
                        name={imgRef.key}
                        label={"Bild"}
                        defaultFilename="Bild.jpg"
                        hasFreeScale
                        withSizeCheck
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const html_image_text = (index: number, element: ICmsPageElement) => {
        const content = element.content as string[] | IDocumentRef[];

        const imgRef: IDocumentRef = content[0] as IDocumentRef;
        const img = images[imgRef.key]
            ? (images[imgRef.key] as IDocument)
            : {
                  ...InitialDocumentData,
                  key: imgRef.key,
                  type: 1,
              };

        return (
            <React.Fragment key={index}>
                <Grid item xs={12} className="aligned-header">
                    <h5>Bild|Text</h5>
                    {headerSection(index)}
                </Grid>

                <Grid item xs={6}>
                    <ImageUpload
                        onChange={handleImageChange}
                        docData={img}
                        name={imgRef.key}
                        label={"Bild"}
                        defaultFilename="Bild.jpg"
                        hasFreeScale
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 1);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[1] ? content[1] : ""}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const html_image = (index: number, element: ICmsPageElement) => {
        const content = element.content as string[] | IDocumentRef[];

        const imgRef: IDocumentRef = content[0] as IDocumentRef;
        const img = images[imgRef.key]
            ? (images[imgRef.key] as IDocument)
            : {
                  ...InitialDocumentData,
                  key: imgRef.key,
                  type: 1,
              };

        return (
            <React.Fragment key={index}>
                <Grid item xs={12} className="aligned-header">
                    <h5>Bild</h5>
                    {headerSection(index)}
                </Grid>

                <Grid item xs={12}>
                    <ImageUpload
                        onChange={handleImageChange}
                        docData={img}
                        name={imgRef.key}
                        label={"Bild"}
                        defaultFilename="Bild.jpg"
                        hasFreeScale
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const html_image_image = (index: number, element: ICmsPageElement) => {
        const content = element.content as string[] | IDocumentRef[];

        const imgRef: IDocumentRef = content[0] as IDocumentRef;
        const img = images[imgRef.key]
            ? (images[imgRef.key] as IDocument)
            : {
                  ...InitialDocumentData,
                  key: imgRef.key,
                  type: 1,
              };

        const imgRef2: IDocumentRef = content[1] as IDocumentRef;
        const img2 = images[imgRef2.key]
            ? (images[imgRef2.key] as IDocument)
            : {
                  ...InitialDocumentData,
                  key: imgRef2.key,
                  type: 1,
              };

        return (
            <React.Fragment key={index}>
                <Grid item xs={12} className="aligned-header">
                    <h5>Bild|Bild</h5>
                    {headerSection(index)}
                </Grid>

                <Grid item xs={6}>
                    <ImageUpload
                        onChange={handleImageChange}
                        docData={img}
                        name={imgRef.key}
                        label={"Bild"}
                        defaultFilename="Bild.jpg"
                        hasFreeScale
                    />
                </Grid>

                <Grid item xs={6}>
                    <ImageUpload
                        onChange={handleImageChange}
                        docData={img2}
                        name={imgRef2.key}
                        label={"Bild2"}
                        defaultFilename="Bild2.jpg"
                        hasFreeScale
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const html_image_image_image = (
        index: number,
        element: ICmsPageElement
    ) => {
        const content = element.content as string[] | IDocumentRef[];

        const imgRef: IDocumentRef = content[0] as IDocumentRef;
        const img = images[imgRef.key]
            ? (images[imgRef.key] as IDocument)
            : {
                  ...InitialDocumentData,
                  key: imgRef.key,
                  type: 1,
              };

        const imgRef2: IDocumentRef = content[1] as IDocumentRef;
        const img2 = images[imgRef2.key]
            ? (images[imgRef2.key] as IDocument)
            : {
                  ...InitialDocumentData,
                  key: imgRef2.key,
                  type: 1,
              };

        const imgRef3: IDocumentRef = content[2] as IDocumentRef;
        const img3 = images[imgRef3.key]
            ? (images[imgRef3.key] as IDocument)
            : {
                  ...InitialDocumentData,
                  key: imgRef3.key,
                  type: 1,
              };

        return (
            <React.Fragment key={index}>
                <Grid item xs={12} className="aligned-header">
                    <h5>Bild|Bild|Bild</h5>
                    {headerSection(index)}
                </Grid>

                <Grid item xs={4}>
                    <ImageUpload
                        onChange={handleImageChange}
                        docData={img}
                        name={imgRef.key}
                        label={"Bild"}
                        defaultFilename="Bild.jpg"
                        hasFreeScale
                    />
                </Grid>

                <Grid item xs={4}>
                    <ImageUpload
                        onChange={handleImageChange}
                        docData={img2}
                        name={imgRef2.key}
                        label={"Bild2"}
                        defaultFilename="Bild2.jpg"
                        hasFreeScale
                    />
                </Grid>

                <Grid item xs={4}>
                    <ImageUpload
                        onChange={handleImageChange}
                        docData={img3}
                        name={imgRef3.key}
                        label={"Bild3"}
                        defaultFilename="Bild3.jpg"
                        hasFreeScale
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const clearCurrentElementToSortState = (type: string) => {
        setCurrentElementToSortIndex((prevState: any) => ({
            ...prevState,
            [type]: undefined,
        }));
    };
    const toggleSortingAction = (type: string, index: number) => {
        setSortingActive((prevState: any) => ({
            ...prevState,
            [type]: {
                ...prevState[type],
                [index]: !prevState[type][index],
            },
        }));
    };

    const handleSorting = (type: string, index: number) => {
        // Start sorting
        toggleSortingAction(type, index);
        // Save the index of the element to sort
        setCurrentElementToSortIndex((prevState: any) => ({
            ...prevState,
            [type]: index,
        }));

        // Push notification when the sort ends : the user should save the data to not loose the changes
        if (sortingActive[type][index]) {
            setSnackBarState({
                isOpen: true,
                message:
                    "Die Daten wurden erfolgreich sortiert. Bitte speichern Sie sie, damit die Änderungen nicht verloren gehen",
                type: "success",
            });

            // Copy page data state
            const pageDataCopy = deepCopy(pageData);

            // Update pageData state with sorted data
            if (type === "downloadList") {
                for (const [key, value] of Object.entries<{
                    [key: string]: any;
                }>(downloadList)) {
                    const numericKey: number = parseInt(key, 10);
                    pageDataCopy.elements[numericKey] = {
                        ...value,
                        content: [value.content],
                    } as ICmsPageElement;
                }
            }
            if (type === "teamList") {
                for (const [key, value] of Object.entries<{
                    [key: string]: any;
                }>(sortedTeamList)) {
                    const numericKey: number = parseInt(key, 10);
                    pageDataCopy.elements[numericKey] =
                        value as ICmsPageElement;
                }
            }

            setPageData({ ...pageDataCopy });

            clearCurrentElementToSortState(type);
        }
    };

    const onSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        // Create a copy of the downloadList array
        const data: any =
            currentElementToSortIndex.downloadList &&
            sortingActive &&
            sortingActive.downloadList[currentElementToSortIndex.downloadList]
                ? deepCopy(
                      downloadList[currentElementToSortIndex.downloadList]
                          .content
                  )
                : currentElementToSortIndex.teamList &&
                  sortingActive.teamList[currentElementToSortIndex.teamList]
                ? deepCopy(
                      sortedTeamList[currentElementToSortIndex.teamList].content
                  )
                : [];

        const updatedList = data ? [...data] : [];
        const arry = arrayMove(updatedList, oldIndex, newIndex);

        // Use the arrayMove function or other suitable sorting logic to update the array
        // const movedItem = updatedList.splice(oldIndex, 1)[0];
        // updatedList.splice(newIndex, 0, movedItem);
        // Update the state with the sorted array
        if (sortingActive.downloadList) {
            setDownloadList((prevState: any) => ({
                ...prevState,
                [currentElementToSortIndex.downloadList]: {
                    ...prevState[currentElementToSortIndex.downloadList],
                    ["content"]: arry,
                },
            }));
        }
        if (sortingActive.teamList) {
            setSortedTeamList((prevState: any) => ({
                ...prevState,
                [currentElementToSortIndex.teamList]: {
                    ...prevState[currentElementToSortIndex.teamList],
                    ["content"]: arry,
                },
            }));
        }
    };

    const SortableItem = SortableElement(
        ({ value, index }: { value: any; index: any }) => {
            if (
                currentElementToSortIndex &&
                sortingActive.teamList[currentElementToSortIndex.teamList]
            ) {
                return (
                    <Grid
                        item
                        style={{ margin: "8px 0" }}
                        className="sorting-item"
                    >
                        {teamList[value.id].displayTitle && (
                            <Typography color="textPrimary">
                                {teamList[value.id].displayTitle}{" "}
                                {teamList[value.id].name}
                            </Typography>
                        )}
                    </Grid>
                );
            }
            if (
                currentElementToSortIndex &&
                sortingActive.downloadList[
                    currentElementToSortIndex.downloadList
                ]
            ) {
                return (
                    <Grid
                        item
                        style={{ margin: "8px 0" }}
                        className="sorting-item"
                    >
                        {uploads[value.key].title && (
                            <Typography color="textPrimary">
                                {uploads[value.key].title}{" "}
                                {uploads[value.key].name}
                            </Typography>
                        )}
                    </Grid>
                );
            }
            return <></>;
        }
    );

    // eslint-disable-next-line no-shadow
    const SortableList = SortableContainer((props: any) => {
        const { items }: { items: any[] } = props;
        return (
            <Grid
                container
                direction="column"
                spacing={2}
                style={{ margin: "8px" }}
            >
                {items ? (
                    items.map((file, fileIndex) => {
                        return (
                            <SortableItem
                                value={file}
                                index={fileIndex}
                                key={file.key}
                            />
                        );
                    })
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        );
    });
    const download_list = (index: number, element: ICmsPageElement) => {
        // @ts-ignore
        const content = element.content[0] as IDocumentRef[];
        const dataSource: any =
            downloadList &&
            currentElementToSortIndex.downloadList &&
            downloadList[currentElementToSortIndex.downloadList]
                ? // @ts-ignore
                  downloadList[currentElementToSortIndex.downloadList].content
                : downloadList[index]
                ? downloadList[index].content
                : content;
        return (
            <React.Fragment key={index}>
                <Grid item xs={12} className="aligned-header">
                    <h5>Downloads</h5>
                    {headerSection(
                        index,
                        [
                            <IconButton
                                key="add-file"
                                onClick={() => addAdditionalFile(index)}
                                color="primary"
                                disabled={sortingActive.downloadList[index]}
                            >
                                <AddIcon />
                            </IconButton>,
                        ],
                        sortingActive.downloadList[index]
                    )}
                    <IconButton
                        key="download-team-list"
                        onClick={() => handleSorting("downloadList", index)}
                        color="primary"
                        title={
                            sortingActive.downloadList[index]
                                ? "Sortierung deaktivieren"
                                : "Sortierung aktivieren"
                        }
                        disabled={Boolean(
                            (downloadList &&
                                downloadList[index] &&
                                downloadList[index].content.length < 2) ||
                                Object.values(currentElementToSortIndex).some(
                                    (el) => el !== undefined && el !== index
                                )
                        )}
                    >
                        {sortingActive.downloadList[index] ? (
                            <SyncDisabledIcon />
                        ) : (
                            <SyncIcon />
                        )}
                    </IconButton>
                </Grid>
                {sortingActive.downloadList[index] && (
                    <SortableList
                        items={dataSource}
                        onSortEnd={onSortEnd}
                        axis="xy"
                    />
                )}
                {!sortingActive.downloadList[index] &&
                    dataSource.map((docRef: any, fileIndex: number) => {
                        return (
                            <Grid item xs={6} key={fileIndex}>
                                <FileUpload
                                    onChange={handleUploadChange}
                                    docData={uploads[docRef.key] as IDocument}
                                    name={fileIndex}
                                    label={"Datei"}
                                    defaultFilename="data.dat"
                                />
                            </Grid>
                        );
                    })}
            </React.Fragment>
        );
    };
    const team_list = (index: number, element: ICmsPageElement) => {
        // @ts-ignore
        const content = element.content as ITeamRef[];
        const dataSource: any =
            sortedTeamList &&
            currentElementToSortIndex.teamList &&
            sortedTeamList[currentElementToSortIndex.teamList]
                ? // @ts-ignore
                  sortedTeamList[currentElementToSortIndex.teamList].content
                : sortedTeamList[index]
                ? sortedTeamList[index].content
                : content;

        const teamListSorted = Object.values(teamList).sort((left, right) => {
            if (
                "" + left.displayTitle + "-" + left.name >
                "" + right.displayTitle + "-" + right.name
            ) {
                return 1;
            } else if (
                "" + left.displayTitle + "-" + left.name ===
                "" + right.displayTitle + "-" + right.name
            ) {
                return 0;
            } else {
                return -1;
            }
        });

        return (
            <React.Fragment key={index}>
                <Grid item xs={12} className="aligned-header">
                    <h5>Teammitglieder</h5>
                    {headerSection(
                        index,
                        [
                            <IconButton
                                key="add-file"
                                onClick={() => addAdditionalTeam(index)}
                                color="primary"
                                disabled={sortingActive.teamList[index]}
                            >
                                <AddIcon />
                            </IconButton>,
                        ],
                        sortingActive.teamList[index]
                    )}
                    <IconButton
                        key="sorting-team-list"
                        onClick={() => handleSorting("teamList", index)}
                        color="primary"
                        title={
                            sortingActive.teamList[index]
                                ? "Sortierung deaktivieren"
                                : "Sortierung aktivieren"
                        }
                        disabled={
                            (sortedTeamList &&
                                sortedTeamList[index] &&
                                sortedTeamList[index].content.length < 2) ||
                            Object.values(currentElementToSortIndex).some(
                                (el) => el !== undefined && el !== index
                            )
                        }
                    >
                        {sortingActive.teamList[index] ? (
                            <SyncDisabledIcon />
                        ) : (
                            <SyncIcon />
                        )}
                    </IconButton>
                </Grid>
                {sortingActive.teamList[index] && (
                    <SortableList
                        items={dataSource}
                        onSortEnd={onSortEnd}
                        axis="xy"
                        specificProp={index}
                    />
                )}

                {!sortingActive.teamList[index] &&
                    dataSource.map((teamRef: any, fileIndex: any) => {
                        // @ts-ignore
                        return (
                            <Grid item xs={6} key={index + "-" + fileIndex}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth={true}
                                >
                                    <InputLabel
                                        id={"team-select-label" + teamRef.id}
                                    >
                                        Teammitglied
                                    </InputLabel>
                                    <Select
                                        labelId={
                                            "team-select-label" + teamRef.id
                                        }
                                        value={"" + teamRef.id}
                                        onChange={(event) => {
                                            changeTeam(
                                                index,
                                                fileIndex,
                                                "" + event.target.value
                                            );
                                        }}
                                        label="Teammitglied"
                                        disabled={sortingActive.teamList[index]}
                                    >
                                        <MenuItem key="empty" value="">
                                            <em>Teammitglied wählen</em>
                                        </MenuItem>
                                        {teamListSorted.map(
                                            (
                                                team: ITeam,
                                                teamIndex: number
                                            ) => {
                                                return (
                                                    <MenuItem
                                                        key={
                                                            "team-" + teamIndex
                                                        }
                                                        value={"" + team.id}
                                                    >
                                                        {team.displayTitle}{" "}
                                                        {team.name}
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </Select>
                                </FormControl>
                                {teamRef.id !== "" && teamList[teamRef.id] && (
                                    <TeamTile
                                        participant={
                                            teamList[teamRef.id] as ITeam
                                        }
                                        fullWidth={true}
                                    />
                                )}
                            </Grid>
                        );
                    })}
            </React.Fragment>
        );
    };
    const faq_list = (index: number, element: ICmsPageElement) => {
        // @ts-ignore
        const content = element.content as IQuestionRef[];
        return (
            <React.Fragment key={index}>
                <Grid item xs={12} className="faq-list__header aligned-header">
                    <h5>FAQ</h5>
                    {headerSection(index, [
                        <Tooltip
                            title="Neue Frage hinzufügen"
                            key={"idx-" + index}
                        >
                            <IconButton
                                key="add-faq"
                                onClick={() => addAdditionalFaq(index)}
                                color="primary"
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>,
                    ])}
                </Grid>
                <Grid item xs={12} className="faq-list__container">
                    {content.map((faqRef, fileIndex) => {
                        // @ts-ignore
                        return (
                            <React.Fragment key={index + "-" + fileIndex}>
                                <Grid
                                    item
                                    xs={12}
                                    key={fileIndex}
                                    className="faq-list__container__questions"
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        className="faq-list__container__questions__buttons"
                                    >
                                        <Tooltip title="Antwort hinzufügen">
                                            <IconButton
                                                key="add-response"
                                                onClick={() =>
                                                    addAdditionalResponse(
                                                        index,
                                                        fileIndex
                                                    )
                                                }
                                                color="primary"
                                            >
                                                <QuestionAnswerIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Frage löschen">
                                            <IconButton
                                                key="delete-question"
                                                onClick={() =>
                                                    onDeleteFaq(fileIndex)
                                                }
                                                color="primary"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <TextField
                                        className="faq-list__container__questions__question"
                                        autoFocus
                                        variant="outlined"
                                        label="Frage"
                                        value={
                                            faqRef.question
                                                ? faqRef.question
                                                : ""
                                        }
                                        onChange={(event: any) => {
                                            handleFaqContentTextChange(
                                                event,
                                                "question",
                                                index,
                                                fileIndex
                                            );
                                        }}
                                        fullWidth
                                        required={true}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    key={fileIndex}
                                    className="aq-list-answers__container"
                                >
                                    {content[fileIndex].answers.map(
                                        (
                                            response: IResponse,
                                            responseIndex: number
                                        ) => {
                                            return (
                                                <Grid
                                                    className="faq-list-answers__container__item"
                                                    xs={12}
                                                    key={response.id}
                                                    item
                                                    direction="row"
                                                >
                                                    <TextEdit
                                                        handleChange={(
                                                            editorState
                                                        ) => {
                                                            handleFaqContentWysiwygChange(
                                                                editorState,
                                                                "answers",
                                                                index,
                                                                fileIndex,
                                                                responseIndex
                                                            );
                                                        }}
                                                        title="Antwort"
                                                        defaultValue={
                                                            response.content
                                                                ? response.content
                                                                : ""
                                                        }
                                                    />
                                                    <Tooltip title="Frage löschen">
                                                        <IconButton
                                                            key="Antwort löschen"
                                                            className="faq-list-answers__container__item__button"
                                                            onClick={() =>
                                                                onDeleteResponse(
                                                                    index,
                                                                    fileIndex,
                                                                    responseIndex
                                                                )
                                                            }
                                                            color="primary"
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            );
                                        }
                                    )}
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                </Grid>
            </React.Fragment>
        );
    };
    const calculator = (index: number, element: ICmsPageElement) => {
        return (
            <Grid item xs={12} key={index}>
                <div className="aligned-header">
                    <h5>Fördermittelrechner</h5>
                    {headerSection(index)}
                </div>
                <Input
                    value="Fördermittelrechner"
                    fullWidth
                    required={true}
                    disabled
                    endAdornment={
                        <InputAdornment position="end">
                            <MoneyIcon />
                        </InputAdornment>
                    }
                />
            </Grid>
        );
    };
    const handleEvents: any = {
        header_1,
        header_2,
        header_3,
        html,
        html_text_text,
        html_text_text_text,
        html_text_image,
        html_image_text,
        html_image,
        html_image_image,
        html_image_image_image,
        download_list,
        team_list,
        faq_list,
        calculator,
    };

    const handleOpenPreviewForPage = () => {
        const projectUrlKey = pageData.data?.project?.urlKey;

        const previewUrl = projectUrlKey
            ? projectUrlKey + "/seite/" + pageData.data.urlKey
            : "/seite/" + pageData.data.urlKey;

        if (changesNotSavedState) {
            confirm({
                description:
                    "Die Seite hat ungespeicherte Änderungen. Bitte speichern Sie zuerst die Seite wenn Sie die Änderungen sehen möchten.",
                confirmationText: "Trotzdem anschauen",
                cancellationText: "Erst speichern",
                title: "Inhalt anzeigen",
            }).then(() => {
                window.open(toPortalUrl(mandant, previewUrl), "preview");
            });
        } else {
            window.open(toPortalUrl(mandant, previewUrl), "preview");
        }
    };

    return (
        <>
            <Paper variant="outlined" className={"main-paper"}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        color="inherit"
                        href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                        data-url={toPortalUrl(
                            mandant,
                            RoutesEnum.ADMIN_DASHBOARD
                        )}
                        onClick={handleBread}
                    >
                        Start
                    </Link>
                    <Link
                        color="inherit"
                        href={toPortalUrl(
                            mandant,
                            RoutesEnum.ADMIN_CMS_PAGE_LIST
                        )}
                        data-url={toPortalUrl(
                            mandant,
                            RoutesEnum.ADMIN_CMS_PAGE_LIST
                        )}
                        onClick={handleBread}
                    >
                        Liste der CMS Seiten
                    </Link>
                    <Typography color="textPrimary">
                        {isEditForm && <>Seite: {pageData.data.title}</>}
                        {!isEditForm && <>Eine neue Seite erstellen</>}
                    </Typography>
                </Breadcrumbs>

                {isLoading && (
                    <LinearProgress
                        color={"primary"}
                        style={{ marginBottom: "16px" }}
                    />
                )}

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button onClick={handleSave} color={"primary"}>
                            Seite speichern
                        </Button>

                        <Button
                            onClick={handleOpenPreviewForPage}
                            color="secondary"
                        >
                            Seite anzeigen
                        </Button>
                    </Grid>
                    <Grid item xs={8} className={"gap-bottom"}>
                        <TextField
                            variant="outlined"
                            label="Seiten Titel"
                            name={"title"}
                            value={
                                pageData.data.title ? pageData.data.title : ""
                            }
                            onChange={handleTextChange}
                            fullWidth
                            required={true}
                        />
                        <TextField
                            variant="outlined"
                            label="Url Schlüssel"
                            name={"urlKey"}
                            value={
                                pageData.data.urlKey ? pageData.data.urlKey : ""
                            }
                            onChange={handleTextChange}
                            fullWidth
                            required={true}
                        />

                        <FormControl variant="outlined" fullWidth={true}>
                            <InputLabel id="project-select-label">
                                Projekt
                            </InputLabel>
                            <Select
                                labelId="project-select-label"
                                value={
                                    pageData.data?.project?.id
                                        ? pageData.data?.project?.id
                                        : ""
                                }
                                onChange={changeProject}
                                label="Projekt"
                            >
                                <MenuItem key="empty" value="">
                                    <em>Bitte Projekt wählen</em>
                                </MenuItem>
                                {projectList.map(
                                    (project: ISelectOption, index: number) => {
                                        return (
                                            <MenuItem
                                                key={"project-" + index}
                                                value={project.id}
                                            >
                                                {project.label}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={pageData.data.flagPublished}
                                        onChange={handleCheckboxChange}
                                        name="flagPublished"
                                    />
                                }
                                label="Seite ist publiziert"
                            />
                        </FormGroup>
                        {pageData.data?.project?.id && (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                pageData.data
                                                    .flagShowTimelineHeader
                                            }
                                            onChange={handleCheckboxChange}
                                            name="flagShowTimelineHeader"
                                        />
                                    }
                                    label="Verwenden Sie den Timeline-Header"
                                />
                            </FormGroup>
                        )}

                        <Divider className="divider" />

                        {!refreshMode && (
                            <Grid container spacing={2}>
                                {pageData.elements.map(
                                    (
                                        element: ICmsPageElement,
                                        index: number
                                    ) => {
                                        if (handleEvents[element.type]) {
                                            return handleEvents[element.type](
                                                index,
                                                element
                                            );
                                        } else {
                                            return <div key={index} />;
                                        }
                                    }
                                )}
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={4}>
                        <div className="sticky-menu">
                            <Typography variant="h2" className="gap-bottom">
                                Seiten Elemente
                            </Typography>

                            <ButtonGroup
                                fullWidth={true}
                                variant="outlined"
                                orientation="vertical"
                                color="primary"
                                aria-label="vertical outlined primary button group"
                            >
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={CmsPageElementTypeEnum.HEADER_1}
                                >
                                    Header 1
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={CmsPageElementTypeEnum.HEADER_2}
                                >
                                    Header 2
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={CmsPageElementTypeEnum.HEADER_3}
                                >
                                    Header 3
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={CmsPageElementTypeEnum.HTML}
                                >
                                    Text Voll
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={
                                        CmsPageElementTypeEnum.HTML_TEXT_TEXT
                                    }
                                >
                                    Text | Text
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={
                                        CmsPageElementTypeEnum.HTML_TEXT_TEXT_TEXT
                                    }
                                >
                                    Text | Text | Text
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={
                                        CmsPageElementTypeEnum.HTML_TEXT_IMAGE
                                    }
                                >
                                    Text | Bild
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={
                                        CmsPageElementTypeEnum.HTML_IMAGE_TEXT
                                    }
                                >
                                    Bild | Text
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={
                                        CmsPageElementTypeEnum.HTML_IMAGE
                                    }
                                >
                                    Bild
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={
                                        CmsPageElementTypeEnum.HTML_IMAGE_IMAGE
                                    }
                                >
                                    Bild | Bild
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={
                                        CmsPageElementTypeEnum.HTML_IMAGE_IMAGE_IMAGE
                                    }
                                >
                                    Bild | Bild | Bild
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={
                                        CmsPageElementTypeEnum.DOWNLOAD_LIST
                                    }
                                >
                                    Download Liste
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={CmsPageElementTypeEnum.TEAM_LIST}
                                >
                                    Team Liste
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={CmsPageElementTypeEnum.FAQ_LIST}
                                >
                                    FAQ
                                </Button>
                                <Button
                                    onClick={addNewPageElement}
                                    data-type={
                                        CmsPageElementTypeEnum.CALCULATOR
                                    }
                                >
                                    Fördermittelrechner
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Grid>
                </Grid>

                <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
            </Paper>
        </>
    );
};
export default CmsPageForm;
