import IDocument from "../IDocument";

const InitialDocumentData: IDocument = {
    id: 0,
    url: "",
    description: "",
    copyright: "",
    width: 0,
    height: 0,
    created: "",
    data: "",
    key: "0",
    name: "",
    size: 0,
    title: "",
    type: 0,
};
export default InitialDocumentData;
