import { Grid, Typography } from "@material-ui/core";
import React from "react";

import ICmsPageElement from "../../../../../types/ICmsPageElement";

interface IProps {
    pageFragment: ICmsPageElement;
}

const CmsPageFragmentHeader3 = (props: IProps) => {
    const { pageFragment } = props;

    return (
        <Grid item xs={12}>
            <Typography variant="h3">{pageFragment.content}</Typography>
        </Grid>
    );
};

export default CmsPageFragmentHeader3;
