import "date-fns";

import { Grid, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import TextEdit from "../../../../components/wysiwyg/TextEdit";
import IDocument from "../../../../types/IDocument";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/IProjectFull";
import { IProjectValidationState } from "../projectDataValidation";

interface IProjectDataFormProps {
    mandant: IMandant;
    onTextChange: (event: any) => void;
    onImageChange: (image: IDocument, attrKey: string) => void;
    projectData: IProjectFull;
    onChangeWysiwyg: (editorState: any, name: string) => void;
    errorState: IProjectValidationState;
}

const ProjectStartpageTab = (props: IProjectDataFormProps) => {
    const { projectData, onTextChange, onImageChange, onChangeWysiwyg } = props;
    return (
        <Paper variant="outlined" className="main-paper">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Startseite Header
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body1" className="gap-bottom">
                        Wenn keine Daten im Headerbereich angegeben werden,
                        werden die Einträge des Start Tabs geladen.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        name="menuTabStart"
                        label="Menü Label"
                        fullWidth
                        value={projectData.menuTabStart}
                        onChange={onTextChange}
                        variant="outlined"
                        required={true}
                    />
                    <TextField
                        name="titleTabStart"
                        label="Seiten Titel"
                        fullWidth
                        value={projectData.titleTabStart}
                        onChange={onTextChange}
                        variant="outlined"
                        required={true}
                    />
                    <TextField
                        name="descTabStart"
                        id="outlined-multiline-flexible"
                        label="Kurzbeschreibung"
                        fullWidth
                        multiline
                        value={projectData.descTabStart}
                        onChange={onTextChange}
                        variant="outlined"
                        required={true}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <ImageUpload
                        onChange={onImageChange}
                        docData={projectData.headerTabStart}
                        name={"headerTabStart"}
                        label={"Info Header"}
                        defaultFilename="Header.jpg"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Startseite Content
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <TextField
                        name="welcomeMessageTitle"
                        label="Kopfzeile der Willkommensnachricht"
                        fullWidth
                        value={projectData.welcomeMessageTitle}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                    <TextEdit
                        handleChange={(editorState) => {
                            onChangeWysiwyg(
                                editorState,
                                "welcomeMessageContent"
                            );
                        }}
                        title="Willkommensnachricht"
                        defaultValue={
                            projectData.welcomeMessageContent
                                ? projectData.welcomeMessageContent
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            onChangeWysiwyg(editorState, "motivation");
                        }}
                        title="Text Links"
                        defaultValue={
                            projectData.motivation ? projectData.motivation : ""
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            onChangeWysiwyg(editorState, "motivation2");
                        }}
                        title="Text Rechts"
                        defaultValue={
                            projectData.motivation2
                                ? projectData.motivation2
                                : ""
                        }
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ProjectStartpageTab;
