import "date-fns";

import { Button, Checkbox, Grid, LinearProgress } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useState } from "react";

import DeleteDialog from "../../../../components/deleteDialog/DeleteParticipantDialog";
import Notify from "../../../../components/notify/Notify";
import { Format } from "../../../../config/config";
import ICampaignParticipantList from "../../../../types/appointment/ICampaignParticipantList";
import IMandant from "../../../../types/IMandant";
import InitialSnackbarState from "../../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../../types/ISnackbarState";
import { deleteParticipantRequest } from "../../../../xhr/AppointmentRequests";
import exportTableToExcel from "../exports/exportParticipants";

interface IParticipantListingProps {
    campaignId: string | number;
    participants: ICampaignParticipantList[];
    mandant: IMandant;
    onUpdate: () => void;
}

const ParticipantListingTab: React.FC<IParticipantListingProps> = (
    props: IParticipantListingProps
) => {
    const { participants, onUpdate } = props;

    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const [IsLoading, setIsLoading] = React.useState<boolean>(false);

    const [participantState, setParticipantState] =
        useState<ICampaignParticipantList[]>(participants);

    const handleDeleteParticipant = (row: ICampaignParticipantList) => {
        setIsLoading(true);
        deleteParticipantRequest(props.mandant.key, row.id)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Der Teilnehmer wurde gelöscht.",
                    type: "success",
                });
                onUpdate();
                setIsLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Löschen des Teilnehmers ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setIsLoading(false);
            });
        const newParticipantList = participantState.filter(
            (elem: any) => elem.id !== row.id
        );

        setParticipantState(newParticipantList);
    };

    return (
        <>
            {IsLoading && (
                <LinearProgress
                    color={"primary"}
                    style={{ marginBottom: "16px" }}
                />
            )}
            <Grid container className="gap-bottom">
                <Grid item xs={12}>
                    <Button
                        color={"primary"}
                        onClick={() => exportTableToExcel(participantState)}
                    >
                        Teilnehmer exportieren
                    </Button>
                </Grid>
            </Grid>
            <Typography variant="h2" className="main-header gap-bottom">
                Liste der Teilnehmer
            </Typography>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>UserId</TableCell>
                            <TableCell>Ordnungsnummer</TableCell>
                            <TableCell>Zugangstoken</TableCell>
                            <TableCell>Datum</TableCell>
                            <TableCell>Raum</TableCell>
                            <TableCell>Anrede</TableCell>
                            <TableCell>Vorname</TableCell>
                            <TableCell>Nachname</TableCell>
                            <TableCell>Strasse</TableCell>
                            <TableCell>Hausnummer</TableCell>
                            <TableCell>Postleitzahl</TableCell>
                            <TableCell>Stadt</TableCell>
                            <TableCell>Land</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Per Brief schicken</TableCell>
                            <TableCell>Notifier Email gesendet</TableCell>
                            <TableCell>Erinnerungsmail gesendet</TableCell>
                            <TableCell>Menu</TableCell>
                        </TableRow>
                    </TableHead>
                    {participantState.length > 0 ? (
                        <TableBody>
                            {participantState.map(
                                (row: ICampaignParticipantList) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.user?.id}</TableCell>
                                        <TableCell>{row.orderNumber}</TableCell>
                                        <TableCell>{row.accessToken}</TableCell>
                                        <TableCell>
                                            {row.appointment?.startDate
                                                ? moment(
                                                      row.appointment?.startDate
                                                  ).format(Format.dateTime)
                                                : ""}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                row.appointment?.campaignRoom
                                                    ?.name
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {row.user?.salutation}
                                        </TableCell>
                                        <TableCell>
                                            {row.user?.firstname}
                                        </TableCell>
                                        <TableCell>
                                            {row.user?.lastname}
                                        </TableCell>
                                        <TableCell>
                                            {" "}
                                            {row.user?.street}
                                        </TableCell>
                                        <TableCell>
                                            {row.user?.streetNo}
                                        </TableCell>
                                        <TableCell>{row.user?.zip}</TableCell>
                                        <TableCell>{row.user?.city}</TableCell>
                                        <TableCell>
                                            {row.user?.country}
                                        </TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={row.flagSendPostalMail}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    row.flagNotifierMailSent
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    row.flagReminderMailSent
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <DeleteDialog
                                                deleteHandler={
                                                    handleDeleteParticipant
                                                }
                                                participant={row}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    ) : (
                        <></>
                    )}
                </Table>
            </TableContainer>

            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

export default ParticipantListingTab;
