import {
    Button,
    CircularProgress,
    Grid,
    LinearProgress,
    Paper,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconGames from "@material-ui/icons/Games";
import IconOpenWith from "@material-ui/icons/OpenWith";
import arrayMove from "array-move";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { useParams } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import Notify from "../../../../../components/notify/Notify";
import TeamForm from "../../../../../components/team/TeamForm";
import TeamTile from "../../../../../components/team/TeamTile";
import IDocument from "../../../../../types/IDocument";
import IMandant from "../../../../../types/IMandant";
import InitialSnackbarState from "../../../../../types/initialData/InitialSnackbarState";
import InitialTeamData from "../../../../../types/initialData/InitialTeamData";
import ISnackbarState from "../../../../../types/ISnackbarState";
import ITeam from "../../../../../types/ITeam";
import {
    createEventParticipantRequest,
    deleteEventParticipantRequest,
    updateEventParticipantRequest,
} from "../../../../../xhr/TeamRequests";

/**
 */
interface IProps {
    participants: ITeam[];
    refreshEvent: () => void;
    sortEvent: (sortedlistTeam: string[]) => void;
    mandant: IMandant;
}

const EventMembersTab = (props: IProps) => {
    const confirm = useConfirm();
    const { participants, mandant } = props;
    const { eventId } = useParams<{
        eventId: string;
    }>();

    const [participantState, setParticipants] =
        React.useState<ITeam[]>(participants);

    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });
    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };
    const [loading, setLoading] = React.useState<boolean>(false);
    const [sortingActive, setSortingActive] = React.useState<boolean>(false);

    const [participantAction, setParticipantAction] =
        React.useState<string>("");
    const [selectedParticipant, setSelectedParticipant] = React.useState<ITeam>(
        {
            ...InitialTeamData,
        }
    );
    React.useEffect(() => {
        setParticipants(participants);
    }, [participants]);
    const refreshData = () => {
        setSelectedParticipant({ ...InitialTeamData });
        setParticipantAction("CREATE");
        props.refreshEvent();
    };

    /* handle form state change  */
    const onTeamFormChange = (event: any) => {
        setSelectedParticipant({
            ...selectedParticipant,
            [event.target.name]: event.target.value,
        });
    };

    const onTeamFormCheckChange = (event: any) => {
        setSelectedParticipant({
            ...selectedParticipant,
            [event.target.name]: event.target.checked,
        });
    };

    /**
     * upload event for the document
     */
    const onTeamImageChange = (image: IDocument) => {
        setSelectedParticipant({
            ...selectedParticipant,
            image: {
                ...selectedParticipant.image,
                ...image,
            },
        });
    };

    /* handle edit participant click */
    const toggleSortingAction = () => {
        if (sortingActive) {
            props.sortEvent(
                participantState.map((part: ITeam): string => {
                    return "" + part.id;
                })
            );
        }

        setSortingActive(!sortingActive);
    };

    /* handle edit participant click */
    const handleEditTeam = (selectedPart: ITeam) => {
        setParticipantAction("EDIT");
        setSelectedParticipant({ ...selectedPart });
    };
    /* handle delete participant */
    const handleDeleteTeam = (participant: ITeam) => {
        confirm({
            description:
                "Bitte bestätigen Sie, dass sie den Teilnehmer löschen möchten",
            confirmationText: "Ja löschen",
            cancellationText: "Abbrechen",
            title: participant.displayTitle,
        }).then(() => {
            setLoading(true);
            if (participant.id !== null) {
                deleteEventParticipantRequest(participant.id, mandant.key)
                    .then(() => {
                        const newData = participantState.filter(
                            (elem) => elem.id !== participant.id
                        );
                        setParticipants(newData);
                        // refreshData();

                        setSnackBarState({
                            isOpen: true,
                            message: "Der Teilnehmer wurde gelöscht.",
                            type: "success",
                        });
                        setLoading(false);
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Fehler beim Löschen des Teilnehmers.",
                            type: "error",
                        });
                        setLoading(true);
                    });
            }
        });
    };
    /* handle participant save edit click */
    const onTeamSaveClick = () => {
        setLoading(true);

        const teamToPost = {
            ...selectedParticipant,
            event: { id: eventId },
        };
        updateEventParticipantRequest(mandant.key, teamToPost)
            .then(() => {
                refreshData();
                setSnackBarState({
                    isOpen: true,
                    message: "Der Teilnehmer wurde angepasst.",
                    type: "success",
                });
                setLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Fehler beim anpassen des Teilnehmers.",
                    type: "error",
                });
                setLoading(false);
            });
    };
    /* create paticipant */
    const onTeamCreateClick = () => {
        setLoading(true);

        const teamToPost = {
            ...selectedParticipant,
            event: { id: eventId },
        };
        createEventParticipantRequest(mandant.key, teamToPost)
            .then((response: any) => {
                const newData = [...participantState];
                newData.push(response.data);
                setParticipants(newData);
                setSelectedParticipant({ ...InitialTeamData });
                // refreshData();
                setSnackBarState({
                    isOpen: true,
                    message: "Der Teilnehmer wurde erstellt",
                    type: "success",
                });
                setLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Fehler beim Erstellen des Teilnehmers.",
                    type: "error",
                });
                setLoading(false);
            });
    };
    const SortableItem = SortableElement(
        ({ value, index }: { value: any; index: any }) => (
            <TeamTile key={value.name + index} participant={value} />
        )
    );
    const SortableList = SortableContainer(({ items }: { items: ITeam[] }) => {
        return (
            <Grid item xs={12} md={8} xl={9}>
                <Grid container alignItems="flex-start" spacing={2}>
                    {items ? (
                        items.map((participant: ITeam, index) => (
                            <SortableItem
                                value={participant}
                                index={index}
                                key={participant.name}
                            />
                        ))
                    ) : (
                        <CircularProgress />
                    )}
                </Grid>
            </Grid>
        );
    });
    const onSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        const arry = arrayMove(participantState, oldIndex, newIndex);

        setParticipants(arry);
    };
    return (
        <>
            {loading && (
                <LinearProgress
                    color={"primary"}
                    style={{ marginBottom: "16px" }}
                />
            )}
            <Paper variant="outlined" className={"main-paper"}>
                <Typography variant={"h2"} className="main-header">
                    Teilnehmer Verwaltung
                </Typography>

                <Button
                    color="secondary"
                    onClick={toggleSortingAction}
                    startIcon={sortingActive ? <IconOpenWith /> : <IconGames />}
                >
                    {sortingActive
                        ? "Sortierung deaktivieren"
                        : "Sortierung aktivieren"}
                </Button>

                <Grid container spacing={2}>
                    {sortingActive && (
                        <SortableList
                            items={participantState}
                            onSortEnd={onSortEnd}
                            axis="xy"
                        />
                    )}

                    {!sortingActive && (
                        <Grid item xs={12} md={8} xl={9}>
                            <Grid container alignItems="flex-start" spacing={2}>
                                {participantState ? (
                                    participantState
                                        .sort((a: ITeam, b: ITeam) => {
                                            return a.priority - b.priority;
                                        })
                                        .map((participant: ITeam, index) => (
                                            <TeamTile
                                                key={participant.name + index}
                                                participant={participant}
                                                handleEditClick={() =>
                                                    handleEditTeam(participant)
                                                }
                                                handleDeleteClick={() =>
                                                    handleDeleteTeam(
                                                        participant
                                                    )
                                                }
                                            />
                                        ))
                                ) : (
                                    <CircularProgress />
                                )}
                            </Grid>
                        </Grid>
                    )}

                    <Grid item xs={12} md={4} xl={3}>
                        <Paper variant="outlined" className="main-paper">
                            <TeamForm
                                label="Teilnehmer"
                                participant={selectedParticipant}
                                onEditHandler={onTeamFormChange}
                                onCheckEditHandler={onTeamFormCheckChange}
                                action={participantAction}
                                onCreateTeam={onTeamCreateClick}
                                onTeamSave={onTeamSaveClick}
                                onImageChange={onTeamImageChange}
                            />
                        </Paper>
                    </Grid>
                    <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
                </Grid>
            </Paper>
        </>
    );
};
export default EventMembersTab;
