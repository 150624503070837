import { Button, Fab, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AttachmentIcon from "@material-ui/icons/Attachment";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";

import IDocument from "../../../../types/IDocument";
import { formatBytes } from "../../../../utils/bytesConverter";

interface IAppointmentDataTabProps {
    document: IDocument;
    handleFileImport: (event: any) => void;
    handleSave: () => void;
    handleReset: () => void;
}

const ParticipantImportDataTab = (props: IAppointmentDataTabProps) => {
    const sizeIsNotNull = (size?: number | null) => {
        if (size == null) {
            return false;
        }

        return size > 0;
    };

    const { handleFileImport, handleSave, handleReset, document } = props;
    return (
        <>
            <Grid container className="gap-bottom">
                <Grid item xs={12}>
                    <Button color={"primary"} onClick={handleSave}>
                        Teilnehmer importieren
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="gap-bottom">
                        <input
                            accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf, .csv"
                            id={"campaign-data-upload"}
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileImport}
                        />

                        <label htmlFor={"campaign-data-upload"}>
                            Dokument anhängen
                            <Fab
                                component="span"
                                style={{
                                    marginTop: "16px",
                                    marginLeft: "16px",
                                    marginBottom: "16px",
                                }}
                            >
                                <AttachmentIcon />
                            </Fab>
                        </label>
                        {document?.name && (
                            <Fab
                                component="span"
                                onClick={() => handleReset()}
                                style={{
                                    marginTop: "16px",
                                    marginLeft: "16px",
                                    marginBottom: "16px",
                                }}
                            >
                                <DeleteIcon />
                            </Fab>
                        )}
                        {document?.name && (
                            <div className="gap-bottom_2">
                                <Typography component="div">
                                    {document?.name}
                                </Typography>

                                {sizeIsNotNull(document?.size) && (
                                    <Typography>
                                        Dateigröße :{" "}
                                        {formatBytes(document?.size)}
                                    </Typography>
                                )}
                            </div>
                        )}
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default ParticipantImportDataTab;
