import "./MandantOverview.css";

import { LinearProgress, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";

import logo from "../../../assets/images/logo.png";
import CookiesConsent from "../../../components/cookiesConsent/CookiesConsent";
import InDevelopmentBanner from "../../../components/inDevelopmentBanner/InDevelopmentBanner";
import ProjectLongBlock from "../../../components/projectsOverview/ProjectLongBlock";
import { IS_TESTSYSTEM, PAGE_BREAK_MOBILE } from "../../../config/config";
import useWindowSize from "../../../customhooks/useWindowSize";
import PortalSimpleFooter from "../../../layout/portalFooter/PortalSimpleFooter";
import IMandant from "../../../types/IMandant";
import IOverview from "../../../types/IOverview";
import IProjectList from "../../../types/IProjectList";
import setMandantThemeByServer from "../../../utils/setMandantThemeByServer";
import { loadOverviewRequest } from "../../../xhr/OverviewRequests";
import { loadPublicAllProjectListRequest } from "../../../xhr/ProjectRequests";

interface IMandantOverviewParams {
    mandant: IMandant;
}

const MandantOverview = (props: IMandantOverviewParams) => {
    const [projectList, setProjectList] = React.useState<IProjectList[]>([]);
    const [overViewData, setOverViewData] = React.useState<IOverview>();
    const [projectReady, setProjectsReady] = React.useState<boolean>(false);
    const [overviewReady, setOverviewReady] = React.useState<boolean>(false);

    const mandant = {
        primaryColor: "#6bd5fc",
        theme: "sporty",
        key: "",
    };
    const windowSize = useWindowSize();
    const [isMobile, setIsMobile] = React.useState<boolean | null>(null);

    React.useEffect(() => {
        if (windowSize > 319) {
            let flagIsMobile = false;
            if (windowSize < PAGE_BREAK_MOBILE) {
                flagIsMobile = true;
            }

            if (isMobile !== flagIsMobile) {
                // this was moved to line 62 to update the mandant when the user switches the projects.
                // setMandantThemeByServer(mandant, flagIsMobile);
                setIsMobile(flagIsMobile);
            }
            setMandantThemeByServer(mandant, flagIsMobile);
        }
    }, [mandant]);

    React.useEffect(() => {
        loadPublicAllProjectListRequest()
            .then((result: any) => {
                if (result.data != null) {
                    setProjectList(result.data);
                    setProjectsReady(true);
                }
                return Promise.resolve();
            })
            .catch(() => {
                setProjectsReady(true);
            });
        loadOverviewRequest()
            .then((result: any) => {
                setOverViewData(result.data);
                setOverviewReady(true);
                return Promise.resolve();
            })
            .catch(() => {
                setOverviewReady(true);
            });
    }, [mandant.key]);

    if (!projectReady || !overviewReady) {
        return (
            <div className="portal-section">
                <CookiesConsent mandant={mandant} />
                <div className="App-main">
                    <div className="App-cms-container">
                        <LinearProgress />
                    </div>
                </div>
            </div>
        );
    }

    const renderInDevelopmentBanner = () => {
        if (IS_TESTSYSTEM) {
            return <InDevelopmentBanner />;
        }
        return <></>;
    };

    return (
        <div className="portal-section">
            <div className="App-main">
                {renderInDevelopmentBanner()}
                <div className="App-cms-container">
                    <div className="mandant-overview__logo-container">
                        <a href={"https://steg.de"} target="__blank">
                            <img
                                className="mandant_App-header-logo-steg"
                                src={logo}
                                alt="Logo"
                            />
                        </a>
                    </div>

                    <div className="portal-section-body-position">
                        <Grid
                            container
                            spacing={2}
                            style={{ maxWidth: "1200px" }}
                        >
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="h3">
                                    {overViewData?.firstTitle}
                                </Typography>
                                <Typography variant="h1" className="gap-top">
                                    {overViewData?.secondTitle}
                                </Typography>

                                <Typography
                                    variant="body2"
                                    style={{ fontSize: "24px" }}
                                    className="gap-top-2"
                                >
                                    {overViewData?.firstDescription}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography variant="body1">
                                    {overViewData?.secondDescriptionLeft}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography variant="body1">
                                    {overViewData?.secondDescriptionRight}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid
                        container
                        className="gap-top-2 project-list-contianer"
                        direction="column"
                    >
                        {projectList != null &&
                            projectList
                                .sort((a: IProjectList, b: IProjectList) => {
                                    if (a.priority === null) {
                                        return 1;
                                    } else if (b.priority === null) {
                                        return -1;
                                    } else {
                                        return a.priority - b.priority;
                                    }
                                })
                                .map((project: IProjectList, index: number) => (
                                    <ProjectLongBlock
                                        projectData={project}
                                        mandant={project.mandant as IMandant}
                                        index={index % 2}
                                        key={project.id}
                                    />
                                ))}
                    </Grid>
                </div>
            </div>
            <PortalSimpleFooter mandant={mandant} />
        </div>
    );
};

export default MandantOverview;
