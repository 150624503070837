import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { API_URL_STATIC } from "../../config/config";
import DownloadCard from "../downloadCard/DownloadCard";

interface IFolderListProps {
    files: any[];
    fromEventTab?: boolean;
}

export default function FolderList(props: IFolderListProps) {
    const { files } = props;

    if (!files?.length) {
        return <></>;
    }

    return (
        <>
            <Typography
                gutterBottom
                variant="h3"
                className="gap-top gap-bottom"
            >
                Hier finden Sie weiterführende Dokumente
            </Typography>
            <Grid container spacing={2} direction="row">
                {files.map((elem: any, index: number) => {
                    return (
                        <Grid key={index + ""} item xs={12} md={6} lg={4}>
                            <DownloadCard
                                tooltip={elem?.document?.name}
                                title={
                                    elem?.document?.title
                                        ? elem?.document?.title
                                        : elem?.document?.name
                                }
                                description={
                                    elem?.document?.description
                                        ? elem?.document?.description
                                        : ""
                                }
                                actionButtonText="Herunterladen"
                                actionButtonClassName="App-header-red-button"
                                downloadUrl={
                                    API_URL_STATIC + elem?.document?.url
                                }
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
}
