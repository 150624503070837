import "./EventForm.css";

import {
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link,
    Typography,
} from "@material-ui/core";
import IconSave from "@material-ui/icons/Save";
import IconPublish from "@material-ui/icons/Visibility";
import IconUnPublish from "@material-ui/icons/VisibilityOff";
import draft from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { arrayMove } from "react-sortable-hoc";

import Notify from "../../../components/notify/Notify";
import TabContainer from "../../../components/tabContainer/TabContainer";
import { Format } from "../../../config/config";
import RoutesEnum from "../../../routes/RoutesEnum";
import IDocument from "../../../types/IDocument";
import IEventFull from "../../../types/IEventFull";
import IEventGalleryImage from "../../../types/IEventGalleryImage";
import ILocation from "../../../types/ILocation";
import IMandant from "../../../types/IMandant";
import InitialEventFullData from "../../../types/initialData/InitialEventFullData";
import InitialEventGalleryElementData from "../../../types/initialData/InitialEventGalleryElementData";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import deepCopy from "../../../utils/deepCopy";
import { getEditorStateContent } from "../../../utils/editorContentHelper/editorContentHelper";
import { getIds } from "../../../utils/getIds";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import {
    createEventRequest,
    loadEventAttachmentsRequest,
    loadSingleEventRequest,
    sortEventFilesRequest,
    sortEventGalleryRequest,
    switchEventPublishFlagRequest,
    updateEventFileRequest,
    updateEventGalleryRequest,
    updateEventRequest,
} from "../../../xhr/EventRequests";
import { sortEventParticipantRequest } from "../../../xhr/TeamRequests";
import {
    IEventValidationState,
    requiredEventFilled,
} from "./eventDataValidation";
import EventDataTab from "./tabs/eventDataTab/EventDataTab";
import EventFileTab from "./tabs/EventFileTab";
import EventMembersTab from "./tabs/eventMembersTab/EventMembersTab";
import EventReportTab from "./tabs/eventReportTab/EventReportTab";
import EventTextTab from "./tabs/eventTextTab/EventTextTab";
import ImageGalleryTab from "./tabs/imageGalleryTab/ImageGalleryTab";

type ILocalImageGallery = {
    [key: string]: IEventGalleryImage[];
};

interface IOwnProps {
    mandant: IMandant;
}
const EventForm = (props: IOwnProps) => {
    const history = useHistory();
    const { eventId, projectId } = useParams<{
        projectId: any;
        eventId: any;
    }>();
    const { mandant } = props;
    const [isEditForm, setIsEditForm] = React.useState<boolean>(
        !isNaN(eventId)
    );
    const [activeTab, setActiveTab] = React.useState<number>(1);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [errorState, setErrorState] = React.useState<IEventValidationState>({
        hasError: false,
    });

    /**
     * The form data
     */
    const [eventData, setEventData] = React.useState<IEventFull>({
        ...InitialEventFullData,
    });

    const germanyCenter = {
        latitude: 51.1657,
        longitude: 10.4515,
        zoom: 4,
    };

    const [eventLocation, setEventLocation] = React.useState<ILocation>({
        latitude: germanyCenter.latitude,
        longitude: germanyCenter.longitude,
        zoom: germanyCenter.zoom,
    });

    /**
     * Snackkbar related states
     */
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });
    const [changesNotSavedState, setChangesNotSavedState] =
        React.useState<boolean>(false);

    /**
     * Gallery states
     */
    const [images, setImages] = React.useState<ILocalImageGallery>({});
    const [galleryLoading, setGalleryLoading] = React.useState<boolean>(false);
    const confirm = useConfirm();
    const closeChangesNotSavedWarning = () => {
        setChangesNotSavedState(false);
    };
    const openChangesNotSavedWarning = () => {
        setChangesNotSavedState(true);
    };
    /* Load single event and the form data state with it's content */
    const loadEventToState = (loadEventId: string) => {
        setIsLoading(true);

        loadSingleEventRequest(mandant.key, loadEventId)
            .then((response) => {
                setEventData(response.data);
                setEventLocation({
                    latitude: response.data.latitude,
                    longitude: response.data.longitude,
                    zoom: response.data.zoom,
                });
                loadEventAttachmentsRequest(mandant.key, loadEventId).then(
                    (res: any) => {
                        images.attachments = res.data ? [...res.data] : [];
                        images.default = response.data.images
                            ? [...response.data.images]
                            : [];
                        images.after = response.data.imagesAfter
                            ? [...response.data.imagesAfter]
                            : [];

                        setImages(deepCopy<ILocalImageGallery>(images));
                    }
                );

                setIsLoading(false);
                closeChangesNotSavedWarning();
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Laden der Veranstaltung ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setIsLoading(false);
            });
    };
    //effect

    React.useEffect(() => {
        if (isEditForm) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            loadEventToState(eventId);
        } else {
            // if we create an event we need at least the project
            setEventData({
                ...eventData,
                project: { id: projectId },
            });
        }
    }, [mandant.key, eventId]);

    const handleOnImagesOrderChange = (
        oldIndex: number,
        newIndex: number,
        type: string
    ) => {
        const arry = arrayMove(images[type], oldIndex, newIndex);

        setImages({
            ...images,
            [type]: arry,
        });
        openChangesNotSavedWarning();
    };

    const handleOnImagesOrderSave = (type: string) => {
        setIsLoading(true);

        sortEventGalleryRequest(
            mandant.key,
            getIds(images[type]),
            eventData.id,
            type
        )
            .then((response: any) => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Sortierung wurde erfolgreich gespeichert.",
                    type: "success",
                });
                setIsLoading(false);
                setImages({
                    ...images,
                    [type]: response.data,
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Speichern der Sortierung ist ein Fehler aufgetreten",
                    type: "error",
                });
                setIsLoading(false);
            });
    };

    const handleEventSave = () => {
        const newErrorState = requiredEventFilled(eventData);
        setErrorState(newErrorState);

        if (newErrorState.hasError) {
            setSnackBarState({
                isOpen: true,
                message: "Fehlende Pflichtfelder.",
                type: "error",
            });
            return;
        }

        setIsLoading(true);
        if (isEditForm) {
            updateEventRequest(mandant.key, {
                ...eventData,
                latitude: eventLocation.latitude,
                longitude: eventLocation.longitude,
                zoom: eventLocation.zoom,
            })
                .then(() => {
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Die Veranstaltung wurde erfolgreich gespeichert.",
                        type: "success",
                    });
                    setIsLoading(false);
                })
                .catch(() => {
                    //setIsLoading(false);
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Beim Speichern der Veranstaltung ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        } else {
            createEventRequest(mandant.key, {
                ...eventData,
                latitude: eventLocation.latitude,
                longitude: eventLocation.longitude,
                zoom: eventLocation.zoom,
            })
                .then((response: any) => {
                    setIsEditForm(true);

                    history.push(
                        toPortalUrl(
                            mandant,
                            RoutesEnum.ADMIN_PROJECT +
                                eventData.project +
                                RoutesEnum.ADMIN_SUB_EVENT +
                                response.data.id
                        )
                    );

                    loadEventToState(response.data.id);
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Die Veranstaltung wurde erfolgreich erstellt.",
                        type: "success",
                    });
                    setIsLoading(false);
                })
                .catch(() => {
                    //setIsLoading(false);
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Beim Erstellen der Veranstaltung ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        }
    };

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const onEventPublishClick = () => {
        switchEventPublishFlagRequest(eventData.id, mandant.key)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: eventData.flagIsPublished
                        ? "Die Veranstaltung wurde depubliziert."
                        : "Die Veranstaltung wurde publiziert.",
                    type: "success",
                });

                // it's a reference. should work
                eventData.flagIsPublished = !eventData.flagIsPublished;
                setEventData(deepCopy<IEventFull>(eventData));
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Publizieren der Veranstaltung is ein Fehler aufgetreten",
                    type: "error",
                });
            });
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    /*
    Handle generic change
   */
    const handleTextChange = (event: any) => {
        const newData = {
            ...eventData,
            [event.target.name]: event.target.value,
        };
        setEventData(newData);
        openChangesNotSavedWarning();
    };

    /**
     *
     * @param latitude
     * @param longitude
     * @param zoom
     */
    const handleEventLocationChange = (
        latitude: number,
        longitude: number,
        zoom: number
    ) => {
        const newLocationData = {
            ...eventLocation,
            latitude,
            longitude,
            zoom,
        };

        setEventLocation(newLocationData);
        openChangesNotSavedWarning();
    };

    const handleCheckboxChange = (event: any) => {
        const newData = {
            ...eventData,
            [event.target.name]: event.target.checked,
        };
        setEventData(newData);
        openChangesNotSavedWarning();
    };

    const handleImageChange = (image: IDocument, attrKey: string) => {
        const newData = { ...eventData, [attrKey]: image };
        setEventData(newData);
        openChangesNotSavedWarning();
    };

    const handleWysiwygChange = (editorState: any, name: string) => {
        const rawContentState = draft.convertToRaw(
            editorState.getCurrentContent()
        );
        const markup = draftToHtml(rawContentState);
        let newData: IEventFull = InitialEventFullData;
        newData = {
            ...eventData,
            [name]: getEditorStateContent(editorState, markup),
        };
        setEventData(newData);
        openChangesNotSavedWarning();
    };

    const handeDateChange = (date: Date, attrKey: string) => {
        setEventData({
            ...eventData,
            [attrKey]: moment(date).format(Format.dateTimeEn),
        });
        openChangesNotSavedWarning();
    };

    /*
     * Functions for the gallery tab
     */

    const handleGalleryUpload = (
        type: string,
        image: IEventGalleryImage,
        index: number
    ): void => {
        images[type][index] = image;
        setImages(deepCopy<ILocalImageGallery>(images));
        openChangesNotSavedWarning();
    };

    const handleGalleryDelete = (type: string, index: number): void => {
        confirm({
            description:
                "Bitte bestätigen sie, dass sie das Bild löschen möchten.",
            confirmationText: "Ja löschen",
            cancellationText: "Abbrechen",
            title: "Bild aus der Galerie löschen",
        }).then(() => {
            images[type].splice(index, 1);
            setImages(deepCopy<ILocalImageGallery>(images));
            openChangesNotSavedWarning();
        });
    };

    const handleGalleryItemReset = (
        type: string,
        index: number,
        imageId: number
    ): void => {
        let oldImage;

        if (type === "default") {
            oldImage = eventData.images.find((checkImg: IEventGalleryImage) => {
                return checkImg.id === imageId;
            });
        } else {
            oldImage = eventData.imagesAfter.find(
                (checkImg: IEventGalleryImage) => {
                    return checkImg.id === imageId;
                }
            );
        }

        // will always exist
        if (oldImage) {
            images[type][index] = oldImage;
        }

        setImages(deepCopy<ILocalImageGallery>(images));
    };

    const handleGalleryCreateImage = (type: string): void => {
        const newImage = { ...InitialEventGalleryElementData, type };

        if (images[type].length) {
            images[type] = [newImage].concat(images[type]);
        } else {
            images[type] = [newImage];
        }

        setImages(deepCopy<ILocalImageGallery>(images));
        openChangesNotSavedWarning();
    };

    const handleSaveImages = (type: string): void => {
        setGalleryLoading(true);

        updateEventGalleryRequest(mandant.key, eventId, type, images[type])
            .then((response: any) => {
                setImages({ ...images, [type]: [...response.data] });

                if (type === "default") {
                    setEventData({
                        ...eventData,
                        images: [...response.data],
                    });
                } else {
                    setEventData({
                        ...eventData,
                        imagesAfter: [...response.data],
                    });
                }

                setSnackBarState({
                    isOpen: true,
                    message: "Die Galerie wurde gespeichert.",
                    type: "success",
                });
                setGalleryLoading(false);
            })
            .catch(() => {
                //setIsLoading(false);
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim speichern der Galerie ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setGalleryLoading(false);
            });
    };

    const updateParticipantOrderAction = (sortedlistTeam: string[]) => {
        setIsLoading(true);

        sortEventParticipantRequest(mandant.key, sortedlistTeam, eventData.id)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Sortierung wurde erfolgreich gespeichert.",
                    type: "success",
                });
                setIsLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Speichern der Sortierung ist ein Fehler aufgetreten",
                    type: "error",
                });
                setIsLoading(false);
            });
    };
    /* handle order files event save  */
    const handleOnFileOrderSave = () => {
        setIsLoading(true);

        sortEventFilesRequest(
            mandant.key,
            getIds(images.attachments),
            eventData.id
        )
            .then((response: any) => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Sortierung wurde erfolgreich gespeichert.",
                    type: "success",
                });
                setIsLoading(false);
                setImages({
                    attachments: response.data,
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Speichern der Sortierung ist ein Fehler aufgetreten",
                    type: "error",
                });
                setIsLoading(false);
            });
    };
    //updateEventFileRequest
    const handleSaveFiles = (type: string): void => {
        setGalleryLoading(true);

        updateEventFileRequest(mandant.key, eventData.id, type, images[type])
            .then((response: any) => {
                setImages({ ...images, [type]: [...response.data] });

                setEventData({
                    ...eventData,
                    images: [...response.data],
                });

                setSnackBarState({
                    isOpen: true,
                    message: "Die Galerie wurde gespeichert.",
                    type: "success",
                });
                setGalleryLoading(false);
            })
            .catch(() => {
                //setLoading(false);
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim speichern der Galerie ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setGalleryLoading(false);
            });
    };

    function handleBread(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }
    // Handle show content
    const handleOpenPreviewForPage = () => {
        if (changesNotSavedState) {
            confirm({
                description:
                    "Die Veranstaltung hat ungespeicherte Änderungen. Bitte speichern Sie zuerst die Veranstaltung wenn Sie die Änderungen sehen möchten.",
                confirmationText: "Trotzdem anschauen",
                cancellationText: "Erst speichern",
                title: "Inhalt anzeigen",
            }).then(() => {
                const previewUrl = RoutesEnum.PORTAL_EVENT + "/" + eventData.id;
                window.open(toPortalUrl(mandant, previewUrl), "preview");
            });
        } else {
            const previewUrl = RoutesEnum.PORTAL_EVENT + "/" + eventData.id;
            window.open(toPortalUrl(mandant, previewUrl), "preview");
        }
    };

    return (
        <div>
            {isLoading && (
                <LinearProgress
                    color={"primary"}
                    style={{ marginBottom: "16px" }}
                />
            )}

            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    onClick={handleBread}
                >
                    Projekte verwalten
                </Link>

                <Typography color="textPrimary">
                    {isEditForm ? (
                        <>{eventData.title}</>
                    ) : (
                        <>Eine neue Veranstaltung erstellen</>
                    )}
                </Typography>
            </Breadcrumbs>

            {isEditForm && (
                <>
                    <Typography variant={"h3"} className="gap-top-1">
                        <strong>Ab</strong>:{" "}
                        {moment(eventData.theStart).format(Format.dateTime)}
                        {" Uhr "}
                        bis: {moment(eventData.theEnd).format(Format.dateTime)}
                        {" Uhr "}
                        <br />
                        <strong>Ort</strong>: {eventData.locationName}
                    </Typography>
                </>
            )}

            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        onClick={handleEventSave}
                        color={"primary"}
                        startIcon={<IconSave />}
                    >
                        Veranstaltung speichern
                    </Button>

                    {isEditForm && (
                        <>
                            <Button
                                onClick={onEventPublishClick}
                                color={"secondary"}
                                startIcon={
                                    eventData.flagIsPublished ? (
                                        <IconUnPublish />
                                    ) : (
                                        <IconPublish />
                                    )
                                }
                            >
                                {eventData.flagIsPublished
                                    ? "Veranstaltung depublizieren"
                                    : "Veranstaltung publizieren"}
                            </Button>
                            <Button
                                onClick={handleOpenPreviewForPage}
                                color="secondary"
                            >
                                Seite anzeigen
                            </Button>
                        </>
                    )}
                </Grid>
            </Grid>

            <TabContainer
                isAdmin={true}
                tabs={[
                    {
                        id: "tab-event-data",
                        label: "Veranstaltung Daten",
                        display: true,
                        tabKey: 1,
                        content: (
                            <EventDataTab
                                eventData={eventData}
                                eventLocation={eventLocation}
                                errorState={errorState}
                                onEventLocationChange={
                                    handleEventLocationChange
                                }
                                onImageChange={handleImageChange}
                                onTextChange={handleTextChange}
                                onCheckboxChange={handleCheckboxChange}
                                onDateChange={handeDateChange}
                            />
                        ),
                    },
                    {
                        id: "tab-description",
                        label: "Beschreibung",
                        display: true,
                        tabKey: 2,
                        content: (
                            <EventTextTab
                                eventData={eventData}
                                onWysiwygChange={handleWysiwygChange}
                            />
                        ),
                    },
                    {
                        id: "tab-image-gallery",
                        label: "Vorab BilderGalerie",
                        display: isEditForm,
                        tabKey: 3,
                        content: (
                            <>
                                {images.default && (
                                    <ImageGalleryTab
                                        images={images.default}
                                        label="Vorab BilderGalerie"
                                        loadingGallery={galleryLoading}
                                        type="default"
                                        onUpload={handleGalleryUpload}
                                        onDelete={handleGalleryDelete}
                                        resetImage={handleGalleryItemReset}
                                        onSave={handleSaveImages}
                                        onImageCreate={handleGalleryCreateImage}
                                        onSort={handleOnImagesOrderChange}
                                        handleImagesSortSave={
                                            handleOnImagesOrderSave
                                        }
                                    />
                                )}
                            </>
                        ),
                    },
                    {
                        id: "tab-event-report",
                        label: "Veranstaltungsbericht",
                        display: true,
                        tabKey: 4,
                        content: (
                            <EventReportTab
                                eventData={eventData}
                                onWysiwygChange={handleWysiwygChange}
                            />
                        ),
                    },
                    {
                        id: "tab-gallery-report",
                        label: "Bildergalerie Bericht",
                        display: isEditForm,
                        tabKey: 5,
                        content: (
                            <>
                                {images.after && (
                                    <ImageGalleryTab
                                        images={images.after}
                                        label="Bildergalerie Bericht"
                                        loadingGallery={galleryLoading}
                                        type="after"
                                        onUpload={handleGalleryUpload}
                                        onDelete={handleGalleryDelete}
                                        resetImage={handleGalleryItemReset}
                                        onSave={handleSaveImages}
                                        onImageCreate={handleGalleryCreateImage}
                                        onSort={handleOnImagesOrderChange}
                                        handleImagesSortSave={
                                            handleOnImagesOrderSave
                                        }
                                    />
                                )}
                            </>
                        ),
                    },
                    {
                        id: "tab-event-downloads",
                        label: "Datei Downloads",
                        display: isEditForm,
                        tabKey: 6,
                        content: (
                            <>
                                {images.attachments && (
                                    <EventFileTab
                                        label="Datei Downloads"
                                        images={images.attachments}
                                        type="attachments"
                                        onUpload={handleGalleryUpload}
                                        onDelete={handleGalleryDelete}
                                        onResetImage={handleGalleryItemReset}
                                        onSave={handleSaveFiles}
                                        onImageCreate={handleGalleryCreateImage}
                                        loadingGallery={galleryLoading}
                                        onSort={handleOnImagesOrderChange}
                                        handleImagesSortSave={
                                            handleOnFileOrderSave
                                        }
                                    />
                                )}
                            </>
                        ),
                    },
                    {
                        id: "tab-event-participants",
                        label: "Teilnehmer",
                        display: isEditForm,
                        tabKey: 7,
                        content: (
                            <EventMembersTab
                                participants={eventData.participants || []}
                                refreshEvent={() => {
                                    loadEventToState("" + eventData.id);
                                }}
                                sortEvent={updateParticipantOrderAction}
                                mandant={mandant}
                            />
                        ),
                    },
                ]}
                activeTab={activeTab}
                ariaLabel={"Veranstaltungs Verwaltung"}
                idKey={"event"}
                handleChange={handleChange}
            />
            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </div>
    );
};
export default EventForm;
