import "../ProjectData.css";

import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    LinearProgress,
    TextField,
    Typography,
} from "@material-ui/core";
import moment from "moment";
import React from "react";

import { Format } from "../../../../config/config";
import IAppointmentCampanion from "../../../../types/appointment/IAppointmentCampanion";
import ICampaign from "../../../../types/appointment/ICampaign";
import IPortalAppointment from "../../../../types/appointment/IPortalAppointment";
import IMandant from "../../../../types/IMandant";
import IUserData from "../../../../types/IUserData";
import { toPortalUrl } from "../../../../utils/toPortalUrl";

interface IAppointmentFormProps {
    handleSubmitEvent: () => void;
    campaignData: ICampaign;
    appointment: IPortalAppointment;
    mandant: IMandant;
    handlePrevious: () => void;
    userData: Partial<IUserData>;
    setUserData: (userData: Partial<IUserData>) => void;
    appointmentFormData: Partial<IPortalAppointment>;
    setAppointmentFormData: (
        appointmentFormData: Partial<IPortalAppointment>
    ) => void;
    campaignSerialNumber: string;
    setCampaignSerialNumber: (campaignSerialNumber: string) => void;
}

const AppointmentForm: React.FunctionComponent<IAppointmentFormProps> = (
    props: IAppointmentFormProps
) => {
    const {
        handleSubmitEvent,
        campaignData,
        handlePrevious,
        appointment,
        userData,
        setUserData,
        appointmentFormData,
        setAppointmentFormData,
        campaignSerialNumber,
        setCampaignSerialNumber,
        mandant,
    } = props;
    const campaignAssignedPersons: any = [
        //{ firstName: "Frida", lastName: "Wilhelm" },
    ];

    const [loading, setLoading] = React.useState<boolean>(false);
    const [acceptedDataProtection, setAcceptedDataProtection] =
        React.useState<boolean>(false);

    const handleAppointmentSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        e.preventDefault();
        handleSubmitEvent();
    };

    const handleTextChangeAppointment = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newData = {
            ...appointmentFormData,
            [event.target.name]: event.target.value,
        };
        setAppointmentFormData(newData);
    };

    const handleTextChangeUser = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newData: Partial<IUserData> = {
            ...userData,
            [event.target.name]: event.target.value,
        };
        setUserData(newData);
    };

    const handleBookingParticipantChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newData: Partial<IPortalAppointment> = {
            ...appointmentFormData,
            bookingParticipant: {
                ...appointmentFormData.bookingParticipant,
                email: event.target.value,
            },
        };
        setAppointmentFormData(newData);
    };

    const handleCampaignSerialNumber = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setCampaignSerialNumber(event.target.value);
    };

    const handleCheckboxDataProtection = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setAcceptedDataProtection(event.target.checked);
    };

    const handleCheckboxChangeAppointment = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newData = {
            ...appointmentFormData,
            [event.target.name]: event.target.checked,
        };
        setAppointmentFormData(newData);
    };

    const handleFlagPostalMailChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const flagSendPostalMail = event.target.checked;
        const newData: Partial<IPortalAppointment> = {
            ...appointmentFormData,
            bookingParticipant: {
                ...appointmentFormData.bookingParticipant,
                flagSendPostalMail,
            },
        };
        setAppointmentFormData(newData);
    };

    const handlegroupParticipantsChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        person: IAppointmentCampanion
    ) => {
        /*
        if (event.target.checked) {
            setAppointmentFormData({
                ...appointmentFormData,
                groupParticipants: [...appointmentFormData.groupParticipants, person],
            });
        } else {
            setAppointmentFormData({
                ...appointmentFormData,
                groupParticipants: appointmentFormData.groupParticipants.filter(
                    (campanion: IAppointmentCampanion) =>
                        campanion.firstName !== person.firstName
                ),
            });
        }
         */
    };

    if (!campaignData) {
        return <></>;
    }

    return (
        <Grid container className="gap-top appointment-form-container">
            <Grid item xs={8}>
                <Typography gutterBottom variant="h5">
                    {campaignData.title}
                </Typography>
                <Typography gutterBottom variant="h6">
                    Ihr Termin:{" "}
                    {moment(appointment?.startDate).format(Format.dateTime)} -{" "}
                    {moment(appointment?.startDate)
                        .add(appointment?.duration, "m")
                        .format(Format.time)}
                </Typography>
                <form onSubmit={handleAppointmentSubmit} className="gap-top">
                    {!campaignData.isPublicAvailable && (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth={true}
                                    required
                                    disabled={loading}
                                    id="campaign-number"
                                    label="Ordnungsnummer"
                                    variant="outlined"
                                    name="serialNumber"
                                    value={campaignSerialNumber}
                                    className="input-text"
                                    type="text"
                                    onChange={handleCampaignSerialNumber}
                                />
                            </Grid>
                        </Grid>
                    )}

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth={true}
                                required
                                disabled={loading}
                                id="userData-salutation"
                                value={userData.salutation || ""}
                                label="Anrede"
                                variant="outlined"
                                className="input-text"
                                name="salutation"
                                type="text"
                                onChange={handleTextChangeUser}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth={true}
                                required
                                disabled={loading}
                                id="userData-firstname"
                                value={userData.firstname || ""}
                                label="Vorname"
                                variant="outlined"
                                className="input-text"
                                name="firstname"
                                type="text"
                                onChange={handleTextChangeUser}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth={true}
                                required
                                disabled={loading}
                                id="userData-lastname"
                                value={userData.lastname || ""}
                                label="Nachname"
                                variant="outlined"
                                className="input-text"
                                name="lastname"
                                type="text"
                                onChange={handleTextChangeUser}
                            />
                        </Grid>
                    </Grid>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    !!appointmentFormData?.bookingParticipant
                                        ?.flagSendPostalMail
                                }
                                disabled={loading}
                                onChange={handleFlagPostalMailChange}
                                name="flagSendPostalMail"
                            />
                        }
                        label="Bitte schicken Sie die Terminbestätigung per Post"
                        className="gap-bottom checkbox-label"
                    />
                    {!!appointmentFormData?.bookingParticipant
                        ?.flagSendPostalMail && (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth={true}
                                        required
                                        disabled={loading}
                                        id="userData-street"
                                        value={userData.street || ""}
                                        label="Straße"
                                        variant="outlined"
                                        className="input-text"
                                        name="street"
                                        type="text"
                                        onChange={handleTextChangeUser}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth={true}
                                        required
                                        disabled={loading}
                                        id="userData-streetNo"
                                        value={userData.streetNo || ""}
                                        label="Hausnummer"
                                        variant="outlined"
                                        className="input-text"
                                        name="streetNo"
                                        type="text"
                                        onChange={handleTextChangeUser}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth={true}
                                        required
                                        disabled={loading}
                                        id="userData-zip"
                                        value={userData.zip || ""}
                                        label="Postleitzahl"
                                        variant="outlined"
                                        className="input-text"
                                        name="zip"
                                        type="text"
                                        onChange={handleTextChangeUser}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth={true}
                                        required
                                        disabled={loading}
                                        id="userData-city"
                                        value={userData.city || ""}
                                        label="Stadt"
                                        variant="outlined"
                                        className="input-text"
                                        name="city"
                                        type="text"
                                        onChange={handleTextChangeUser}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}

                    <TextField
                        fullWidth={true}
                        required={
                            !appointmentFormData?.bookingParticipant
                                ?.flagSendPostalMail
                        }
                        disabled={loading}
                        id="email"
                        value={
                            appointmentFormData?.bookingParticipant?.email || ""
                        }
                        label="E-Mail"
                        variant="outlined"
                        className="input-text"
                        name="email"
                        type="text"
                        onChange={handleBookingParticipantChange}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={loading}
                                checked={!!appointmentFormData.hasAttorney}
                                onChange={handleCheckboxChangeAppointment}
                                name="hasAttorney"
                            />
                        }
                        label="Es kommt eine Vertretung mit Vollmacht"
                        className="gap-bottom checkbox-label"
                    />

                    {appointmentFormData.hasAttorney && (
                        <TextField
                            fullWidth={true}
                            required={true}
                            disabled={loading}
                            id="attorney-name"
                            value={appointmentFormData.attorneyName || ""}
                            label="Name der Person mit der Vollmacht"
                            variant="outlined"
                            className="input-text"
                            name="attorneyName"
                            type="text"
                            onChange={handleTextChangeAppointment}
                        />
                    )}

                    {!campaignData.isPublicAvailable && (
                        <>
                            <Typography gutterBottom variant="h2">
                                Ebenfalls teilnehmen werden:
                            </Typography>
                            {campaignAssignedPersons.map(
                                (
                                    person: IAppointmentCampanion,
                                    index: number
                                ) => (
                                    <div key={index}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        appointmentFormData.groupParticipants &&
                                                        !!appointmentFormData.groupParticipants.find(
                                                            (
                                                                campanion: IAppointmentCampanion
                                                            ) =>
                                                                campanion.firstName ===
                                                                person.firstName
                                                        )
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        handlegroupParticipantsChange(
                                                            event,
                                                            person
                                                        );
                                                    }}
                                                    name={person.firstName}
                                                />
                                            }
                                            label={`${person.firstName}, ${person.lastName}`}
                                        />
                                    </div>
                                )
                            )}
                        </>
                    )}

                    <TextField
                        fullWidth={true}
                        id="companion-list"
                        disabled={loading}
                        value={appointmentFormData.additionalPerson || ""}
                        multiline
                        rows={5}
                        label="Bitte geben Sie eventuelle Begleiter und deren Funktion an."
                        variant="outlined"
                        name="additionalPerson"
                        onChange={handleTextChangeAppointment}
                    />
                    <TextField
                        fullWidth={true}
                        disabled={loading}
                        id="additional-information"
                        value={appointmentFormData.userComment || ""}
                        multiline
                        rows={5}
                        label="Haben Sie vorab Informationen für uns?"
                        variant="outlined"
                        name="userComment"
                        onChange={handleTextChangeAppointment}
                    />

                    <p className="gap-top">
                        Bitte akzeptieren Sie die{" "}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={toPortalUrl(mandant, "/seite/datenschutz")}
                        >
                            Datenschutzbedingungen
                        </a>{" "}
                        zum Abschicken der Terminanfrage.
                    </p>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={acceptedDataProtection}
                                disabled={loading}
                                onChange={handleCheckboxDataProtection}
                            />
                        }
                        label="Datenschutzbedingungen akzeptieren."
                        className="gap-bottom checkbox-label"
                    />

                    {!loading && (
                        <Grid item xs={6} className="action-buttons">
                            <Button
                                variant="outlined"
                                onClick={() => handlePrevious()}
                            >
                                Zurück
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={!acceptedDataProtection}
                            >
                                Jetzt Anmelden
                            </Button>
                        </Grid>
                    )}

                    {loading && (
                        <Grid item xs={12} className="action-buttons">
                            <LinearProgress />
                        </Grid>
                    )}
                </form>
            </Grid>
        </Grid>
    );
};

export default AppointmentForm;
