import expandToDecimalPlaces from "../../../utils/expandToDecimalPlaces";
import KFW_STATIC_DATA, {
    DEFAULT_VALUE,
    FIXED_VALUE_CALCULATE_BASIC_FUNDING_CAP,
    KFW40,
    KFW55,
    KFW70,
    KFW85,
    MIN_LIMIT_COSTS,
    NA,
} from "./constants";

export interface ICalculateKFWResult {
    basicSupport: any;
    basicFundingCap: any;
    bonusPromotion: any;
    percentOfEligibleCosts: any;
}

const formatValueIfZero = (value: number) => {
    return value == DEFAULT_VALUE ? NA : value;
};

const calculateBasicSupport = (costs: number, percentage: number): number =>
    costs * percentage;

const calculateBasicFundingCap = (
    costs: number,
    percentage: number,
    maxCosts: number,
    maxBasicFundingCap: number,
    fundingCapKwf85?: number
): number => {
    if (costs < MIN_LIMIT_COSTS) {
        return DEFAULT_VALUE;
    }
    if (maxBasicFundingCap == DEFAULT_VALUE) {
        return DEFAULT_VALUE;
    }
    if (costs && maxCosts && costs >= maxCosts) {
        return maxBasicFundingCap;
    }
    if (fundingCapKwf85) {
        return fundingCapKwf85 + costs * percentage;
    }
    return FIXED_VALUE_CALCULATE_BASIC_FUNDING_CAP + costs * percentage;
};

const calculateBasicFundingCapKfw85 = (costs: number): number => {
    if (costs < MIN_LIMIT_COSTS) {
        return DEFAULT_VALUE;
    }
    if (
        MIN_LIMIT_COSTS <= costs &&
        costs <= KFW_STATIC_DATA.KFW85.MAX_LIMIT_COSTS
    ) {
        return KFW_STATIC_DATA.KFW85.FIXED_BASIC_FUNDING_CAP_KFW85;
    }
    return calculateBasicFundingCap(
        costs,
        KFW_STATIC_DATA.KFW85.KFW85_BASIC_FUNDING_CAP_POURCENTAGE,
        KFW_STATIC_DATA.KFW85.MAX_COSTS_KFW85,
        KFW_STATIC_DATA.KFW85.MAX_BASIC_FUNDING_CAP_KFW85
    );
};

const calculateBonusPromotion = (
    costs: number,
    pourcentage: number,
    maxCosts: number,
    maxBonusPromotion: number
): number => {
    if (costs < MIN_LIMIT_COSTS) {
        return DEFAULT_VALUE;
    }
    if (costs >= maxCosts) {
        return maxBonusPromotion;
    }
    const basicFundingCapKfw85 = calculateBasicFundingCapKfw85(costs);
    return basicFundingCapKfw85 + costs * pourcentage;
};

const calculatePercentOfEligibleCosts = (
    variantCosts: number,
    costs: number,
    percentage: number
): number => {
    if (costs < MIN_LIMIT_COSTS) {
        return percentage * 100;
    }
    return expandToDecimalPlaces((variantCosts / costs) * 100, 1);
};

/******************************Calculate KFW ******************************/
export const calculateKFW = (
    costs: number,
    kfw: string
): ICalculateKFWResult => {
    let basicSupport: any = DEFAULT_VALUE;
    let basicFundingCap: any = "";
    let bonusPromotion: any = "";
    let percentOfEligibleCosts: any = DEFAULT_VALUE;
    let basicFundingCapKfw85 = 0;
    let basicFundingCapCalcRes = 0;
    switch (kfw) {
        case KFW85:
            basicSupport = calculateBasicSupport(
                costs,
                KFW_STATIC_DATA[kfw].KFW85_BASIC_SUPPORT_POURCENTAGE
            );

            basicFundingCap = formatValueIfZero(
                calculateBasicFundingCapKfw85(costs)
            );
            bonusPromotion = NA;
            percentOfEligibleCosts = formatValueIfZero(
                calculatePercentOfEligibleCosts(
                    calculateBasicFundingCapKfw85(costs),
                    costs,
                    KFW_STATIC_DATA[kfw].KFW85_BASIC_SUPPORT_POURCENTAGE
                )
            );
            break;
        case KFW70:
            basicSupport = calculateBasicSupport(
                costs,
                KFW_STATIC_DATA[kfw].KFW70_BASIC_SUPPORT_POURCENTAGE
            );
            basicFundingCapKfw85 = calculateBasicFundingCapKfw85(costs);
            basicFundingCapCalcRes = calculateBasicFundingCap(
                costs,
                KFW_STATIC_DATA[kfw].KFW70_BASIC_FUNDING_CAP_POURCENTAGE,
                KFW_STATIC_DATA[kfw].MAX_COSTS_KFW70,
                KFW_STATIC_DATA[kfw].MAX_BASIC_FUNDING_CAP_KFW70,
                basicFundingCapKfw85
            );
            basicFundingCap = formatValueIfZero(basicFundingCapCalcRes);
            bonusPromotion = NA;
            percentOfEligibleCosts = formatValueIfZero(
                calculatePercentOfEligibleCosts(
                    basicFundingCapCalcRes,
                    costs,
                    KFW_STATIC_DATA[kfw].KFW70_BASIC_SUPPORT_POURCENTAGE
                )
            );
            break;
        case KFW55:
            basicSupport = calculateBasicSupport(
                costs,
                KFW_STATIC_DATA[kfw].KFW55_BASIC_SUPPORT_POURCENTAGE
            );

            basicFundingCap = formatValueIfZero(
                calculateBasicFundingCap(
                    costs,
                    DEFAULT_VALUE,
                    KFW_STATIC_DATA[kfw].MAX_COSTS_KFW55,
                    DEFAULT_VALUE
                )
            );
            bonusPromotion = formatValueIfZero(
                calculateBonusPromotion(
                    costs,
                    KFW_STATIC_DATA[kfw].KFW55_BONUS_PROMOTION_POURCENTAGE,
                    KFW_STATIC_DATA[kfw].MAX_COSTS_KFW55,
                    KFW_STATIC_DATA[kfw].MAX_BONUS_PROMOTION_KFW55
                )
            );
            percentOfEligibleCosts = formatValueIfZero(
                calculatePercentOfEligibleCosts(
                    bonusPromotion,
                    costs,
                    KFW_STATIC_DATA[kfw].KFW55_BASIC_SUPPORT_POURCENTAGE
                )
            );
            break;
        case KFW40:
            basicSupport = calculateBasicSupport(
                costs,
                KFW_STATIC_DATA[kfw].KFW40_BASIC_SUPPORT_POURCENTAGE
            );
            basicFundingCap = formatValueIfZero(
                calculateBasicFundingCap(
                    costs,
                    DEFAULT_VALUE,
                    KFW_STATIC_DATA[kfw].MAX_COSTS_KFW40,
                    DEFAULT_VALUE
                )
            );
            bonusPromotion = formatValueIfZero(
                calculateBonusPromotion(
                    costs,
                    KFW_STATIC_DATA[kfw].KFW40_BONUS_PROMOTION_POURCENTAGE,
                    KFW_STATIC_DATA[kfw].MAX_COSTS_KFW40,
                    KFW_STATIC_DATA[kfw].MAX_BONUS_PROMOTION_KFW40
                )
            );
            percentOfEligibleCosts = formatValueIfZero(
                calculatePercentOfEligibleCosts(
                    bonusPromotion,
                    costs,
                    KFW_STATIC_DATA[kfw].KFW40_BASIC_SUPPORT_POURCENTAGE
                )
            );
            break;
    }
    return {
        basicSupport,
        basicFundingCap,
        bonusPromotion,
        percentOfEligibleCosts,
    };
};
