import InitialDocumentData from "../../../types/initialData/InitialDocumentData";
import InitialPartnerLogoData from "../../../types/initialData/InitialPartnerLogoData";
import ActionTypes from "../MandantActionTypesEnum";
import { AppActions, IMandantState } from "./types";

export const initialState: IMandantState = {
    loading: false,
    error: null,
    mandant: {
        id: 0,
        key: "",
        name: "",
        contactEmail: "",
        notifyEmailsElse: "",
        slogan: "",
        matomoTrackingId: "",
        domain: "",
        urlWebsite: "",
        headerImage: { ...InitialDocumentData },
        logoImage: { ...InitialDocumentData },
        partnerLogoImage: { ...InitialDocumentData },
        partnerLogos: [{ ...InitialPartnerLogoData }],
        flagUseOldUi: false,
    },
    mandantTheme: {
        "primary-color": "",
        "text-color": "",
        "text-font-size": "",
        "text-font-size-smaller": "",
        "text-font-family": "",
        "header-h2-color": "",
        "header-h3-color": "",
        "header-h2-size": "",
        "header-h3-size": "",
        "header-font-family": "",
        "button-background": "",
        "button-text-color": "",
        "footer-background-color": "",
        "footer2-background-color": "",
        "link-color": "",
        "link-color-hover": "",
        "link-color-footer1": "",
        "link-color-footer2": "",
        gap: "",
    },
};

function MandantReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: IMandantState = initialState,
    action: AppActions
): IMandantState {
    switch (action.type) {
        case ActionTypes.LOAD:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.LOAD_SUCCESS:
            return {
                ...state,
                mandant: action.payload,
                loading: false,
                error: null,
            };
        case ActionTypes.LOAD_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case ActionTypes.SET_THEME:
            return {
                ...state,
                mandantTheme: action.payload,
            };
        default:
            return state;
    }
}

export default MandantReducer;
