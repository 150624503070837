import ActionTypes from "../CmsPageProjectSelectboxTypesEnum";
import { AppActions, ICmsPageProjectSelectboxState } from "./types";

export const initialState: ICmsPageProjectSelectboxState = {
    error: null,
    cmsPageProjectData: [],
};

function CmsPageProjectSelectboxReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: ICmsPageProjectSelectboxState = initialState,
    action: AppActions
): ICmsPageProjectSelectboxState {
    switch (action.type) {
        case ActionTypes.LOAD_SUCCESS:
            return {
                ...state,
                cmsPageProjectData: action.payload,
                error: null,
            };
        case ActionTypes.LOAD_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
}

export default CmsPageProjectSelectboxReducer;
