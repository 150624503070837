import IUser from "../IUser";
import InitialDocumentData from "./InitialDocumentData";

const InitialUserData: IUser = {
    id: 0,
    dateCreated: "",
    description: "",
    displayTitle: "",
    nickName: "",
    email: "",
    firstname: "",
    lastname: "",
    street: "",
    streetNo: "",
    zip: "",
    city: "",
    image: { ...InitialDocumentData },
    lastLogin: "",
    roles: [],
    projectRoles: {},
    salutation: "",
};
export default InitialUserData;
