import { AxiosResponse } from "axios";

import { PORTAL_PROJECT, PORTAL_PROPOSAL } from "../enums/Endpoints";
import IMapProposal from "../types/IMapProposal";
import { deleteData, getData, postData } from "../utils/httpRequest/request";

/* admin load proposals */
export const loadProposalsList = (
    mandant: string,
    projectId: string
): Promise<AxiosResponse<any>> => {
    return getData<any[]>(PORTAL_PROJECT + projectId + "/" + PORTAL_PROPOSAL, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
/* portal create proposal */
export const createProposalRequest = (
    proposal: IMapProposal,
    mandant: string
): Promise<AxiosResponse<IMapProposal>> => {
    return postData<IMapProposal, IMapProposal>(PORTAL_PROPOSAL, proposal, {
        mandant,
        withLang: true,
        isPublic: true,
    });
};
/* portal get proposals */
export const getProjectProposalsRequest = (
    mandant: string,
    projectId: string
): Promise<AxiosResponse<any>> => {
    return getData<any>(PORTAL_PROJECT + projectId + "/" + PORTAL_PROPOSAL, {
        mandant,
        withLang: true,
        isPublic: true,
    });
};

export const deleteProjectProposalRequest = (
    id: number,
    mandant: string
): Promise<any> => {
    return deleteData<any>(PORTAL_PROPOSAL, id, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
